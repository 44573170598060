import { useUsers } from '@capturi/stores'
import { Button } from '@capturi/ui-components'
import { Flex, HStack, Text, useToast } from '@chakra-ui/react'
import React, { ReactElement, useEffect, useState } from 'react'
import PhrasesEditor from '../components/PhrasesEditor'
import {
  useAllSupportedLanguages,
  useKeyTopicsBlacklist,
  useUpdateKeyTopicsBlacklist,
} from './useKeyTopicsBlacklist'

const KeyTopicsBlacklist: React.FC = () => {
  const [language, setLanguage] = useState<string>('da-DK')
  const toast = useToast()
  const { getUserByUid } = useUsers()

  const { data, isLoading } = useKeyTopicsBlacklist(language)
  const { data: languages } = useAllSupportedLanguages()
  const { mutate: updateKeyTopicsBlacklist } = useUpdateKeyTopicsBlacklist()
  const [stopWords, setStopWords] = useState<string[]>(
    data?.blacklistedPatterns ?? [],
  )

  const handleUpdateWords = () => {
    updateKeyTopicsBlacklist(
      { patterns: stopWords, language: language },
      {
        onSuccess: () => {
          toast({ title: 'Blacklist updated', status: 'success' })
        },
        onError: (error) => {
          toast({
            title: 'Updating blacklist failed.',
            description: `${error.message} - NOTE: Currently only letters (for all supported languages) and the special character "*" are allowed in the list of blacklisted words.`,
            status: 'error',
          })
        },
      },
    )
  }

  useEffect(() => {
    if (data) {
      setStopWords(data.blacklistedPatterns)
    }
  }, [data])

  const renderButtons = (): ReactElement => {
    return (
      <>
        {languages?.map((lang, i) => (
          <Button
            key={lang}
            primary
            borderLeftRadius={i === 0 ? 'lg' : 0}
            borderRightRadius={i + 1 === languages.length ? 'lg' : 0}
            variant={language === lang ? 'solid' : 'outline'}
            onClick={() => setLanguage(lang)}
          >
            {lang}
          </Button>
        ))}
      </>
    )
  }

  return (
    <>
      <Flex align="center" mb="4" justifyContent="center">
        {renderButtons()}
      </Flex>
      <PhrasesEditor
        state={stopWords}
        onChange={setStopWords}
        isLoading={isLoading}
        isSuccess={true}
        title="Blacklisted words"
        placeholder="Blacklisted word"
        mb="4"
      />
      <HStack justify="space-between">
        <Text>
          last updated by:{' '}
          {data?.lastUpdatedByUser &&
            getUserByUid(data?.lastUpdatedByUser).name}
        </Text>
        <Button onClick={handleUpdateWords} primary>
          Save
        </Button>
      </HStack>
    </>
  )
}

export default KeyTopicsBlacklist
