import {
  Box,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { type FC, useMemo } from 'react'
import { flattenObject } from './flattenObject'

type Props = { data: Record<string, unknown>[] }

const Table: FC<Props> = ({ data }) => {
  const flattened = useMemo(() => data.map(flattenObject), [data])
  const headers = useMemo(() => {
    return Object.keys(flattened?.[0] || {}).map((v) => ({
      full: v,
      name: v.split('.').at(-1),
    }))
  }, [flattened])
  return (
    <Box overflow="scroll">
      <ChakraTable overflow="scroll">
        <Thead>
          <Tr>
            {headers.map(({ full, name }) => (
              <Th title={full} key={full}>
                {name}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody overflow="scroll">
          {flattened.map((d, index) => {
            return (
              <Tr key={index} _hover={{ bg: 'gray.50' }}>
                {Object.values(d).map((v, index) => {
                  if (typeof v === 'string') return <Td key={index}>{v}</Td>
                  if (typeof v === 'number')
                    return (
                      <Td isNumeric key={index}>
                        {v}
                      </Td>
                    )
                  if (v instanceof Date)
                    return <Td key={index}>{v.toLocaleString('da-DK')}</Td>
                  return (
                    <Td key={index} color="gray.500">
                      null
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </ChakraTable>
    </Box>
  )
}

export default Table
