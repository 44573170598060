import { Box, Text } from '@chakra-ui/react'
import React from 'react'

import { Card } from '../components'

type Props = { conversationUid: string }

const ConversationAudio: React.FC<Props> = ({ conversationUid }) => {
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? `${location.origin}/api`
      : window.env?.apiUrl || 'https://api.capturi.ai'

  return (
    <Card title="Conversation Audio">
      <Box mt="6">
        <Text textAlign="center">Original Audio</Text>
        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
        <audio
          preload="none"
          controls
          src={`${baseUrl}/superpowers/asr/audio/${conversationUid}?audioVersion=Raw`}
        />
      </Box>
      <Box mt="6">
        <Text textAlign="center">Processed Audio</Text>
        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
        <audio
          preload="none"
          controls
          src={`${baseUrl}/superpowers/asr/audio/${conversationUid}?audioVersion=Playback`}
        />
      </Box>
    </Card>
  )
}

export default ConversationAudio
