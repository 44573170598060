import { Report, ReportRequestModel } from '@capturi/api-systemadmin'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { baseUrl, cacheKey } from '../constants'

export type UpdateReportPayload = {
  reportUid: string
  organizationUid: string | undefined
  report: Partial<ReportRequestModel>
}

export type DeleteReportPlayload = {
  reportUid: string
  organizationUid: string | undefined
}

export type CreateReportPayload = {
  organizationUid: string | undefined
  report: ReportRequestModel
}

export const useUpdateReport = (
  organizationUid: string | undefined,
): UseMutationResult<Report, ResponseError, UpdateReportPayload> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey, 'organisation', organizationUid],
    mutationFn: ({
      reportUid,
      organizationUid,
      report,
    }: UpdateReportPayload) => {
      return request.patch<Report>(
        `${baseUrl}/${organizationUid}/${reportUid}?api-version=1`,
        {
          json: report,
        },
      )
    },
    onSuccess: (newReport) => {
      queryClient.setQueryData<Report[]>(
        [cacheKey, 'organisation', organizationUid],
        (oldData) => {
          if (!oldData) {
            return [newReport]
          }
          return oldData.map((f) => (f.uid === newReport.uid ? newReport : f))
        },
      )
      queryClient.setQueryData<Report>(
        [cacheKey, 'report', newReport.uid],
        () => {
          return newReport
        },
      )
    },
  })
}

export const useDeleteReport = (
  organizationUid: string | undefined,
): UseMutationResult<Report, ResponseError, DeleteReportPlayload> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey, 'organisation', organizationUid],
    mutationFn: ({ reportUid, organizationUid }: DeleteReportPlayload) =>
      request.delete<Report>(`${baseUrl}/${organizationUid}/${reportUid}`),
    onSuccess: (_, payload) => {
      queryClient.setQueryData<Report[]>(
        [cacheKey, 'organisation', organizationUid],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.filter((f) => f.uid !== payload.reportUid)
        },
      )
    },
  })
}

export const useCreateReport = (
  organizationUid: string | undefined,
): UseMutationResult<Report, ResponseError, CreateReportPayload> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [cacheKey, 'organisation', organizationUid],
    mutationFn: ({ organizationUid, report }: CreateReportPayload) =>
      request.post<Report>(`${baseUrl}/${organizationUid}`, {
        json: report,
      }),
    onSuccess: (newReport) => {
      queryClient.setQueryData<Report[]>(
        [cacheKey, 'organisation', organizationUid],
        (oldData) => {
          if (!oldData) {
            return [newReport]
          }
          return [newReport, ...oldData]
        },
      )
    },
  })
}
