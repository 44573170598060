import { Button } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'

import AskAiQuestion from './AskAiQuestion'
import AskAiQuestionList from './AskAiQuestionList'
import { Question, useAiQuestions } from './useAiQuestions'

type MappedQuestions = {
  language: string
  questions: {
    language: string
    uid: string
    name: string
    question: string
  }[]
}

const AskAiQuestionsPage: React.FC = () => {
  const [question, setQuestion] = useState<null | Question>(null)

  const { data = [] } = useAiQuestions()

  const categorizedByLanguage = useMemo<
    MappedQuestions[]
  >((): MappedQuestions[] => {
    return data.reduce<MappedQuestions[]>((acc, question) => {
      const languageIndex = acc.findIndex(
        (item) => item.language === question.language,
      )
      if (languageIndex > -1) {
        acc[languageIndex].questions.push({
          language: question.language,
          uid: question.uid,
          name: question.name,
          question: question.question,
        })
      } else {
        acc.push({
          language: question.language,
          questions: [
            {
              language: question.language,
              uid: question.uid,
              name: question.name,
              question: question.question,
            },
          ],
        })
      }

      return acc
    }, [])
  }, [data])
  return (
    <Box width="100%" mt={4}>
      <Flex>
        <Flex
          gap={2}
          flexDirection="column"
          flex="50%"
          borderRight="1px solid"
          borderColor="gray.100"
          pr="4"
        >
          <Tabs isLazy mt={4}>
            <TabList>
              {categorizedByLanguage.map((item) => (
                <Tab key={item.language}>{item.language}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {categorizedByLanguage.map((list) => (
                <TabPanel key={list.language} px={0}>
                  {list.questions.map((q) => (
                    <AskAiQuestionList
                      key={q.uid}
                      question={q}
                      setQuestion={setQuestion}
                    />
                  ))}
                </TabPanel>
              ))}

              <TabPanel key="en-US" px={0} />
            </TabPanels>
          </Tabs>
          <Button
            mt="4"
            onClick={() =>
              setQuestion({
                name: '',
                uid: '',
                language: 'da-DK',
                question: '',
              })
            }
          >
            Add
          </Button>
        </Flex>

        <Box flex="50%">
          {question && (
            <AskAiQuestion
              key={question.uid}
              language={question.language}
              onClose={() => setQuestion(null)}
              name={question.name}
              question={question.question}
              uid={question.uid}
            />
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default AskAiQuestionsPage
