import { Button, useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box } from '@chakra-ui/react'
import React from 'react'

import { Card } from '../components'
import ReprocessConversations from './ReprocessConversations'
import RetrainSpeakerModel from './RetrainSpeakerModel'

type Props = {
  status: string
  name: string
  uid: string
  organizationUid: string
  updateCache: () => void
}

const SpeakerModelCard: React.FC<Props> = ({
  status,
  name,
  uid,
  organizationUid,
  updateCache,
}) => {
  const [openReprocessConversations] = useModal(ReprocessConversations)
  const [openRetrainSpeakerModel] = useModal(RetrainSpeakerModel)
  const toast = useToast()
  const inProgress = status === 'Processing'

  return (
    <div>
      <Card title="Speaker Model">
        <Box m="2">Status: {status}</Box>
        <Box mx="-4" mb="-4">
          <Button
            primary
            variant="ghost"
            isDisabled={inProgress}
            onClick={() => {
              openRetrainSpeakerModel({
                organizationUid: organizationUid,
                userName: name,
                userUid: uid,
                onSubmit: updateCache,
              })
            }}
          >
            Retrain Speaker Model
          </Button>
          <Button
            primary
            variant="ghost"
            isDisabled={inProgress}
            onClick={() => {
              openReprocessConversations({
                userName: name,
                userUid: uid,
                onSubmit: (count: number) => {
                  toast({ title: `Reprocessing ${count} conversations` })
                },
              })
            }}
          >
            Reprocess Conversations
          </Button>
        </Box>
      </Card>
    </div>
  )
}

export default SpeakerModelCard
