import { useToast } from '@capturi/ui-components'
import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { Flex, FormControl, FormLabel, IconButton } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdCopyAll } from 'react-icons/md'

type Option = { value: string; label: string }
type Props = {
  options: Option[]
  label: string
  fieldKey: string
  value: string[]
  onChange: (values: string[]) => void
  isDisabled?: boolean
}

export const Multiselect: React.FC<Props> = ({
  options,
  fieldKey,
  label,
  onChange,
  value,
  isDisabled = false,
}) => {
  const toast = useToast()
  const handleChange = (values: OnChangeValue<SelectOption, boolean>): void => {
    if (Array.isArray(values)) {
      onChange(values.map((x) => x.value))
    }
  }
  const handleCopy = (): void => {
    navigator.clipboard.writeText(options.map((f) => f.label).join('\n'))
    toast({ status: 'info', title: 'Copied list to clipboard' })
  }

  const initialValue = useMemo<Option[]>(() => {
    const nameMap = new Map(options.map(({ label, value }) => [value, label]))

    return value.map((v) => ({ label: nameMap.get(v) ?? v, value: v }))
  }, [options, value])

  return (
    <FormControl key={fieldKey}>
      <Flex justifyContent="space-between">
        <FormLabel>{label}</FormLabel>
        <IconButton
          variant="ghost"
          icon={<MdCopyAll />}
          onClick={handleCopy}
          aria-label="copy list content"
          title="copy list content"
        />
      </Flex>
      <Select
        isDisabled={isDisabled}
        defaultValue={initialValue}
        isMulti
        options={options}
        placeholder={label}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue
        isClearable={true}
        autoFocus={false}
        minMenuHeight={100}
        maxMenuHeight={612}
      />
    </FormControl>
  )
}
