import { Text } from '@chakra-ui/react'
import React from 'react'
import type { ConversationResponse } from './useConversations'

type Props = { fieldsToShow: string[]; data: ConversationResponse[] }

const ConversationsJson: React.FC<Props> = ({ data, fieldsToShow }) => {
  return (
    <Text whiteSpace="break-spaces" fontFamily="monospace" bg="gray.100">
      {JSON.stringify(data, fieldsToShow, '   ')}
    </Text>
  )
}

export default ConversationsJson
