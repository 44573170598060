import { Spinner } from '@capturi/ui-components'
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { Title } from '../../../utils'
import { useActiveCapturiUsers } from '../useUsers'

type Props = { organizationUid: string }

const Person: React.FC<{ name: string }> = ({ name }) => {
  return (
    <Flex alignItems="center" my="2" whiteSpace="nowrap">
      <Avatar name={name} size="sm" />
      <Text mx="1">{name}</Text>
    </Flex>
  )
}
const UsersWithAccess: React.FC<Props> = ({ organizationUid }) => {
  const {
    data: capturiUsers = [],
    isLoading,
    isSuccess,
  } = useActiveCapturiUsers(organizationUid)

  return (
    <Card mt="4">
      <CardBody>
        <Title>{`Access ${isSuccess ? `(${capturiUsers.length})` : ''}`}</Title>
        <Box maxHeight={'300px'} overflowY="auto">
          <SimpleGrid spacing={2} minChildWidth="200px" my="2">
            {isSuccess &&
              capturiUsers.map((u) => <Person name={u.name} key={u.id} />)}
            {isLoading && (
              <Flex alignItems="center" justifyContent="center" height="127px">
                <Spinner delay={0} />
              </Flex>
            )}
          </SimpleGrid>
        </Box>
      </CardBody>
    </Card>
  )
}

export default UsersWithAccess
