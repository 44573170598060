import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalCloseButton,
  Switch,
  Text,
} from '@chakra-ui/react'
import noop from 'lodash/noop'
import React, { ReactElement, useState } from 'react'
import { type Team, useUpdateTeam } from './useSuperpowersTeams'

type Props = {
  organizationUid: string
  onSubmit: (team: Team) => void
  onClose: () => void
} & Team
type Keys = 'name' | 'externalUid'

const EditTeamModal: React.FC<Props> = ({
  onClose,
  createdByIntegration,
  name,
  organizationUid,
  uid,
  externalUid,
  integrationType,
}) => {
  const toast = useToast()
  const { mutate, isPending, error } = useUpdateTeam(organizationUid)

  const [state, setState] = useState<{
    uid: string
    name: string
    externalUid: string
    createdByIntegration: boolean
    integrationType?: string
  }>({
    createdByIntegration,
    name,
    uid,
    externalUid: externalUid || '',
    integrationType: integrationType || '',
  })

  const updateState = (key: Keys, value: string): void =>
    setState((s) => ({ ...s, [key]: value }))

  const handleSave = async (): Promise<void> => {
    mutate(state, {
      onSuccess: () => {
        toast({ status: 'success', title: 'Updated Team' })
        onClose()
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: 'Could not update team',
          description: error.message,
        })
      },
    })
  }

  const renderTextField = (key: Keys, label: string): ReactElement => {
    return (
      <FormControl mt="2">
        <FormLabel mb="0" ml="1" htmlFor="name">
          {label}
        </FormLabel>
        <Input
          isDisabled={isPending}
          id="name"
          value={state[key] as string}
          onChange={(event) => updateState(key, event.currentTarget.value)}
          autoComplete="none"
        />
      </FormControl>
    )
  }

  return (
    <Modal isOpen onClose={isPending ? noop : onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit team</ModalHeader>
        {!isPending && <ModalCloseButton />}

        <ModalBody my="4">
          {renderTextField('name', 'Name')}
          {renderTextField('externalUid', 'ExternalUid')}
          <Flex alignItems="center" mt="2">
            <Switch
              key="createdByIntegration"
              isChecked={state.createdByIntegration}
              onChange={() =>
                setState((s) => ({
                  ...s,
                  createdByIntegration: !s.createdByIntegration,
                }))
              }
              isDisabled={isPending}
              mr="4"
            />

            <Text fontWeight="normal">Created By Integration</Text>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" isDisabled={isPending} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isPending}
            colorScheme="primary"
            onClick={handleSave}
          >
            Update
          </Button>
        </ModalFooter>
        {error != null && (
          <Text textAlign="center" p="3" color="danger">
            {error.message}
          </Text>
        )}
      </ModalContent>
    </Modal>
  )
}

export default EditTeamModal
