import {
  CustomNumberPropSettings,
  CustomPropSettings,
} from '@capturi/api-organization'

export const customNumberPropExamples: CustomNumberPropSettings[] = [
  {
    enabled: true,
    label: {
      'da-DK': 'Kundetilfredshed',
      'en-US': 'Customer satisfaction',
    },
    filterType: 'Range',
    min: 0,
    max: 10,
  },
  {
    enabled: true,
    label: {
      'da-DK': 'Samtalelængde',
      'en-US': 'Duration',
    },
    filterType: 'Dropdown',
    filters: [
      {
        label: {
          'da-DK': 'Højst 1 minut',
          'en-US': 'Max. 1 minute',
        },
        max: 1,
        min: null,
      },
      {
        label: {
          'da-DK': 'Mindst 1 minut',
          'en-US': 'Min. 1 minute',
        },
        max: null,
        min: 1,
      },
      {
        label: {
          'da-DK': 'Mindst 5 minutter',
          'en-US': 'Min. 5 minutes',
        },
        max: null,
        min: 5,
      },
    ],
  },
]

export const customPropExamples: CustomPropSettings[] = [
  {
    enabled: true,
    filterType: 'Text',
    label: {
      'da-DK': 'Eksternt id',
      'en-US': 'External id',
    },
  },
  {
    enabled: true,
    filterType: 'Dropdown',
    label: {
      'da-DK': 'Status',
      'en-US': 'Status',
    },
    values: [
      {
        value: 'automaticRedial',
        label: {
          'da-DK': 'Genkald',
          'en-US': 'Redial',
        },
      },
      {
        value: 'notInterested',
        label: {
          'da-DK': 'Ikke interesseret',
          'en-US': 'Not interested',
        },
      },
    ],
  },
]
