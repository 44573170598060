import {} from '@chakra-ui/react'

import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const CACHE_KEY = ['superpowers', 'aiQuestions']

export type Question = {
  uid: string
  language: string
  name: string
  question: string
}

type QuestionPayload = {
  language: string
  name: string
  question: string
}

type QuestionResponse = QuestionPayload & { uid: string }

export const useAiQuestions = (): UseQueryResult<Question[], ResponseError> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: async () => {
      const result = await request.get<{ questions: Question[] }>(
        'systemadmin/ask-ai-questions',
      )
      return result.questions
    },
  })

export const useAddAiQuestion = (): UseMutationResult<
  QuestionPayload,
  ResponseError,
  QuestionPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model) =>
      request.post('systemadmin/ask-ai-questions', {
        json: model,
      }),
    onSuccess: (question) => {
      queryClient.setQueryData<QuestionPayload[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [question]
        }
        return [...oldData, question]
      })
    },
  })
}

export const useUpdateAiQuestion = (
  uid: string | undefined,
): UseMutationResult<
  QuestionResponse,
  ResponseError,
  QuestionResponse,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (payload) =>
      request.patch(`systemadmin/ask-ai-questions/${uid}`, {
        json: payload,
      }),
    onSuccess: (question) => {
      queryClient.setQueryData<QuestionResponse[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [question]
        }
        return oldData.map((d) => (d.uid === uid ? { ...d, ...question } : d))
      })
    },
  })
}

export const useDeleteAiQuestion = (
  uid: string | undefined,
): UseMutationResult<void, ResponseError, void, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: () => request.delete(`systemadmin/ask-ai-questions/${uid}`),
    onSuccess: () => {
      queryClient.setQueryData<Question[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.filter((d) => d.uid !== uid)
      })
    },
  })
}
