import { Button } from '@capturi/ui-components'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'

import Settings from './Settings'
import { useHeimdalStatus, useSyncHeimdal } from './useHeimdalStatus'

const formatNumber = (n: number): string =>
  new Intl.NumberFormat('da-DK').format(n)
const Status: React.FC = () => {
  const sync = useSyncHeimdal()
  const { data } = useHeimdalStatus()

  if (data == null) return null

  const { newestEntry, oldestEntry, totalConversationsInDatabase } = data
  return (
    <Flex justifyContent="space-between" width="100%">
      <Box ml="1">
        <Text>
          Entries in database: {formatNumber(totalConversationsInDatabase)}
        </Text>
        <Text>Oldest entry: {oldestEntry.toISOString()}</Text>
        <Text>Newest entry: {newestEntry.toISOString()}</Text>
      </Box>
      <Flex>
        <Button
          variant="ghost"
          primary
          onClick={() => sync.mutate()}
          isLoading={sync.isPending}
        >
          Sync
        </Button>
        <Settings />
      </Flex>
    </Flex>
  )
}

export default Status
