import { Button } from '@capturi/ui-components'
import { Box, Flex, IconButton, Text, Textarea } from '@chakra-ui/react'
import { subMonths } from 'date-fns'
import React, { useState } from 'react'
import { MdSearch } from 'react-icons/md'

type Props = {
  onSearch: React.Dispatch<React.SetStateAction<Filter>>
  isLoading: boolean
  showAsJson: boolean
  setShowAsJson: React.Dispatch<React.SetStateAction<boolean>>
  copyFullJson: () => void
  numberOfResults: number | undefined
}

export type Filter = Partial<{
  fromInclusive: string
  toInclusive: string
  subjects: string[]
  customers: string[]
  duration: Record<string, number>
  labels: string[]
  keyTopics: string[]
  teamUids: string[]
  userUids: string[]
  externalIdentity: string
  customProp1: string[]
  customProp2: string[]
  customProp3: string[]
  customProp4: string[]
  customProp5: string[]
  customProp6: string[]
  customProp7: string[]
  customProp8: string[]
  customProp9: string[]
  customProp10: string[]
  pageNumber: number
  pageSize: number
  sortDirection: 'Ascending' | 'Descending'
}>

const emptyState: Filter = {
  fromInclusive: '',
  toInclusive: '',
  subjects: [],
  customers: [],
  duration: {},
  labels: [],
  keyTopics: [],
  teamUids: [],
  userUids: [],
  externalIdentity: '',
  customProp1: [],
  customProp2: [],
  customProp3: [],
  customProp4: [],
  customProp5: [],
  customProp6: [],
  customProp7: [],
  customProp8: [],
  customProp9: [],
  customProp10: [],

  pageNumber: 0,
  pageSize: 20,
  sortDirection: 'Descending',
}

const removeEmpty = (obj: Record<string, unknown>): Record<string, unknown> => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ''))
}

const validateJson = (val: string): string => {
  if (val === '') return ''
  try {
    JSON.parse(val)
    return ''
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    }
    return 'invalid json'
  }
}

const SearchField: React.FC<Props> = ({
  onSearch,
  isLoading,
  showAsJson,
  setShowAsJson,
  copyFullJson,
  numberOfResults,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [state, setstate] = useState<string>(
    JSON.stringify(
      {
        ...emptyState,
        fromInclusive: subMonths(new Date(), 2),
      },
      undefined,
      '   ',
    ),
  )

  const jsonErrorMessage = validateJson(state)

  const onSubmit = () => {
    const d = JSON.parse(state)
    setstate(JSON.stringify(d, undefined, '   '))
    onSearch(removeEmpty(d))
  }

  if (!show) {
    return (
      <Box mt="-30px">
        <IconButton
          icon={<MdSearch />}
          colorScheme="primary"
          aria-label="search"
          onClick={() => setShow(true)}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Textarea
        fontFamily="monospace"
        rows={25}
        value={state}
        onChange={(e) => setstate(e.currentTarget.value)}
      />
      <Text color="red.500">{jsonErrorMessage}</Text>
      <Flex justifyContent="center" my="4" alignItems="center">
        <Button variant="ghost" onClick={copyFullJson}>
          Copy full JSON
        </Button>
        <Button variant="ghost" onClick={() => setShowAsJson((t) => !t)}>
          {showAsJson ? 'Show as Table' : 'Show as JSON'}
        </Button>
        <Button variant="ghost" onClick={() => setShow(false)}>
          Hide
        </Button>

        <Button
          isDisabled={isLoading}
          onClick={() => {
            setstate(
              JSON.stringify(
                {
                  ...emptyState,
                  fromInclusive: subMonths(new Date(), 2),
                },
                undefined,
                '   ',
              ),
            )
          }}
          mx="4"
        >
          Reset
        </Button>
        <Button
          isDisabled={jsonErrorMessage !== ''}
          isLoading={isLoading}
          onClick={onSubmit}
          primary
        >
          Search
        </Button>
        <Text ml="4" textAlign="center">
          Results: {numberOfResults}
        </Text>
      </Flex>
    </Box>
  )
}

export default SearchField
