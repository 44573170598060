import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

export type ScimConfig = {
  isEnabled: boolean
  mode: 'access' | 'full'
  deletionStrategy: 'Permanently' | 'KeepNonPersonalAndAnonymize'
}

export const useScimConfig = (organizationUid: string) => {
  const data = useGetScimConfig(organizationUid)
  const update = useUpdateScimConfig(organizationUid)

  return { ...data, update }
}

const useGetScimConfig = (
  organizationUid: string,
): UseQueryResult<ScimConfig, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'scim', organizationUid, 'authConfig'],
    queryFn: async () => {
      const result = await request.get<ScimConfig>(
        `authentication/superpowers/organization/scim/${organizationUid}`,
      )
      return result
    },
  })

const useUpdateScimConfig = (
  organizationUid: string,
): UseMutationResult<ScimConfig, ResponseError, ScimConfig, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload) =>
      request.put(
        `authentication/superpowers/organization/scim/${organizationUid}`,
        {
          json: payload,
        },
      ),
    onSuccess: (config) => {
      queryClient.setQueryData<ScimConfig>(
        ['superpowers', 'scim', organizationUid, 'authConfig'],
        config,
      )
    },
  })
}
