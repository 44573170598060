import { systemAdminAPI } from '@capturi/api-systemadmin'
import request, { getErrorMessage } from '@capturi/request'
import { DatePicker, Spinner, useToast } from '@capturi/ui-components'
import {
  Box,
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from '@chakra-ui/react'
import { addDays, addMinutes, differenceInDays, startOfDay } from 'date-fns'
import React, { useState } from 'react'
import useSWR from 'swr'

import formatDate from './formatDate'

type Props = { organizationUid: string; onClose: () => void }

const DeletionScheduling: React.FC<Props> = ({ organizationUid, onClose }) => {
  const {
    data,
    mutate,
    error: fetchError,
  } = useSWR<{ deleted: Date | null }>(
    systemAdminAPI.getOrganizationSettings(organizationUid).url,
    { suspense: false },
  )

  const toast = useToast()
  const [enabled, setEnabled] = useState(data?.deleted != null)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<null | string>(null)
  const minimumDeleteDate = addDays(new Date(), 3)
  const [deleteDate, setDeleteDate] = useState(
    data?.deleted ?? minimumDeleteDate,
  )

  const handleSave = async (): Promise<void> => {
    try {
      const now = new Date()
      const diffInDays = differenceInDays(
        startOfDay(deleteDate),
        startOfDay(now),
      )
      const deleteDateTime = addMinutes(addDays(now, diffInDays), 1)

      setInProgress(true)

      await request.patch(
        systemAdminAPI.getOrganizationSettings(organizationUid).url,
        {
          json: {
            deleted: enabled ? deleteDateTime : null,
          },
        },
      )
      mutate(() => ({ deleted: enabled ? deleteDateTime : null }))
      toast({
        title: enabled
          ? `Organisation sheduled for deletion at ${formatDate(
              deleteDateTime,
            )}`
          : 'Organisation deletion cancelled',
      })
      onClose()
    } catch (error) {
      setInProgress(false)
      const errorMessage = getErrorMessage(error)
      setError(errorMessage)
    }
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Organisation Deletion</ModalHeader>
        {<ModalCloseButton />}

        <ModalBody my="4">
          {!data && <Spinner />}
          {fetchError && <Text>{fetchError.message}</Text>}
          {data && (
            <>
              <FormControl whiteSpace="nowrap" alignItems="center">
                <Flex flexDir="column" alignItems="flex-start">
                  <Flex alignItems="center">
                    <Switch
                      key="enableDeleteCustomer"
                      isChecked={enabled}
                      onChange={() => setEnabled((val) => !val)}
                      isDisabled={inProgress || !data}
                      size="sm"
                      mr="4"
                    />
                    <Text m="0" fontWeight="normal">
                      Schedule organisation deletion
                    </Text>
                  </Flex>
                  {enabled && (
                    <Flex flexDir="column" alignItems="flex-start" mt={4}>
                      <Box mt={4}>
                        <DatePicker
                          selected={deleteDate}
                          defaultMonth={deleteDate}
                          disabled={[{ before: minimumDeleteDate }]}
                          onSelectDate={(date) => {
                            setDeleteDate(date)
                          }}
                        />
                      </Box>
                    </Flex>
                  )}
                </Flex>
              </FormControl>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={inProgress} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={inProgress}
            colorScheme="primary"
            onClick={handleSave}
          >
            Update
          </Button>
        </ModalFooter>
        {error != null && (
          <Text textAlign="center" p="3" color="danger">
            {error}
          </Text>
        )}
      </ModalContent>
    </Modal>
  )
}

export default DeletionScheduling
