export const convertToCsv = (data: Record<string, unknown>[]): string => {
  const delimiter = ','
  const lineBreak = '\n'
  let result = ''

  const headers = getHeaders(data[0])

  result = headers.join(delimiter) + lineBreak

  data.forEach((val) => {
    headers.forEach((h) => {
      const value = val[h]
      const isEmptyValue = value === undefined || value === null
      if (isEmptyValue) {
        result = result + delimiter
      } else if (typeof value === 'object' && value instanceof Date) {
        const cell = value.toLocaleString('da-DK')
        result = result + cell + delimiter
      } else if (typeof value === 'object') {
        const cell = JSON.stringify(value).replaceAll(/{|}|\[|\]|"/g, '')
        if (cell.includes(',')) {
          result = `${result}"${cell}"${delimiter}`
        } else {
          result = result + cell + delimiter
        }
      } else {
        result = result + value + delimiter
      }
    })

    result = result.slice(0, -1) + lineBreak
  })

  return result
}

const getHeaders = (obj: Record<string, unknown>): string[] => {
  return Object.keys(obj)
}
