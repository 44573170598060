import { useScrollable } from '@capturi/react-utils'
import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Box, Flex, Text, Textarea } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import React, { useRef, useState } from 'react'
import { DEFAULT_SESSIONS } from './defaultSessions'

type Props = {} & BaseModalComponentProps
type Response = Record<string, Record<string, string | Date | null>[]>

type Request = {
  sql: string
  sessions: { integrationKey: string; uids: string[] }[]
}

const validateJson = (val: string): string => {
  if (val === '') return ''
  try {
    JSON.parse(val)
    return ''
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    }
    return 'invalid json'
  }
}

const GeneratePuzzelTestData: React.FC<Props> = ({ onClose }) => {
  const [sessions, setSessions] = useState(DEFAULT_SESSIONS)
  const [command, setCommand] = useState('select top(10) * from call_events')

  const ref = useRef(null)
  const height = useScrollable(ref, 70)
  const { data, isPending, error, mutate } = useMutation<
    Response,
    ResponseError,
    Request
  >({
    mutationFn: async (payload) => {
      const result = await request.post<Response>(
        'superpowers/integration/puzzel/all/sql/generate-test-data',
        {
          json: payload,
          timeout: 100_000_000,
        },
      )

      for (const sessionsUid in result) {
        for (let index = 0; index < result[sessionsUid].length; index++) {
          const element = result[sessionsUid][index]

          //For making the type matching the one the test expects
          if ('call_id' in element) {
            result[sessionsUid][index].call_id = String(
              result[sessionsUid][index].call_id,
            )
          }
        }
      }

      return result
    },
  })

  const errorMessage = validateJson(sessions)

  return (
    <Modal isOpen onClose={onClose} size="full">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>PUZZEL SQL </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Box width="100%" m="4">
                Query
                <Textarea
                  fontFamily="monospace"
                  height="300"
                  placeholder="sql statement"
                  value={command}
                  onChange={(e) => setCommand(e.currentTarget.value)}
                />
              </Box>
              <Box width="50%" m="4">
                Sessions
                <Textarea
                  height="300"
                  placeholder="sessions"
                  value={sessions}
                  onChange={(e) => setSessions(e.currentTarget.value)}
                />
              </Box>
            </Flex>
            <Flex>
              <Button
                mx="2"
                isLoading={isPending}
                isDisabled={errorMessage !== ''}
                primary
                onClick={() => {
                  const s = JSON.parse(sessions)
                  mutate({ sessions: s, sql: command })
                }}
              >
                query
              </Button>
              <Button
                isDisabled={isPending || !data}
                mr="4"
                variant="outline"
                primary
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.stringify(data, undefined, '   '),
                  )
                }}
              >
                Copy json
              </Button>
            </Flex>
            {error && <Text>{error.message}</Text>}

            {data && (
              <Box
                m="4"
                whiteSpace="nowrap"
                fontFamily="monospace"
                overflow="auto"
                ref={ref}
                height={`${height}px`}
                bg="gray.100"
                p="2"
                borderRadius="8"
              >
                <Text whiteSpace="break-spaces">
                  {JSON.stringify(data || '', undefined, '   ')}
                </Text>
              </Box>
            )}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button primary onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default GeneratePuzzelTestData
