import { Spinner, useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  StackDivider,
  Switch,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { Title } from '../../../../utils'
import ScimTokens from './Tokens'
import ViewLogLinkModal from './ViewLogLinkModal'
import ViewLogsModal from './ViewLogsModal'
import { ScimConfig, useScimConfig } from './useScimConfig'
import { useCreateScimLogToken } from './useScimLogs'

type Props = { organizationUid: string }

const Scim: React.FC<Props> = ({ organizationUid }) => {
  const [openViewLogsModal] = useModal(ViewLogsModal)
  const [openViewLogLinkModal] = useModal(ViewLogLinkModal)

  const { data, error, isLoading, update } = useScimConfig(organizationUid)
  const logLink = useCreateScimLogToken(organizationUid)
  const toast = useToast()
  if (isLoading || !data) {
    return (
      <Card mb="8">
        <CardHeader>
          <Title>SCIM</Title>
        </CardHeader>
        <CardBody display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </CardBody>
      </Card>
    )
  }

  if (error) {
    return <Text color="red.500">{error.message}</Text>
  }

  const updateConfig = (payload: Partial<ScimConfig>) => {
    update.mutate(
      { ...data, ...payload },
      {
        onSuccess: () => {
          toast({
            title: 'Updated scim config',
            status: 'success',
          })
        },
        onError: (e) => {
          toast({
            title: 'Could not update scim config',
            description: e.message,
            status: 'error',
          })
        },
      },
    )
  }

  const handleCreateLogLink = () => {
    logLink.mutate(null, {
      onSuccess: (data) => {
        openViewLogLinkModal({ token: data.token })
      },
      onError: (error) => {
        toast({
          title: 'Could not create log link',
          description: error.message,
          status: 'error',
        })
      },
    })
  }

  return (
    <Card mb="8">
      <CardHeader display="flex" justifyContent="space-between">
        <Flex alignItems="center">
          <Switch
            size="sm"
            color="primary"
            isChecked={data.isEnabled}
            onChange={(e) =>
              updateConfig({ isEnabled: e.currentTarget.checked })
            }
            mr={2}
            isDisabled={update.isPending}
          />
          <Title>SCIM</Title>
        </Flex>
        <HStack>
          {update.isPending ? <Spinner delay={0} /> : null}
          <Button
            onClick={() => {
              handleCreateLogLink()
            }}
          >
            Create Log Link
          </Button>
          <Button
            onClick={() => {
              openViewLogsModal({ organizationUid })
            }}
          >
            View Logs
          </Button>
        </HStack>
      </CardHeader>
      <CardBody>
        <Stack
          // width="100%"
          spacing={10}
          direction="row"
          divider={<StackDivider borderColor="gray.200" />}
        >
          <Box width="45%">
            <Text mb="2" fontSize="md">
              Deletion Strategy
            </Text>
            <RadioGroup
              colorScheme={data.isEnabled ? 'primary' : 'gray'}
              isDisabled={update.isPending}
              ml="4"
              onChange={(val: ScimConfig['deletionStrategy']) =>
                updateConfig({ deletionStrategy: val })
              }
              value={data.deletionStrategy}
            >
              <Stack direction="column">
                <Radio value="KeepNonPersonalAndAnonymize">
                  KeepNonPersonalAndAnonymize
                </Radio>
                <Radio value="Permanently">Permanently</Radio>
              </Stack>
            </RadioGroup>

            <Text mt="4" fontSize="md">
              Sync Mode
            </Text>
            <RadioGroup
              colorScheme={data.isEnabled ? 'primary' : 'gray'}
              isDisabled={update.isPending}
              ml="4"
              onChange={(val: ScimConfig['mode']) =>
                updateConfig({ mode: val })
              }
              value={data.mode}
            >
              <Stack direction="column">
                <Radio value="access">Access Only</Radio>
                <Radio value="full">Full</Radio>
              </Stack>
            </RadioGroup>
          </Box>
          <ScimTokens organizationUid={organizationUid} />
        </Stack>
      </CardBody>
    </Card>
  )
}

export default Scim
