import { applyPermissionsRules } from '@capturi/core'
import {
  Box,
  Flex,
  Icon,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  chakra,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { MdInfoOutline } from 'react-icons/md'

import {
  UiRole,
  UserPermissions,
  defaultPermissionsPerRole,
} from './permissionsUtils'

type Props = {
  permissions: UserPermissions
  setPermissions: React.Dispatch<React.SetStateAction<UserPermissions>>
  inProgress: boolean
  role: UiRole
}

type PermissionRow = {
  key: keyof UserPermissions
  name: string
  tooltip: string
  disabled?: boolean
}

const Permissions: React.FC<Props> = ({
  setPermissions,
  permissions,
  inProgress,
  role,
}) => {
  const crudPermissions: PermissionRow[] = [
    {
      name: 'Organization',
      key: 'organization',
      tooltip:
        'The user will be be able to change organization setup and users.',
    },
    {
      name: 'Dashboard',
      key: 'editDashboard',
      tooltip:
        'The user will be be able to create, edit and delete dashboards.',
      disabled: permissions.editEverything || undefined, // if user can editEverything, disable editing of editDashboard
    },
    {
      name: 'Trackers',
      key: 'editTracker',
      tooltip: 'The user will be be able to create, edit and delete trackers.',
      disabled: permissions.editEverything || undefined, // if user can editEverything, disable editing of editTracker
    },
    {
      name: 'Segments',
      key: 'editSegment',
      tooltip: 'The user will be be able to create, edit and delete segments.',
      disabled: permissions.editEverything || undefined, // if user can editEverything, disable editing of editSegment
    },
    {
      name: 'Scores',
      key: 'editScore',
      tooltip: 'The user will be be able to create, edit and delete scores.',
      disabled: permissions.editEverything || undefined, // if user can editEverything, disable editing of editScore
    },
    {
      name: 'Library',
      key: 'editLibrary',
      tooltip:
        'The user will be be able to create, edit, delete library playlists and add snippets to the library.',
      disabled: permissions.editEverything || undefined, // if user can editEverything, disable editing of editLibrary
    },
    {
      name: 'Master tracker',
      key: 'editMasterTracker',
      tooltip: 'The user can create, update and delete master trackers',
    },
    {
      name: 'Reports',
      key: 'viewReports',
      tooltip: 'The user can view analytics reports',
    },
    {
      name: 'Text',
      key: 'text',
      tooltip: 'The user ave access to all text stuff',
    },
    {
      name: 'Quality Assurance',
      key: 'qualityAssurance',
      tooltip:
        'Permission to view and edit functionality regarding ‘quality assurance’',
    },
    {
      name: 'Hide User Info',
      key: 'hideUserInfo',
      tooltip: 'Permission will hide all agents names and emails.',
    },
  ]
  const playbackPermissions: PermissionRow[] = [
    {
      name: 'Playback',
      key: 'playback',
      tooltip:
        'The user will be be able to play conversations within Capturi. Library snippets are NOT effected by this setting',
    },
    {
      name: 'Download',
      key: 'download',
      tooltip:
        'The user can download audio (conversations and library snippets) directly from the user interface within Capturi.',
      disabled: permissions.playback === false || undefined, // disable download, if playback is false
    },
  ]

  const togglePermission = (
    key: keyof UserPermissions,
    value: boolean,
  ): void => {
    const userPermissions = applyPermissionsRules(permissions, key, value)
    setPermissions(userPermissions)
  }

  const renderRow = ({ key, name, tooltip }: PermissionRow): ReactElement => {
    return (
      <Tr key={key}>
        <Td w="100%">
          {name}
          <Tooltip hasArrow label={tooltip}>
            <chakra.span ml={1}>
              <Icon as={MdInfoOutline} />
            </chakra.span>
          </Tooltip>
        </Td>
        <Td>
          <chakra.span ml={1}>
            <Switch
              isDisabled={inProgress}
              onChange={(e) => togglePermission(key, e.currentTarget.checked)}
              isChecked={permissions[key]}
            />
          </chakra.span>
        </Td>
      </Tr>
    )
  }

  const renderHeader = (header: string, text: string): ReactElement => {
    return (
      <Box py="4">
        <Flex>
          <Text fontWeight="semibold" fontSize="md">
            {header}
          </Text>
        </Flex>
        <Text>{text}</Text>
      </Box>
    )
  }

  const allowedToChangeEditEverything =
    defaultPermissionsPerRole[role]?.editEverything == null
  return (
    <div>
      {role === 'owner' && (
        <>
          <Flex
            alignItems="start"
            justifyContent="space-between"
            data-stonly="editEverythingPermission"
            backgroundColor="gray.100"
            borderRadius="4px"
            px={2}
            border="1px solid"
            borderColor="gray.300"
            mt={4}
            mb={1}
          >
            {renderHeader(
              'Edit everything ⭐',
              'Edit rights to all functionalities in Capturi.',
            )}
            <chakra.span ml={1}>
              <Switch
                py={2}
                isDisabled={inProgress || allowedToChangeEditEverything}
                onChange={(e) =>
                  togglePermission('editEverything', e.currentTarget.checked)
                }
                isChecked={permissions.editEverything}
              />
            </chakra.span>
          </Flex>
        </>
      )}
      {renderHeader(
        'Create, edit and delete',
        'Permission to create/edit/delete dashboards, trackers, segments, scores and library playlists/snippets.',
      )}
      <Table size="sm">
        <Tbody>{crudPermissions.map(renderRow)}</Tbody>
      </Table>

      {renderHeader(
        'Playback',
        'Specify whether the user should be able to play and download conversations. Audio snippets added to the library will continue to be available.',
      )}
      <Table size="sm">
        <Tbody>{playbackPermissions.map(renderRow)}</Tbody>
      </Table>
    </div>
  )
}

export default Permissions
