import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import type { ConfigField } from '../IntegrationConfigSchemaType'
import { Field } from '../fields/Field'
import { useAddAudioImporter } from '../useIntegrationConfig'

type Props = {
  schema: ConfigField[]
  integrationType: string
  organizationName: string
  organizationUid: string
  onClose: () => void
}

const extractDefaultValues = (fields: ConfigField[]) => {
  return fields.reduce<Record<string, unknown>>((memo, f) => {
    memo[f.key] = f.defaultValue

    return memo
  }, {})
}
export const IntegrationAuthConfig: React.FC<Props> = ({
  schema,
  integrationType,
  onClose,
  organizationName,
  organizationUid,
}) => {
  const toast = useToast()
  const { mutate, isPending } = useAddAudioImporter(integrationType)

  const [auth, setAuth] = useState<Record<string, unknown>>(
    extractDefaultValues(schema),
  )

  const handleUpdate = () => {
    mutate(
      { integrationAuth: auth, organizationName, organizationUid },
      {
        onSuccess: () => {
          toast({ status: 'success', title: 'Integration Added' })
          onClose()
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: error.name,
            description: error.message,
          })
        },
      },
    )
  }

  return (
    <ModalContent>
      <ModalHeader>Add {integrationType} Integration</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {schema.map((f) => (
          <Box my="3" key={f.key}>
            <Field
              key={f.key}
              field={f}
              onChange={(v) => setAuth((s) => ({ ...s, [f.key]: v }))}
              value={auth[f.key]}
            />
          </Box>
        ))}
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <Button mr="4" isDisabled={isPending} onClick={onClose}>
          Cancel
        </Button>
        <Button primary isLoading={isPending} onClick={handleUpdate}>
          Save
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
