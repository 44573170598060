import request from '@capturi/request'
import { Button } from '@chakra-ui/react'
import React, { useState } from 'react'

type Props = {
  organizationUid: string
  integrationKey: string
  integrationType: string
}

const UpdateApiToken: React.FC<Props> = ({
  organizationUid,
  integrationKey,
  integrationType,
}) => {
  const [inProgress, setInProgress] = useState<boolean>(false)

  const updateToken = async (): Promise<void> => {
    try {
      setInProgress(true)

      await request.post(
        `superpowers/integration/${organizationUid}/refresh-token`,
        {
          json: {
            integrationKey: integrationKey,
            integrationType: integrationType,
          },
        },
      )
      setInProgress(false)
    } catch (_error) {
      setInProgress(false)
    }
  }

  return (
    <Button mx="3" isLoading={inProgress} onClick={updateToken}>
      Update api token
    </Button>
  )
}

export default UpdateApiToken
