export const DEFAULT_SESSIONS = `[
    {
        "integrationKey": "norlys-master",
        "uids": [
            "7a5c62e2-f802-407c-8ba4-800e78a681cb",
            "59c49c46-da66-48ee-bbf6-7a083102b1e7",
            "af221e23-ddfb-439d-92e6-fbb7b51a067c",
            "aaaeaa4e-41c0-47f0-a917-8cf791bcd21d",
            "9b586361-af95-4329-9425-c6000c03dc60",
            "e86f91cb-5eb9-4294-aa20-db92e6b5d216",
            "71043a3c-cdd0-4b87-8614-3b06b2a997e0",
            "653a71dd-8afc-4870-9848-2732f60f451a",
            "8b7ced25-d971-463f-80f2-d60aafb802da",
            "84d6cf9e-d9f5-4476-9ef4-5f5847ceef07",
            "000cbc22-8d51-4f80-9662-7dea8cc37808",
            "007502dd-7d48-4560-968f-52b652398a45",
            "007b4cc6-9121-43b9-af4b-41159a581adf",
            "33247c14-91a9-4d1d-b27f-d804d9bae313"
        ]
    },
    {
        "integrationKey": "norlys-n1-1",
        "uids": [
            "e3035043-79dd-49a0-a54b-40dbf3452587",
            "fe2f5b4c-f2e5-45d6-a7b6-de91cd7fc6b6",
            "fec1a350-b69d-4516-8fde-0997bc23853b",
            "cf5c0efc-82d4-4443-9e6e-1ca519b3df59",
            "4dc9377e-88b9-4e79-ac2b-40ed2a6535d1"
        ]
    },
    {
        "integrationKey": "dcc-energi",
        "uids": [
            "2b14140c-a68d-495a-be45-2ed923271f75"
        ]
    },
    {
        "integrationKey": "jysk-1",
        "uids": [
            "9058b80f-0b21-4cc4-acce-de7ffcdc9c8c"
        ]
    },
    {
        "integrationKey": "bredband2-ab",
        "uids": [
            "a00a3d0b-92fe-4745-8498-8e2a4919afbf"
        ]
    }
]`
