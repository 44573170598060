import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

export type Token = {
  TokenID: string
  TokenHash: string
  OrganizationUid: string
  Created: Date
  SyncActive: boolean
}
export type CreateTokenResult = { Secret: string; Token: Token }

const selectForSingleOrg = (organizationUid: string, data: Token[]) =>
  data
    .filter((d) => d.OrganizationUid === organizationUid)
    .sort((a, b) => a.Created.getTime() - b.Created.getTime())

export const useGetScimToken = (
  organizationUid: string,
): UseQueryResult<Token[], ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'scim', 'tokens'],
    queryFn: async () => {
      const result = await request.get<Token[]>('superpowers/scim/list')
      return result
    },
    select: (data) => selectForSingleOrg(organizationUid, data),
  })

export const useCreateScimToken = (
  organizationUid: string,
): UseMutationResult<CreateTokenResult, ResponseError, null, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => request.post(`superpowers/scim/${organizationUid}`),
    onSuccess: (data) => {
      queryClient.setQueryData<Token[]>(
        ['superpowers', 'scim', 'tokens'],
        (oldData = []) => [data.Token, ...oldData],
      )
    },
  })
}
export const useDeleteScimToken = (
  organizationUid: string,
): UseMutationResult<null, ResponseError, string, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (token) =>
      request.delete(`superpowers/scim/${organizationUid}/${token}`),
    onSuccess: (_, token) => {
      queryClient.setQueryData<Token[]>(
        ['superpowers', 'scim', 'tokens'],
        (oldData = []) => oldData.filter((d) => d.TokenID !== token),
      )
    },
  })
}
