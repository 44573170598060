import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

type KeyTopicsBlacklistResponse = {
  lastUpdatedByUser: string | null
  lastUpdatedAt: string
  language: string
  blacklistedPatterns: string[]
}
type SupportedLanguagesResponse = string[]

type KeyTopicsBlacklistUpdateModel = {
  language: string
  patterns: string[]
}

export const useAllSupportedLanguages =
  (): UseQueryResult<SupportedLanguagesResponse> =>
    useQuery({
      queryKey: ['supported-languages'],
      queryFn: () =>
        request.get<SupportedLanguagesResponse>(
          'superpowers/asr/supported-languages',
        ),
      refetchOnWindowFocus: false,
    })
export const useKeyTopicsBlacklist = (
  language: string,
): UseQueryResult<KeyTopicsBlacklistResponse> =>
  useQuery({
    queryKey: ['keyTopicsBlacklist', language],
    queryFn: () =>
      request.get<KeyTopicsBlacklistResponse>(
        `superpowers/asr/key-topics-blacklist/${language}`,
      ),
    refetchOnWindowFocus: false,
  })

export const useUpdateKeyTopicsBlacklist = (): UseMutationResult<
  KeyTopicsBlacklistUpdateModel,
  ResponseError,
  Partial<KeyTopicsBlacklistUpdateModel>,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (model: Partial<KeyTopicsBlacklistUpdateModel>) =>
      request.put<KeyTopicsBlacklistUpdateModel>(
        `superpowers/asr/key-topics-blacklist/${model.language}`,
        {
          json: model.patterns,
        },
      ),
    onSuccess: (newBlacklist, { language }) => {
      queryClient.setQueryData<string[]>(
        ['keyTopicsBlacklist', language],
        () => {
          return newBlacklist.patterns
        },
      )
    },
  })
}
