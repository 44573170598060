import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text, Textarea } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../../Loader'
import {
  useIntegrationAuth,
  useMutateIntegrationAuth,
} from './useIntegrationAuth'

type Props = {
  integrationType: string
  integrationKey: string
} & BaseModalComponentProps

const validateJson = (val: string): string => {
  if (val === '') return ''
  try {
    JSON.parse(val)
    return ''
  } catch (error) {
    if (error instanceof Error) {
      return error.message
    }
    return 'invalid json'
  }
}

const IntegrationAuthModal: React.FC<Props> = ({
  onClose,
  integrationType,
  integrationKey,
}) => {
  const { isLoading, data, isSuccess } = useIntegrationAuth(
    integrationType,
    integrationKey,
  )

  const ogData = useRef('')

  const [auth, setAuth] = useState<string>('')
  useEffect(() => {
    if (auth === '' && isSuccess) {
      const s = JSON.stringify(data, undefined, '   ')
      ogData.current = s
      setAuth(s)
    }
  }, [auth, isSuccess, data])

  const { mutate, isPending: isMutating } = useMutateIntegrationAuth(
    integrationType,
    integrationKey,
  )

  const handleUpdate = (): void => {
    const parsed = JSON.parse(auth)
    mutate(parsed, { onSuccess: () => onClose() })
  }

  const jsonErrorMessage = validateJson(auth)

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Auth</ModalHeader>

          <ModalCloseButton />
          <ModalBody minHeight="440px">
            {isLoading ? (
              <Loader marginTop="45%" />
            ) : (
              <Textarea
                height="400px"
                value={auth}
                onChange={(e) => setAuth(e.currentTarget.value)}
              />
            )}
            <Text height={'30px'}>{jsonErrorMessage}</Text>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button mr="2" onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
            <Button
              colorScheme="primary"
              isLoading={isMutating || isLoading}
              onClick={handleUpdate}
              isDisabled={jsonErrorMessage !== '' || auth === ogData.current}
            >
              <Trans>Update</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default IntegrationAuthModal
