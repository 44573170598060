import request, { ResponseError } from '@capturi/request'
import { Button, useToast } from '@capturi/ui-components'
import { Flex, Text } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React, { ReactElement, useEffect, useState } from 'react'

import PhrasesEditor from '../components/PhrasesEditor'

type Response = {
  extraStopWords: string[]
  extraStopNgrams: string[]
  nonStopWords: string[]
  version?: number
}

const isDirty = (originalArray: string[], newArray: string[]): boolean => {
  if (originalArray.length !== newArray.length) return true

  return originalArray.some((og) => !newArray.includes(og))
}

const StopWords: React.FC = () => {
  const [languageTag, setLanguageTag] = useState<string>('da-DK')
  const url = `superpowers/asr/stop-words/${languageTag}`
  const { data } = useQuery<Response>({ queryKey: [url] })
  const toast = useToast()
  const [extraStopWords, setExtraStopWords] = useState<string[]>([])
  const [extraStopNgrams, setExtraStopNgrams] = useState<string[]>([])
  const [nonStopWords, setNonStopWords] = useState<string[]>([])

  const queryClient = useQueryClient()

  useEffect(() => {
    if (data) {
      setExtraStopWords(data.extraStopWords)
      setExtraStopNgrams(data.extraStopNgrams)
      setNonStopWords(data.nonStopWords)
    }
  }, [data])

  const mutate = useMutation<Response, ResponseError, Response>({
    mutationFn: (model) =>
      request.put(url, {
        json: model,
      }),
    onMutate: async (model) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [url],
      })
      // Optimistically update to the new value
      queryClient.setQueryData<Response>([url], model)
      return
    },
    onSuccess: () => {
      toast({ status: 'success', title: 'Words updated' })
    },
  })

  const handleSave = (): void => {
    mutate.mutate({
      extraStopWords,
      extraStopNgrams,
      nonStopWords,
    })
  }

  const renderButton = (
    text: string,
    tag: string,
    placement?: 'start' | 'end',
  ): ReactElement => {
    return (
      <Button
        primary
        borderLeftRadius={placement === 'start' ? 'lg' : 0}
        borderRightRadius={placement === 'end' ? 'lg' : 0}
        variant={languageTag === tag ? 'solid' : 'outline'}
        onClick={() => setLanguageTag(tag)}
      >
        {text}
      </Button>
    )
  }

  const extraStopWordsIsDirty = isDirty(
    data?.extraStopWords || [],
    extraStopWords,
  )
  const extraStopNgramsIsDirty = isDirty(
    data?.extraStopNgrams || [],
    extraStopNgrams,
  )
  const nonStopWordsIsDirty = isDirty(data?.nonStopWords || [], nonStopWords)
  return (
    <>
      <Flex align="center" mb="4" justifyContent="center">
        {renderButton('Danish', 'da-DK', 'start')}
        {renderButton('Swedish', 'sv-SE')}
        {renderButton('Norwegian', 'nb-NO')}
        {renderButton('English', 'en-US', 'end')}
      </Flex>

      {data && (
        <>
          <PhrasesEditor
            state={extraStopWords}
            onChange={setExtraStopWords}
            isLoading={mutate.isPending}
            isSuccess={!extraStopWordsIsDirty}
            title="Stop words"
            placeholder="Stop word"
            mb="4"
          />
          <PhrasesEditor
            title="Stop Ngrams"
            placeholder="Stop Ngrams"
            state={extraStopNgrams}
            onChange={setExtraStopNgrams}
            isLoading={mutate.isPending}
            isSuccess={!extraStopNgramsIsDirty}
            mb="4"
          />
          <PhrasesEditor
            title="Non Stop Words"
            placeholder="Non Stop Word"
            state={nonStopWords}
            onChange={setNonStopWords}
            isLoading={mutate.isPending}
            isSuccess={!nonStopWordsIsDirty}
            mb="4"
          />

          <Button
            isDisabled={
              !(
                nonStopWordsIsDirty ||
                extraStopWordsIsDirty ||
                extraStopNgramsIsDirty
              )
            }
            primary
            onClick={handleSave}
            isLoading={mutate.isPending}
          >
            Save
          </Button>
          {mutate.error && <Text>{mutate.error.message}</Text>}
        </>
      )}
    </>
  )
}

export default StopWords
