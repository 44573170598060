import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import type { FC } from 'react'
type Props = {
  label: string
  fieldKey: string
  value: number
  onChange: (values: number) => void
  isDisabled?: boolean
}

export const NumberField: FC<Props> = ({
  fieldKey,
  label,
  onChange,
  value,
  isDisabled,
}) => {
  return (
    <FormControl mt="2" key={fieldKey}>
      <FormLabel mb="0" ml="1" htmlFor={fieldKey}>
        {label}
      </FormLabel>
      <Input
        type="number"
        isDisabled={isDisabled}
        id={fieldKey}
        value={value}
        onChange={(event) => onChange(Number(event.currentTarget.value))}
        autoComplete="none"
      />
    </FormControl>
  )
}
