import React from 'react'

import { Row, SmartRow, TableCard } from '../components'
import type { ConversationTranscriptionInfo as Data } from '../types'
import {
  AsrPriority as AsrPriorityEnum,
  AsrProcessingStatus as AsrProcessingStatusEnum,
  MonoNumSpeakers as MonoNumSpeakersEnum,
} from '../types'

type Props = { data: Data }

const ConversationTranscriptionInfo: React.FC<Props> = ({ data }) => {
  const { AsrProcessingStatus, MonoNumSpeakers, AsrPriority, ...rest } = data

  return (
    <TableCard title="Transcription info">
      {Row('AsrProcessingStatus', AsrProcessingStatusEnum[AsrProcessingStatus])}
      {Row('MonoNumSpeakers', MonoNumSpeakersEnum[MonoNumSpeakers])}
      {Row('AsrPriority', AsrPriorityEnum[AsrPriority])}
      {Object.entries(rest).map(([key, val]) => SmartRow(key, val))}
    </TableCard>
  )
}

export default ConversationTranscriptionInfo
