import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { ReactElement, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'

import {
  SummaryPrompt,
  useSummaryPrompts,
} from '../../FeatureFlags/api/useSummaryPrompts'
import CustomSummaryPromptsEditor from './CustomSummaryPromptsEditor'

type Props = BaseModalComponentProps

const CustomSummaryPromptsModal: React.FC<Props> = ({ onClose }) => {
  const { data = [] } = useSummaryPrompts()
  const [summary, setSummary] = useState<null | SummaryPrompt>(null)

  const renderItem = (s: SummaryPrompt): ReactElement => {
    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        key={s.uid}
        _hover={{ bg: 'gray.200' }}
        cursor="pointer"
        p="2"
        width="100%"
        borderBottom="1px solid"
        borderColor="gray.100"
        onClick={() => setSummary(s)}
      >
        <Text>{s.name}</Text>
        <MdChevronRight />
      </Flex>
    )
  }

  return (
    <Modal isOpen onClose={onClose} size="4xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Default Prompts</ModalHeader>

          <ModalCloseButton />
          <ModalBody display="flex" minHeight="500px">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              flex="50%"
              borderRight="1px solid"
              borderColor="gray.100"
              pr="4"
            >
              <Box>{data.map(renderItem)}</Box>
              <Button
                mt="4"
                onClick={() => setSummary({ name: '', prompt: '', uid: '' })}
              >
                Add
              </Button>
            </Flex>

            <Box flex="50%">
              {summary && (
                <CustomSummaryPromptsEditor
                  key={summary.uid}
                  onClose={() => setSummary(null)}
                  name={summary.name}
                  prompt={summary.prompt}
                  uid={summary.uid}
                />
              )}
            </Box>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button ml="4" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CustomSummaryPromptsModal
