import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React, { ReactElement, useState } from 'react'

import Loader from '../../../Loader'

type Props = { organizationUid: string }
type Response = {
  year: number
  month: number
  agents: number
  startDate: Date
  endDate: Date
}

const getMonth = new Intl.DateTimeFormat('da', {
  month: 'long',
}).format
const formatRange = (startDate: Date, endDate: Date): string =>
  new Intl.DateTimeFormat('da', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).formatRange(startDate, endDate)
const formatShortRange = (startDate: Date, endDate: Date): string =>
  new Intl.DateTimeFormat('da', {
    day: 'numeric',
  }).formatRange(startDate, endDate)

const formatNumber = new Intl.NumberFormat('da-DK', {
  maximumFractionDigits: 2,
})
const numberFormat = (number: number): string => formatNumber.format(number)

const AgentsPerMonth: React.FC<Props> = ({ organizationUid }) => {
  const [monthsToLookBack, setMonthsToLookBack] = useState<number>(3)
  const {
    data = [],
    error,
    isFetching,
  } = useQuery<Response[], ResponseError>({
    queryFn: async () =>
      request.get(
        `superpowers/organization/${organizationUid}/insights/unique-agents-per-month?monthsToLookBack=${monthsToLookBack}`,
      ),
    queryKey: [
      'superpowers',
      organizationUid,
      'uniqueAgentsPerMonth',
      monthsToLookBack,
    ],
  })

  if (error) {
    return <Text color="danger">{error.message}</Text>
  }

  const renderHeader = (d: Response): ReactElement => {
    const { startDate, endDate, month } = d

    return (
      <Th key={month}>
        <Tooltip shouldWrapChildren label={formatRange(startDate, endDate)}>
          <Text fontWeight="light">{formatShortRange(startDate, endDate)}</Text>
          <div>{getMonth(startDate)}</div>
        </Tooltip>
      </Th>
    )
  }

  return (
    <Box mt="20">
      <Flex width="100%" justifyContent="space-between">
        <div>
          <Text fontSize="xl">Unique Agents per Month</Text>
          <Text color="subtitle">
            Unique agents we have imported conversations for per month
          </Text>
        </div>
        <Flex alignItems="center">
          <NumberInput
            title="Months to look back"
            size="xs"
            width="32"
            min={1}
            mr="4"
            onChange={(e) => {
              setMonthsToLookBack(Number(e))
            }}
            value={monthsToLookBack}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Flex>
      {!data && isFetching ? (
        <Flex my="6">
          <Loader />
        </Flex>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Month</Th>
              {data.map(renderHeader)}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Th as="td">Agents</Th>
              {data.map((d) => (
                <Td key={d.month}>
                  <Tooltip
                    shouldWrapChildren
                    label={formatRange(d.startDate, d.endDate)}
                  >
                    {d.agents}
                  </Tooltip>
                </Td>
              ))}
            </Tr>
          </Tbody>
          <TableCaption>{`Average agents per month the last ${
            data.length
          } months: ${numberFormat(calculateAverage(data))}`}</TableCaption>
        </Table>
      )}
    </Box>
  )
}

const calculateAverage = (data: Response[]): number => {
  const numberOfWeeks = data.length
  const totalAgents = data.reduce((memo, { agents }) => agents + memo, 0)
  return totalAgents / numberOfWeeks
}

export default AgentsPerMonth
