import { useMemo } from 'react'
import useSWR from 'swr'

import { integrations } from '../integrations'

type Organization = {
  createdBy: string
  name: string
  uid: string
  uiLanguage: string
  conversationLanguage: string
  toBeDeleted: boolean
}
type IntegrationResponse = {
  organizationUid: string
  integrationKey: string
  active: boolean
  integrationType: string
}

type Expand<T> = T extends infer O ? { [K in keyof O]: O[K] } : never
export type integration = Expand<Organization & IntegrationResponse>
export type Integrations = {
  integrationType: string
  orgs: Expand<integration>[]
}

export const useIntegrations = (): Expand<Integrations>[] => {
  const { data: orgs } = useSWR<Organization[]>('superpowers/organization', {
    suspense: false,
  })
  const { data: serverIntegrations } = useSWR<IntegrationResponse[]>(
    'superpowers/integration/list',
    {
      suspense: false,
    },
  )

  const int = useMemo(() => {
    if (!(orgs && serverIntegrations)) return []

    const allIntegrations = new Set([
      ...integrations.keys(),
      ...serverIntegrations.map((i) => i.integrationType),
    ])

    const orgMap = new Map(orgs.map((o) => [o.uid, o]))

    const integrationMap = (serverIntegrations || []).reduce((memo, i) => {
      const current = memo.get(i.integrationType) || []
      const org = orgMap.get(i.organizationUid)
      if (org) {
        current.push({ ...i, ...org })
        memo.set(i.integrationType, current)
      }

      return memo
    }, new Map<string, integration[]>())

    const res = [...allIntegrations].map((integrationType) => ({
      integrationType: integrationType,
      orgs: integrationMap.get(integrationType) || [],
    }))
    return res
  }, [orgs, serverIntegrations])

  return int
}
