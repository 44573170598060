import { Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { formatSeconds } from '../../utils'
import { Card } from '../components'
import { UserConversation } from '../types'

type Props = { data: UserConversation[] }

const UserConversations: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()

  const renderRow = (row: UserConversation, index: number): ReactElement => {
    const { DateTime, Subject, _id, Duration, Labels } = row
    return (
      <Tr
        cursor="pointer"
        key={index}
        onClick={() => navigate(`/superpowers/conversation?uid=${_id}`)}
        _hover={{ bg: 'gray.50' }}
      >
        <Td>{DateTime.toLocaleString()}</Td>
        <Td>{Subject}</Td>

        <Td
          title={
            Duration
              ? `Conversation duration: ${formatSeconds(Duration)}`
              : undefined
          }
        >
          {formatSeconds(Duration)}
        </Td>
        <Td>{Labels.join(',')}</Td>
      </Tr>
    )
  }

  if (data.length === 0)
    return (
      <Card w="100%">
        <Text fontSize="lg" textAlign="center">
          {` User doesn't have any conversations`}
        </Text>
      </Card>
    )

  return (
    <Card w="100%">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Queue / campaign</Th>
            <Th>Duration</Th>
            <Th>Labels</Th>
          </Tr>
        </Thead>
        <Tbody>{data.map(renderRow)}</Tbody>
      </Table>
    </Card>
  )
}

export default UserConversations
