import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

type KeyTopicsWordCloudBlacklistResponse = {
  language: string
  stopWords: string[]
  blacklistNgrams: string[]
  updated: Date | null
}

type KeyTopicsWordCloudBlacklistUpdateModel = {
  language: string
  stopWords: string[]
  blacklistNgrams: string[]
}

type AllKeyTopicsWordCloudBlacklistResponse = {
  wordCloudSettings: KeyTopicsWordCloudBlacklistResponse[]
}

export const useKeyTopicsWordCloudBlacklist = (
  language: string,
): UseQueryResult<KeyTopicsWordCloudBlacklistResponse> =>
  useQuery({
    queryKey: ['keyTopicsWordCloudBlacklist', language],
    queryFn: () =>
      request.get<KeyTopicsWordCloudBlacklistResponse>(
        `systemadmin/keytopics/wordCloudSettings/${language}`,
      ),
    refetchOnWindowFocus: false,
  })

export const useAllKeyTopicsWordCloudBlacklist =
  (): UseQueryResult<AllKeyTopicsWordCloudBlacklistResponse> =>
    useQuery({
      queryKey: ['keyTopicsWordCloudBlacklist'],
      queryFn: () =>
        request.get<AllKeyTopicsWordCloudBlacklistResponse>(
          'systemadmin/keytopics/wordCloudSettings',
        ),
      refetchOnWindowFocus: false,
    })

export const useUpdateKeyTopicsWordCloudBlacklist = (): UseMutationResult<
  KeyTopicsWordCloudBlacklistUpdateModel,
  ResponseError,
  Partial<KeyTopicsWordCloudBlacklistUpdateModel>,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (model: Partial<KeyTopicsWordCloudBlacklistUpdateModel>) =>
      request.patch<KeyTopicsWordCloudBlacklistUpdateModel>(
        `systemadmin/keytopics/wordCloudSettings/${model.language}`,
        {
          json: {
            stopWords: model.stopWords,
            blacklistNgrams: model.blacklistNgrams,
          },
        },
      ),
    onSuccess: (newBlacklist, { language }) => {
      queryClient.setQueryData<KeyTopicsWordCloudBlacklistUpdateModel>(
        ['keyTopicsWordCloudBlacklist', language],
        () => {
          return newBlacklist
        },
      )
    },
  })
}
