import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { Input, Text } from '@chakra-ui/react'
import { format } from 'date-fns/fp'
import noop from 'lodash/noop'
import React, { useState } from 'react'
import { useSetMostRecentRecordDate } from '../useOrganizationIntegrations'

type Props = {
  onClose: () => void
  onSubmit: (date: Date) => void
  organizationUid: string
  integrationKey: string
  integrationType: string
  mostRecentRecordDate: Date
  minDate: Date | null
}
const toTime = format("yyyy-MM-dd'T'HH:mm")

const SetMostRecentRecordDateModal: React.FC<Props> = ({
  onClose,
  organizationUid,
  integrationKey,
  integrationType,
  mostRecentRecordDate,
  minDate,
}) => {
  const { mutate, isPending, error } =
    useSetMostRecentRecordDate(organizationUid)
  const [date, setDate] = useState<string>(toTime(mostRecentRecordDate))

  const updateDate = (): void => {
    mutate(
      {
        mostRecentRecordDate: new Date(date),
        integrationType,
        integrationKey,
      },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }

  return (
    <Modal isOpen onClose={isPending ? noop : onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Set the date where the next round should start importing from
        </ModalHeader>
        {!isPending && <ModalCloseButton />}

        <ModalBody>
          <Input
            type="datetime-local"
            onChange={(e) => setDate(e.target.value)}
            value={date}
            isDisabled={isPending}
            min={minDate ? toTime(minDate) : undefined}
          />
          {error && (
            <Text textAlign="center" color="danger">
              {error.message}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} isDisabled={isPending}>
            Cancel
          </Button>
          <Button primary isLoading={isPending} onClick={updateDate} ml="1">
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SetMostRecentRecordDateModal
