import { OrganizationMetadataSettings } from '@capturi/api-organization'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Code,
  Heading,
  Icon,
  IconButton,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react'
import React from 'react'
import { FiCopy } from 'react-icons/fi'
import { useCopyToClipboard } from 'react-use'

import { customNumberPropExamples, customPropExamples } from './example-data'

function CodeContainer({ data }: { data: unknown }): React.ReactElement {
  return (
    <Code p={2}>
      <chakra.pre>{JSON.stringify(data, null, 2)}</chakra.pre>
    </Code>
  )
}

function ExamplesList({
  list,
}: {
  list: OrganizationMetadataSettings[keyof OrganizationMetadataSettings][]
}): React.ReactElement {
  const [, copy] = useCopyToClipboard()
  return (
    <Accordion allowMultiple allowToggle mt={4}>
      {list.map((example) => (
        <AccordionItem key={example.filterType}>
          <AccordionButton>
            <Text flex="1" textAlign="left" fontSize="md">
              {example.filterType}
            </Text>
            <IconButton
              as="div"
              role="button"
              size="xs"
              icon={<Icon as={FiCopy} boxSize={3} />}
              aria-label="Copy"
              onClick={(e) => {
                e.stopPropagation()
                copy(JSON.stringify(example, null, 2))
              }}
              colorScheme="blackAlpha"
              mx={2}
            />
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <CodeContainer data={example} />
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

export function Examples(): React.ReactElement {
  return (
    <Stack spacing={8} mt={2}>
      <Box>
        <Heading>Custom Number Prop Examples</Heading>
        <Box mt={4}>
          <ExamplesList list={customNumberPropExamples} />
        </Box>
      </Box>
      <Box>
        <Heading>Custom Prop Examples</Heading>
        <Box mt={4}>
          <ExamplesList list={customPropExamples} />
        </Box>
      </Box>
    </Stack>
  )
}
