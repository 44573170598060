import { useInput } from '@capturi/react-utils'
import {
  Button,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'
import React from 'react'
import { MdSettings } from 'react-icons/md'
import { useBackSyncHeimdal } from './useHeimdalStatus'

const Settings: React.FC = () => {
  const { mutate, isPending } = useBackSyncHeimdal()

  const [inputs, setInputs] = useInput({ weeksToLookBack: 4 })

  return (
    <Popover closeOnBlur={!isPending} closeOnEsc={!isPending}>
      <PopoverTrigger>
        <IconButton
          variant="ghost"
          icon={<MdSettings />}
          isLoading={isPending}
          aria-label="settings"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton isDisabled={isPending} />
        <PopoverHeader>Settings</PopoverHeader>
        <PopoverBody>
          <Flex>
            <Input
              borderRightRadius={0}
              type="number"
              placeholder="Weeks to back sync"
              isDisabled={isPending}
              {...setInputs.weeksToLookBack}
            />

            <Button
              colorScheme="pink"
              width="100px"
              borderLeftRadius={0}
              height="40px"
              onClick={() => mutate({ weeks: Number(inputs.weeksToLookBack) })}
              isLoading={isPending}
            >
              Back sync
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default Settings
