import { useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { formatDistanceToNow } from 'date-fns'
import React, { ReactElement, useState } from 'react'
import { IoMdShareAlt } from 'react-icons/io'
import { Link, useParams } from 'react-router-dom'

import Loader from '../../../Loader'
import { RefreshButton } from '../../../RefreshButton'
import { ErrorsModal } from './ErrorsModal'

type Response = {
  integrationKey: string
  integrationType: string
  externalUid: string
  created: Date
  started: Date
  attempts: number
  errors: string[]
  succeeded: boolean
  conversationUid: '' | null
}

const Mappings: React.FC = () => {
  const { uid: organizationUid } = useParams()

  const [succeeded, setSucceeded] = useState<string>('')
  const [sort, setSort] = useState<string>('created')
  const { data, refetch, isFetching, isLoading } = useQuery<Response[]>({
    queryKey: [
      `superpowers/info/mappings/${organizationUid}?sort=${sort}&succeeded=${succeeded}`,
    ],
    enabled: !!organizationUid,
  })
  const [open] = useModal(ErrorsModal)
  if (organizationUid == null) return <div>Not found</div>

  const renderRow = (row: Response, index: number): ReactElement => {
    const {
      created,
      started,
      attempts,
      conversationUid,
      errors,
      externalUid,
      integrationType,
    } = row

    return (
      <Tr cursor={conversationUid ? 'pointer' : undefined} key={index}>
        <Td whiteSpace="nowrap">{integrationType}</Td>
        <Td>{externalUid}</Td>
        <Td
          whiteSpace="nowrap"
          title={`First try: ${created.toLocaleString()}`}
        >
          {formatDistanceToNow(started, { addSuffix: true })}
        </Td>
        <Td>{attempts}</Td>
        <Td onClick={() => open({ errors })}>{errors[errors.length - 1]}</Td>
        <Td>
          {!!conversationUid && (
            <Link to={`/superpowers/conversation?&uid=${conversationUid}`}>
              <IoMdShareAlt />
            </Link>
          )}
        </Td>
      </Tr>
    )
  }

  return (
    <Box>
      <Flex justifyContent="space-between" mb="4">
        <Stack direction="row" spacing="4">
          <RadioGroup
            onChange={setSucceeded}
            value={succeeded}
            border="1px solid"
            borderColor="inherit"
            borderRadius="sm"
            height="8"
            display="flex"
          >
            <Flex alignItems="center" bg="gray.100" h="full">
              <Text textAlign="center" paddingInline="2">
                Status
              </Text>
            </Flex>
            <Radio value="" mx="2">
              All
            </Radio>
            <Radio value="true" mx="2">
              Succeeded
            </Radio>
            <Radio value="false" mx="2">
              Non succeeded
            </Radio>
          </RadioGroup>
          <RadioGroup
            onChange={setSort}
            value={sort}
            border="1px solid"
            borderColor="inherit"
            borderRadius="sm"
            height="8"
            display="flex"
          >
            <Flex alignItems="center" bg="gray.100" h="full">
              <Text textAlign="center" paddingInline="2">
                Sort
              </Text>
            </Flex>
            <Radio value="created" mx="2">
              Created
            </Radio>
            <Radio value="lastTry" mx="2">
              Last try
            </Radio>
          </RadioGroup>
        </Stack>

        <RefreshButton
          refresh={refetch}
          isLoading={isFetching}
          position="initial"
        />
      </Flex>
      {isLoading ? (
        <Loader />
      ) : (
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>External Uid</Th>
              <Th whiteSpace="pre">{'Created / \nLast Try'}</Th>
              <Th>Attempts</Th>
              <Th>Errors</Th>
            </Tr>
          </Thead>
          <Tbody>{data?.map(renderRow)}</Tbody>
        </Table>
      )}
    </Box>
  )
}

export default Mappings
