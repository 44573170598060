import { type FC } from 'react'
import type { ConfigField } from '../IntegrationConfigSchemaType'
import { BooleanField } from './BooleanField'
import { ChipsField } from './ChipsField'
import { Multiselect } from './Multiselect'
import { NumberField } from './NumberField'
import { SingleSelect } from './SingleSelect'
import TextField from './TextField'

type Props = {
  field: ConfigField
  value: unknown
  onChange: (value: unknown) => void
  isDisabled?: boolean
}

export const Field: FC<Props> = ({ field, onChange, value, isDisabled }) => {
  switch (field.type) {
    case 'boolean':
      return (
        <BooleanField
          {...field}
          fieldKey={field.key}
          onChange={onChange}
          value={value as boolean}
          isDisabled={isDisabled}
        />
      )
    case 'text':
      return (
        <TextField
          {...field}
          fieldKey={field.key}
          onChange={onChange}
          value={value as string}
          isDisabled={isDisabled}
        />
      )
    case 'number':
      return (
        <NumberField
          {...field}
          fieldKey={field.key}
          onChange={onChange}
          value={value as number}
          isDisabled={isDisabled}
        />
      )
    case 'singleSelect':
      return (
        <SingleSelect
          {...field}
          fieldKey={field.key}
          onChange={onChange}
          value={value as string}
          isDisabled={isDisabled}
        />
      )
    case 'multiselect':
      return (
        <Multiselect
          {...field}
          fieldKey={field.key}
          onChange={onChange}
          value={value as string[]}
          isDisabled={isDisabled}
        />
      )
    case 'chips':
      return (
        <ChipsField
          {...field}
          fieldKey={field.key}
          onChange={onChange}
          value={value as string[]}
          isDisabled={isDisabled}
        />
      )

    default:
      return <div />
  }
}
