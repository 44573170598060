import { useModal } from '@capturi/use-modal'
import { Box, Button, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { AiTwotoneApi } from 'react-icons/ai'
import { useParams, useSearchParams } from 'react-router-dom'

import IntegrationOverview from '../../Integration'
import IntegrationChip from '../../IntegrationChip'
import { useOrganizationExternalApi } from '../../useExternalApi'
import { useActiveOrganizationIntegrations } from '../../useIntegrations'
import AiConfig from './AiConfig'
import CreateApiToken from './CreateApiToken'
import Deletion from './DangerZone'
import ExternalApi from './ExternalApi'
import FeatureFlags from './FeatureFlags'
import SetDeletionStrategy from './SetDeletionStrategy'
import UsersWithAccess from './UsersWithAccess'

const Config: React.FC = () => {
  const { uid } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showCreateApiTokenModal] = useModal(CreateApiToken)
  const [showDeletionStrategyModal] = useModal(SetDeletionStrategy)
  const integrations = useActiveOrganizationIntegrations(uid || '')
  const external = useOrganizationExternalApi(uid || '')

  const isNew = searchParams.get('isNew') === 'true'

  useEffect(() => {
    if (isNew && uid) {
      setSearchParams((s) => {
        s.delete('isNew')
        return s
      })
      showDeletionStrategyModal({ organizationUid: uid, isNew: isNew })
    }
  }, [isNew, setSearchParams, showDeletionStrategyModal, uid])

  if (uid == null) return <div>Not found</div>

  return (
    <div>
      <Flex justifyContent="space-between">
        <Flex>
          {integrations.data?.map(({ integrationKey, integrationType }) => (
            <IntegrationChip
              key={integrationKey}
              integrationKey={integrationKey}
              integrationType={integrationType}
            />
          ))}
          {external.data?.map(({ TokenID }) => (
            <IntegrationChip
              key={TokenID}
              integrationKey={TokenID}
              integrationType="External"
            />
          ))}
        </Flex>
        <Flex>
          <Button
            leftIcon={<AiTwotoneApi />}
            size="sm"
            onClick={() => showCreateApiTokenModal({ organizationUid: uid })}
            mx="2"
          >
            Create API Token
          </Button>
          <Button
            onClick={() =>
              showDeletionStrategyModal({ organizationUid: uid, isNew: false })
            }
          >
            Set deletion strategy
          </Button>
        </Flex>
      </Flex>
      <Box mx="4" mb="8">
        <Box>
          <UsersWithAccess organizationUid={uid} />
        </Box>
        <Box>
          <AiConfig organizationUid={uid} />
        </Box>
        <Box>
          <FeatureFlags organizationUid={uid} />
        </Box>
        <Box mt="8">
          <IntegrationOverview organizationUid={uid} />
        </Box>
        <Box mt="8">
          <ExternalApi organizationUid={uid} />
        </Box>
        <Box mt="8">
          <Deletion organizationUid={uid} />
        </Box>
      </Box>
    </div>
  )
}

export default Config
