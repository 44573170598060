import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps, useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import React, { ReactElement, useState } from 'react'
import { useThrottle } from 'react-use'

import { RefreshButton } from '../../RefreshButton'

type Response = {
  integrationKey: string
  integrationType: string
  externalUid: string
  created: Date
  started: Date
  attempts: number
  errors: string[]
  conversationUid: string | null
}

const ImportErrors: React.FC = () => {
  const [hoursToLookBack, setHoursToLookBack] = useState<number>(24)
  const [minAttempts, setMinAttempts] = useState<number>(3)

  const throttledMinAttempts = useThrottle(minAttempts, 1000)
  const throttledHoursToLookBack = useThrottle(hoursToLookBack, 1000)
  const { data, refetch, isFetching } = useQuery<Response[]>({
    queryKey: [
      `superpowers/info/errors?hoursToLookBack=${throttledHoursToLookBack}&attempts=${throttledMinAttempts}`,
    ],
    placeholderData: keepPreviousData,
  })
  const [open] = useModal(ErrorsModal)

  const renderRow = (row: Response, index: number): ReactElement => {
    const {
      attempts,
      created,
      errors,
      externalUid,
      integrationKey,
      integrationType,
      started,
    } = row

    return (
      <Tr key={index}>
        <Td whiteSpace="nowrap" title={`Type: ${integrationType}`}>
          {integrationKey}
        </Td>
        <Td whiteSpace="nowrap">{externalUid}</Td>
        <Td isNumeric>{attempts}</Td>
        <Td cursor="pointer" onClick={() => open({ errors })}>
          {errors[errors.length - 1]}
        </Td>
        <Td>{created.toLocaleString()}</Td>
        <Td>{started.toLocaleString()}</Td>
      </Tr>
    )
  }

  return (
    <Box>
      <Flex justifyContent="flex-end" my="4">
        <InputGroup size="xs" width="auto">
          <InputLeftAddon>Minimum number of attempts</InputLeftAddon>
          <NumberInput
            title="Minimum number of attempts"
            width="16"
            min={0}
            mr="4"
            onChange={(_, e) => {
              setMinAttempts(e)
            }}
            value={minAttempts}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
        <InputGroup size="xs" width="auto">
          <InputLeftAddon>Hours to look back</InputLeftAddon>
          <NumberInput
            title="Hours to look back"
            width="16"
            min={1}
            // mr="4"
            onChange={(_, e) => {
              setHoursToLookBack(e)
            }}
            value={hoursToLookBack}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
        <RefreshButton
          refresh={refetch}
          isLoading={isFetching}
          position="initial"
        />
      </Flex>

      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Integration Key</Th>
            <Th>External Uid</Th>
            <Th isNumeric>Attempts</Th>
            <Th>Errors</Th>
            <Th>Created</Th>
            <Th>Last try</Th>
          </Tr>
        </Thead>
        <Tbody>{data?.map(renderRow)}</Tbody>
      </Table>
    </Box>
  )
}

export default ImportErrors

type ErrorsModalProps = { errors: string[] } & BaseModalComponentProps
const ErrorsModal: React.FC<ErrorsModalProps> = ({ onClose, errors }) => {
  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{'Update'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errors.map((e, index) => (
            <Text
              py="2"
              borderBottom="1px solid"
              borderColor="gray.200"
              key={index}
            >
              {e}
            </Text>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
