import { useScrollable } from '@capturi/react-utils'
import { useModal } from '@capturi/use-modal'
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { Title } from '../utils'
import CreateUpdateOrganization from './CreateUpdateOrganization'
import IntegrationChip from './IntegrationChip'
import { useFuzzySearch } from './useFuzzySearch'
import { useOrganizationsWithIntegrations } from './useOrganizationsWithIntegrations'

const OrganizationOverview: React.FC = () => {
  const { uid } = useParams()

  const navigate = useNavigate()
  const listRef = useRef<HTMLDivElement | null>(null)
  const orgs = useOrganizationsWithIntegrations()
  const [showModal] = useModal(CreateUpdateOrganization)
  const [term, setTerm] = useState<string>('')

  const organizations = useFuzzySearch(orgs, 'searchString', term)

  const handleCreate = (): void => {
    showModal()
  }

  useEffect(() => {
    if (
      orgs &&
      orgs.length > 1 &&
      organizations.length === 1 &&
      organizations[0].uid !== uid
    ) {
      navigate(`/superpowers/organization/${organizations[0].uid}`)
    }
  }, [organizations, navigate, orgs, uid])

  const sidebarHeight = useScrollable(listRef)

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb="8">
        <Title>Organizations</Title>
        <Button size="sm" colorScheme="green" onClick={handleCreate}>
          Create New Organization
        </Button>
      </Flex>
      <Flex>
        <div>
          <Input
            onFocus={(e) => e.target.select()}
            onChange={(e) => setTerm(e.target.value)}
            value={term}
            placeholder="Search"
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                navigate(`/superpowers/organization/${organizations[0].uid}`)
              }
            }}
          />
          <Box
            ref={listRef}
            height={`${sidebarHeight}px`}
            overflowY="auto"
            width="250px"
          >
            <Box>
              {organizations?.map((org) => (
                <Box
                  borderBottom="#eeeeee 1px solid"
                  borderRadius="5px"
                  backgroundColor={org.uid === uid ? '#25748b' : undefined}
                  color={org.uid === uid ? 'white' : undefined}
                  key={org.uid}
                  cursor="pointer"
                  px="4"
                  py="2"
                  onClick={() =>
                    navigate(`/superpowers/organization/${org.uid}`)
                  }
                  _hover={{
                    backgroundColor: org.uid === uid ? '#25748b' : '#D5EAF6',
                  }}
                >
                  <Text>{org.name}</Text>
                  <Flex mt="2" overflowX="auto" overflowY="hidden">
                    {org.integrations
                      ?.filter((i) => i.active)
                      .map((i) => (
                        <IntegrationChip
                          key={i.integrationType + i.integrationKey}
                          integrationKey={i.integrationKey}
                          integrationType={i.integrationType}
                        />
                      ))}
                  </Flex>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
        <Flex flexGrow={2} ml="8" mt="8">
          <Outlet />
        </Flex>
      </Flex>
    </>
  )
}

export default OrganizationOverview
