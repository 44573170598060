import request from '@capturi/request'
import { Button, Emoji } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import noop from 'lodash/noop'
import React, { useState } from 'react'

type Props = BaseModalComponentProps & {
  organizationUid: string
  labelsToKeep: string[]
  allLabels: string[]
}

const ConfirmKeepLabelsModal: React.FC<Props> = ({
  onClose,
  onSubmit,
  labelsToKeep,
  allLabels,
  organizationUid,
}) => {
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<null | Error>(null)

  const handleDelete = async (): Promise<void> => {
    try {
      setInProgress(true)
      await request.post(`systemadmin/keep-labels/${organizationUid}`, {
        json: {
          labelsToKeep: labelsToKeep,
        },
      })
      setInProgress(false)
      onSubmit?.(undefined)
    } catch (error) {
      setInProgress(false)
      if (error instanceof Error) {
        setError(error)
      } else {
        setError(new Error('Unknown error'))
      }
    }
  }

  const sortedLabels = labelsToKeep.sort(Intl.Collator().compare)

  return (
    <Modal isOpen={true} onClose={inProgress ? noop : onClose}>
      <ModalOverlay>
        <ModalContent>
          <Flex direction="column">
            <ModalHeader>
              <Emoji symbol="⚠️" mr={1} />
              Remove {allLabels.length - labelsToKeep.length} labels?
            </ModalHeader>
          </Flex>
          <ModalCloseButton isDisabled={inProgress} />
          <ModalBody>
            <Text>
              This will keep these labels, and remove{' '}
              {allLabels.length - labelsToKeep.length} labels
            </Text>
            <UnorderedList>
              {sortedLabels.map((label) => (
                <ListItem key={label}>{label}</ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
          {error && <Text color="red">{error.message}</Text>}
          <ModalFooter justifyContent="flex-end">
            <Button secondary onClick={onClose} isDisabled={inProgress}>
              Cancel
            </Button>
            <Button
              ml="2"
              colorScheme="green"
              onClick={handleDelete}
              isLoading={inProgress}
            >
              Keep {labelsToKeep.length} labels
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default ConfirmKeepLabelsModal
