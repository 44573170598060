import ufuzzy from '@leeoniya/ufuzzy'
import { useMemo } from 'react'

const uf = new ufuzzy({ intraMode: 1, intraIns: 0, alpha: 'a-zæøåöä' })

export const useFuzzySearch = <T extends {}>(
  data: T[],
  key: keyof T,
  searchTerm: string,
): T[] => {
  const keys = useMemo(() => data.map((o) => o[key]), [data, key]) as string[]

  if (searchTerm === '') return data

  const idxs = uf.filter(keys, searchTerm)
  //IF there isn't any matches
  if (idxs === null || idxs.length === 0) return []

  const info = uf.info(idxs, keys, searchTerm)

  // order is a double-indirection array (a re-order of the passed-in idxs)
  // this allows corresponding info to be grabbed directly by idx, if needed
  const order = uf.sort(info, keys, searchTerm)

  // using info.idx here instead of idxs because uf.info() may have
  // further reduced the initial idxs based on prefix/suffix rules
  return order.map((o) => data[info.idx[order[o]]])
}
