import { useMemo } from 'react'

import { Organization } from './types'
import { useExternalApi } from './useExternalApi'
import { useIntegrations } from './useIntegrations'
import { useOrganizations } from './useOrganizations'

export type Integration = {
  organizationUid: string
  integrationKey: string
  active: boolean
  integrationType: string
}

export type OrganizationWithIntegration = Organization & {
  integrations: Integration[] | undefined
  searchString: string
}

export const useOrganizationsWithIntegrations =
  (): OrganizationWithIntegration[] => {
    const { data } = useOrganizations()
    const { data: external } = useExternalApi()
    const { data: integrations } = useIntegrations()
    const orgs = useMemo(() => {
      if (!data) return []

      const integrationMap = (integrations || []).reduce((memo, i) => {
        const current = memo.get(i.organizationUid) || []
        current.push(i)
        memo.set(i.organizationUid, current)

        return memo
      }, new Map<string, Integration[]>())

      external?.forEach((i) => {
        const current = integrationMap.get(i.OrganizationUID) || []
        current.push({
          active: true,
          integrationKey: i.TokenID,
          integrationType: 'External',
          organizationUid: i.OrganizationUID,
        })
        integrationMap.set(i.OrganizationUID, current)
      })

      const orgs = data.map((o) => {
        const int = integrationMap.get(o.uid) || []
        const searchString = `${o.name}|${o.uid}|${int.reduce(
          (memo, i) => `${memo}|${i.integrationType}`,
          '',
        )}`.toLocaleLowerCase()

        return { ...o, integrations: int, searchString }
      })

      return orgs
    }, [data, integrations, external])

    return orgs
  }
