import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { MdSettings } from 'react-icons/md'

import {
  useSystemSettings,
  useUpdateSystemSettings,
} from '../../../../useSystemSettings'
import { useSummaryPrompts } from '../../FeatureFlags/api/useSummaryPrompts'
import CustomSummaryPromptsModal from './CustomSummaryPromptsModal'

type Props = { organizationUid: string; isEnabled: boolean }
const DEFAULT_MAX_SUMMARY_WORDS = 150
const CustomSummaryPrompts: React.FC<Props> = ({
  organizationUid,
  isEnabled,
}) => {
  const { data: summaries = [] } = useSummaryPrompts()
  const [selectedPrompt, setSelectedPrompt] = useState<
    'custom' | string | undefined
  >(undefined)
  const [customPrompt, setCustomPrompt] = useState<string>('')
  const [maxSummaryWords, setMaxSummaryWords] = useState<number>(
    DEFAULT_MAX_SUMMARY_WORDS,
  )

  const [openCustomPromptEditor] = useModal(CustomSummaryPromptsModal)
  const { data: systemSettings } = useSystemSettings(organizationUid)
  const { mutate: handleUpdate, isPending } =
    useUpdateSystemSettings(organizationUid)
  const toast = useToast()

  const handleChange = (value: string): void => {
    setSelectedPrompt(value)
    if (value !== 'custom') {
      setCustomPrompt('')
      if (systemSettings?.ai)
        handleUpdate(
          {
            ai: {
              ...systemSettings.ai,
              summaryPrompt: null,
              summaryPromptUid: value,
            },
          },
          {
            onSuccess: () => {
              toast({ status: 'success', title: 'Updated prompt' })
            },
            onError: (err) => {
              toast({
                status: 'error',
                title: 'Could not update prompt',
                description: err.message,
              })
            },
          },
        )
    }
  }

  const handleSaveCustom = (): void => {
    if (systemSettings?.ai)
      handleUpdate(
        {
          ai: {
            ...systemSettings.ai,
            summaryPrompt: customPrompt,
            summaryPromptUid: null,
          },
        },
        {
          onSuccess: () => {
            toast({ status: 'success', title: 'Updated Custom Prompt' })
          },
          onError: (err) => {
            toast({
              status: 'error',
              title: 'Could not update Custom Prompt',
              description: err.message,
            })
          },
        },
      )
  }

  const handleSaveMaxSummaryWords = (): void => {
    if (systemSettings?.ai)
      handleUpdate(
        {
          ai: {
            ...systemSettings?.ai,
            maxSummaryWords: maxSummaryWords,
          },
        },
        {
          onSuccess: () => {
            toast({ status: 'success', title: 'Updated Max Summary Words' })
          },
          onError: (err) => {
            toast({
              status: 'error',
              title: 'Could not Updated Max Summary Words',
              description: err.message,
            })
          },
        },
      )
  }

  useEffect(() => {
    if (systemSettings?.ai?.maxSummaryWords) {
      setMaxSummaryWords(systemSettings?.ai?.maxSummaryWords)
    }
    if (systemSettings?.ai?.summaryPromptUid) {
      setSelectedPrompt(systemSettings?.ai?.summaryPromptUid)
    }
    if (systemSettings?.ai?.summaryPrompt) {
      setSelectedPrompt('custom')
      setCustomPrompt(systemSettings?.ai?.summaryPrompt)
    }
  }, [systemSettings?.ai])

  return (
    <Box width="100%">
      <Flex>
        <Flex flexDir="column">
          <Text>AI summary prompt</Text>
          <HStack mb={4}>
            <Select
              value={selectedPrompt}
              onChange={(e) => handleChange(e.currentTarget.value)}
              size="sm"
              isDisabled={isPending || !isEnabled}
              placeholder="Select option"
            >
              {summaries.map((d) => (
                <option key={d.uid} value={d.uid}>
                  {d.name}
                </option>
              ))}
              <option value={'custom'}>Custom</option>
            </Select>
            <Tooltip label="Global summary prompts settings">
              <IconButton
                maxW="50px"
                variant="ghost"
                aria-label="Default custom prompts settings"
                onClick={openCustomPromptEditor}
                icon={<MdSettings />}
              />
            </Tooltip>
          </HStack>

          <Flex whiteSpace="nowrap" alignItems="center">
            Max Summary Words
          </Flex>
          <Flex>
            <Tooltip label="Max Summary Words, 0 for unlimited">
              <NumberInput
                size="sm"
                title="maxSummaryWords"
                isDisabled={isPending || !isEnabled}
                onBlur={handleSaveMaxSummaryWords}
                onChange={(e) => {
                  setMaxSummaryWords(Number(e))
                }}
                value={maxSummaryWords}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Box>
        {selectedPrompt === 'custom' && (
          <Box ml="26px" mt="2">
            <Textarea
              id="prompt"
              placeholder="prompt"
              onChange={(e) => setCustomPrompt(e.currentTarget.value)}
              value={customPrompt}
              isDisabled={isPending}
            />
            <Flex justifyContent="flex-end" mt="2">
              <Button
                primary
                onClick={handleSaveCustom}
                isLoading={isPending}
                isDisabled={!(customPrompt && isEnabled)}
              >
                Save
              </Button>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CustomSummaryPrompts
