import { Text } from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import CallsInRawDataModal from '../../Organization/Integration/puzzel/CallsInRawDataModal'
import { Card, SmartRow, TableCard } from '../components'
import type { ConversationMapping as Data } from '../types'

type Props = { data: Data }

const ConversationMapping: React.FC<Props> = ({ data }) => {
  const { errors, data: extraInfo, ...rest } = data
  const [openCallsInRawDataModal] = useModal(CallsInRawDataModal)

  const showRawDataButton =
    data.integrationType === 'puzzel-v2' &&
    typeof data.data.InternalIqSessionId === 'string'

  return (
    <>
      <TableCard title="Import info">
        {Object.entries(rest).map(([key, val]) => SmartRow(key, val))}
      </TableCard>
      {!isEmpty(extraInfo) && (
        <TableCard
          title="Import data"
          button={
            showRawDataButton ? (
              <Button
                primary
                variant="outline"
                onClick={() =>
                  openCallsInRawDataModal({
                    integrationKey: data.integrationKey,
                    sessionUid: data.data.InternalIqSessionId as string,
                  })
                }
              >
                Show raw data
              </Button>
            ) : undefined
          }
        >
          {Object.entries(extraInfo).map(([key, val]) => SmartRow(key, val))}
        </TableCard>
      )}
      {errors && !!errors.length && (
        <Card title="Errors">
          {errors.map((e, index) => (
            <Text key={index}>{e}</Text>
          ))}
        </Card>
      )}
    </>
  )
}

export default ConversationMapping
