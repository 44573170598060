import request, { ResponseError } from '@capturi/request'
import { Integration } from '../types'

import {
  type UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

export const useOrganizationIntegrations = (
  organizationUid: string,
): UseQueryResult<Integration[], ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'integrations', organizationUid],
    queryFn: () =>
      request.get<Integration[]>(`superpowers/integration/${organizationUid}`),
  })

export const useToggleIntegration = (
  organizationUid: string,
): UseMutationResult<
  void,
  ResponseError,
  { integrationKey: string; integrationType: string; active: boolean },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ integrationKey, integrationType, active }) => {
      queryClient.setQueryData<Integration[]>(
        ['superpowers', 'integrations', organizationUid],
        (old) =>
          (old || []).map((i) => {
            if (
              i.integrationKey === integrationKey &&
              i.integrationType === integrationType
            ) {
              return { ...i, active }
            }
            return i
          }),
      )

      await request.put(`superpowers/integration/${organizationUid}/status`, {
        json: {
          integrationKey,
          integrationType,
          active,
        },
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'integrations'],
      })
    },
  })
}
export const useResetEndDate = (
  organizationUid: string,
): UseMutationResult<
  void,
  ResponseError,
  { integrationKey: string; integrationType: string; lastAttemptEnd: Date },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ integrationKey, integrationType, lastAttemptEnd }) => {
      queryClient.setQueryData<Integration[]>(
        ['superpowers', 'integrations', organizationUid],
        (old) =>
          (old || []).map((i) => {
            if (
              i.integrationKey === integrationKey &&
              i.integrationType === integrationType
            ) {
              return { ...i, lastAttemptEnd }
            }
            return i
          }),
      )

      await request.post<{ lastAttemptEnd: Date }>(
        `superpowers/integration/${organizationUid}/reset-last-attempt-end`,
        {
          json: {
            lastAttemptEnd: new Date(),
            integrationType,
            integrationKey,
          },
        },
      )
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'integrations'],
      })
    },
  })
}
export const useSetMostRecentRecordDate = (
  organizationUid: string,
): UseMutationResult<
  void,
  ResponseError,
  {
    integrationKey: string
    integrationType: string
    mostRecentRecordDate: Date
  },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      integrationKey,
      integrationType,
      mostRecentRecordDate,
    }) => {
      queryClient.setQueryData<Integration[]>(
        ['superpowers', 'integrations', organizationUid],
        (old) =>
          (old || []).map((i) => {
            if (
              i.integrationKey === integrationKey &&
              i.integrationType === integrationType
            ) {
              return { ...i, mostRecentRecordDate }
            }
            return i
          }),
      )

      await request.put<{ mostRecentRecordDate: Date }>(
        `superpowers/integration/${organizationUid}/most-recent-record-date`,
        {
          json: {
            mostRecentRecordDate: mostRecentRecordDate,
            integrationType,
            integrationKey,
          },
        },
      )
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'integrations'],
      })
    },
  })
}
export const useResetFilesOnSftp = (
  organizationUid: string,
): UseMutationResult<
  { mostRecentRecordDate: Date },
  ResponseError,
  {
    integrationKey: string
    integrationType: string
  },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ integrationKey, integrationType }) => {
      return await request.put<{ mostRecentRecordDate: Date }>(
        `superpowers/integration/${organizationUid}/reset-files-on-ftp`,
        {
          json: {
            integrationType,
            integrationKey,
          },
        },
      )
    },
    onSuccess: (
      { mostRecentRecordDate },
      { integrationKey, integrationType },
    ) => {
      queryClient.setQueryData<Integration[]>(
        ['superpowers', 'integrations', organizationUid],
        (old) =>
          (old || []).map((i) => {
            if (
              i.integrationKey === integrationKey &&
              i.integrationType === integrationType
            ) {
              return { ...i, mostRecentRecordDate }
            }
            return i
          }),
      )
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'integrations'],
      })
    },
  })
}
