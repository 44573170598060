import { ResponseError } from '@capturi/request'
import { Flex, Text } from '@chakra-ui/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import Loader from '../../Loader'
import { RefreshButton } from '../../RefreshButton'
import UserCard from '../UserCard'
import {
  SpeakerModelStatusResponse,
  UserConversation as UserConversationResponse,
  User as UserResponse,
} from '../types'
import SpeakerModelCard from './SpeakerModelCard'
import UserConversations from './UserConversations'

type Props = { userUid: string }

const Info: React.FC<Props> = ({ userUid }) => {
  const [error, setError] = useState<string>('')
  const queryClient = useQueryClient()

  const user = useQuery<UserResponse>({
    queryKey: [`superpowers/info/user/${userUid}`],
  })
  const userConversations = useQuery<UserConversationResponse[]>({
    queryKey: [
      `superpowers/info/conversations/${user.data?.organizationId}/${userUid}`,
    ],
    enabled: user.isSuccess,
  })
  const speakerModelUrl = `superpowers/asr/speaker-model/${userUid}`
  let refetchInterval: number | boolean = false
  const speakerModel = useQuery<SpeakerModelStatusResponse>({
    queryKey: [speakerModelUrl],
    enabled: userConversations.isSuccess && userConversations.data.length > 0,
    refetchInterval: refetchInterval,
  })
  refetchInterval = speakerModel.data?.status === 'Processing' ? 1000 : false

  const updateSpeakerModelCache = (): void => {
    queryClient.setQueryData<SpeakerModelStatusResponse>([speakerModelUrl], {
      status: 'Processing',
    })
    queryClient.invalidateQueries({
      queryKey: [speakerModelUrl],
    })
  }

  useEffect(() => {
    ;(() => {
      if (user.error && user.error instanceof ResponseError) {
        const error = user.error
        setError(error.message)
      } else if (
        userConversations.error &&
        userConversations.error instanceof ResponseError
      ) {
        const error = userConversations.error
        setError(error.message)
      }
    })()
  }, [user.error, userConversations.error])

  const refetch = (): void => {
    user.refetch()
    speakerModel.refetch()
    userConversations.refetch()
  }

  return (
    <div>
      <RefreshButton refresh={refetch} isLoading={user.isFetching} />

      <Flex flexWrap="wrap">
        {user.isSuccess && <UserCard data={user.data} />}
        {user.isSuccess && speakerModel.isSuccess && (
          <SpeakerModelCard
            organizationUid={user.data.organizationId}
            status={speakerModel.data.status}
            name={user.data.name}
            uid={user.data.id}
            updateCache={updateSpeakerModelCache}
          />
        )}
        {userConversations.isSuccess && (
          <UserConversations data={userConversations.data} />
        )}
      </Flex>
      {error && (
        <Text w="100%" textAlign="center" my="4" color="danger" fontSize="lg">
          {error}
        </Text>
      )}

      {user.isLoading && <Loader />}
    </div>
  )
}

export default Info
