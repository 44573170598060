import { useToast } from '@capturi/ui-components'
import { OnChangeValue, Select, SelectOption } from '@capturi/ui-select'
import { Flex, FormControl, FormLabel, IconButton } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { MdCopyAll } from 'react-icons/md'

type Option = { value: string; label: string }
type Props = {
  options: Option[]
  label: string
  fieldKey: string
  value: string
  onChange: (values: string) => void
  isDisabled?: boolean
}

export const SingleSelect: React.FC<Props> = ({
  options,
  fieldKey,
  label,
  onChange,
  value,
  isDisabled = false,
}) => {
  const toast = useToast()

  const handleChange = (value: OnChangeValue<SelectOption, false>): void => {
    if (value == null) {
      onChange('')
    } else {
      onChange(value.value)
    }
  }
  const handleCopy = (): void => {
    navigator.clipboard.writeText(options.map((f) => f.label).join('\n'))
    toast({ status: 'info', title: 'Copied list to clipboard' })
  }

  const initialValue = useMemo<Option>(
    () => options.find((o) => o.value === value) || { label: value, value },
    [options, value],
  )

  return (
    <FormControl key={fieldKey}>
      <Flex justifyContent="space-between">
        <FormLabel>{label}</FormLabel>
        <IconButton
          variant="ghost"
          icon={<MdCopyAll />}
          onClick={handleCopy}
          aria-label="copy list content"
          title="copy list content"
        />
      </Flex>
      <Select
        // isOptionDisabled={() => initialCostumFields.length >= 10}
        isDisabled={isDisabled}
        defaultValue={initialValue}
        options={options}
        placeholder={label}
        onChange={handleChange}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        hideSelectedOptions={false}
        backspaceRemovesValue
        isClearable={false}
        autoFocus={false}
        minMenuHeight={100}
        maxMenuHeight={612}
      />
    </FormControl>
  )
}
