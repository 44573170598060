import { Box, Flex, Select, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'

import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import { Button } from '@capturi/ui-components'
import { useMutation } from '@tanstack/react-query'

const guidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}/

const resultRegex = /Conversations:\\n\s*(?<conversations>\d*)/gm

const ReprocessConversations: React.FC = () => {
  const { mutate, isPending, error, reset } = useMutation<
    { result: string },
    ResponseError,
    { uids: string[]; priority: string }
  >({
    mutationFn: ({ uids, priority }) =>
      request.post(
        `superpowers/asr/reprocess-conversations/by-uids?priority=${priority}`,
        {
          json: { uids: uids },
          timeout: false,
        },
      ),
  })

  const toast = useToast()
  const [validUids, setValidUids] = useState<string[]>([])
  const [invalidUids, setInvalidUids] = useState<string[]>([])
  const [priority, setPriority] = useState<string>('low')
  const handlePaste = async (): Promise<void> => {
    reset()
    const text = await navigator.clipboard.readText()
    const wordArray = text.toLowerCase().split(/,|\s/).filter(Boolean)

    const { invalidUids, validUids } = wordArray.reduce<{
      validUids: Set<string>
      invalidUids: Set<string>
    }>(
      (memo, val) => {
        if (guidRegex.test(val)) {
          memo.validUids.add(val)
        } else {
          memo.invalidUids.add(val)
        }

        return memo
      },
      { validUids: new Set(), invalidUids: new Set() },
    )
    setValidUids([...validUids])
    setInvalidUids([...invalidUids])
  }

  const onReset = () => {
    reset()
    setInvalidUids([])
    setValidUids([])
  }

  const onSubmit = (): void => {
    mutate(
      { uids: validUids, priority },
      {
        onSuccess: (result) => {
          const conversationsReprocessed = resultRegex.exec(result.result)
            ?.groups?.conversation

          onReset()
          toast({
            status: 'success',
            title: 'Reprocessing conversations',
            description: conversationsReprocessed
              ? `Reprocessing ${conversationsReprocessed} conversation`
              : result.result,
          })
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: error.name,
            description: error.message,
          })
        },
      },
    )
  }
  return (
    <Box>
      <Flex mb="4" justifyContent="space-between">
        <div>
          <Flex>
            <div>
              <Button
                isDisabled={isPending}
                onClick={() => handlePaste()}
                primary={validUids.length === 0}
              >
                Get uids from clipboard
              </Button>
            </div>
            <Select
              mx="4"
              size="sm"
              id="asrPriority"
              onChange={(e) => setPriority(e.currentTarget.value)}
              value={priority}
              isDisabled={isPending}
            >
              <option value="Low">Low Priority</option>
              <option value="Default">Medium Priority</option>
              <option value="High">High Priority</option>
            </Select>
            <div>
              <Button
                primary={validUids.length > 0}
                isDisabled={validUids.length === 0}
                isLoading={isPending}
                onClick={onSubmit}
              >
                Reprocess {validUids.length} Conversations
              </Button>
            </div>
          </Flex>
          {error && (
            <Text mt="2" color="red.600">
              {error.message}
            </Text>
          )}
        </div>
        <Button isDisabled={isPending} onClick={() => onReset()}>
          Reset
        </Button>
      </Flex>

      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize="xl">Valid uids: {validUids.length}</Text>
          {validUids.map((uid) => (
            <Text key={uid} isTruncated>
              {uid}
            </Text>
          ))}
        </Box>
        <Box>
          <Text fontSize="xl">Invalid uids</Text>
          {invalidUids.map((uid) => (
            <Text key={uid} isTruncated>
              {uid}
            </Text>
          ))}
        </Box>
      </Flex>
    </Box>
  )
}

export default ReprocessConversations
