import { DEFAULT_FLAGS } from '@capturi/feature-flags'
import { List, ListItem, useToast } from '@capturi/ui-components'
import {
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react'
import pick from 'lodash/pick'
import React, { ReactElement, useMemo } from 'react'

import { Title } from '../../../../utils'
import { useFeatureFlags, useUpdateFeatureFlag } from './api/useFeatureFlags'

type Props = { organizationUid: string }
type Flags = { [key: string]: boolean }
type Flag = { key: string; value: boolean }

const convertToArray = (flags: Flags): Flag[] =>
  Object.entries(flags).map(([key, value]) => ({ key, value }))

const FeatureFlags: React.FC<Props> = ({ organizationUid }) => {
  const toast = useToast()

  const { data } = useFeatureFlags(organizationUid)
  const { mutate: updateFeatureFlag, isPending } =
    useUpdateFeatureFlag(organizationUid)
  const flags = useMemo(() => {
    if (data == null) return convertToArray(DEFAULT_FLAGS)
    const mergedFlags = {
      ...DEFAULT_FLAGS,
      ...data,
    }
    // Omit flags that are no longer in use but still returned by the api
    const flags = pick(mergedFlags, Object.keys(DEFAULT_FLAGS))
    return convertToArray(flags)
  }, [data])

  const handleToggle = (key: string, value: boolean): void => {
    updateFeatureFlag(
      { key: key, value: value },
      {
        onError: (error) => {
          toast({ status: 'error', title: error.message })
        },
      },
    )
  }

  const renderRow = ({ key, value }: Flag): ReactElement => {
    return (
      <ListItem key={key} hasDivider>
        <FormControl display="flex" alignItems="center">
          <Switch
            size="sm"
            color="primary"
            id={key}
            isChecked={value}
            onChange={() => handleToggle(key, !value)}
            mr={2}
            isDisabled={isPending}
          />
          <FormLabel htmlFor={key} fontWeight="normal" m={0}>
            {key}
          </FormLabel>
        </FormControl>
      </ListItem>
    )
  }

  return (
    <Card>
      <CardBody>
        <Title>Feature Flags </Title>
        <List>{flags.map(renderRow)}</List>
      </CardBody>
    </Card>
  )
}

export default FeatureFlags
