import request, { ResponseError } from '@capturi/request'

import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
type Flags = Record<string, boolean>

export const useFeatureFlags = (
  organizationUid: string,
): UseQueryResult<Flags, ResponseError> =>
  useQuery({
    queryKey: ['feature-flags', organizationUid],
    queryFn: async () => {
      const result = await request.get<Flags>(
        `superpowers/organization/${organizationUid}/features`,
      )
      return result
    },
  })

export const useUpdateFeatureFlag = (
  organizationUid: string,
): UseMutationResult<
  Flags,
  ResponseError,
  { key: string; value: boolean },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (obj: { key: string; value: boolean }) => {
      return request.patch<Flags>(
        `superpowers/organization/${organizationUid}/features`,
        { json: { [obj.key]: obj.value } },
      )
    },
    onSuccess: (flags) => {
      queryClient.setQueryData<Flags>(
        ['feature-flags', organizationUid],
        (oldFlags = {}) => {
          return { ...oldFlags, ...flags }
        },
      )
    },
  })
}
