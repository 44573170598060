import analytics from '@capturi/analytics'
import {
  Box,
  Checkbox,
  FormLabel,
  Input,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useStandardReportToggle } from '../api/StandardReports/useStandardReports'

type Props = {
  organizationUid: string
  title: string
  metabaseId?: string
  enabled?: boolean
  description: string
  reportUid: string
}

const StandardReport: React.FC<Props> = ({
  organizationUid,
  title,
  metabaseId,
  enabled,
  description,
  reportUid,
}) => {
  const toast = useToast()

  const { mutate: toggleStandardReport } = useStandardReportToggle(
    reportUid,
    organizationUid,
  )

  const handleToggleReport = (
    reportUid: string,
    organizationUid: string,
    isChecked: HTMLInputElement['checked'],
  ): void => {
    toggleStandardReport(
      { reportUid, organizationUid, isChecked },
      {
        onSuccess: () => {
          toast({
            title: `Successfully ${
              isChecked ? 'enabled' : 'disabled'
            } standard report`,
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: 'An error occurred',
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  useEffect(() => {
    analytics.event('reports_current_for_org', {
      metabaseId,
      reportUid,
      title,
    })
  }, [reportUid, metabaseId, title])

  return (
    <SimpleGrid
      columns={1}
      spacing={3}
      border="1px solid"
      borderColor="gray.300"
      borderRadius="6px"
      p={4}
    >
      <Box alignItems="flex-start">
        <FormLabel>Title</FormLabel>
        <Input value={title} isDisabled={true} placeholder="Title" />
      </Box>
      <Box alignItems="flex-start">
        <FormLabel>Description</FormLabel>
        <Input
          value={description}
          isDisabled={true}
          placeholder="Description"
        />
      </Box>
      <Box alignItems="flex-start">
        <Checkbox
          isChecked={enabled}
          onChange={(e) =>
            handleToggleReport(reportUid, organizationUid, e.target.checked)
          }
        >
          Enable standard report
        </Checkbox>
      </Box>
    </SimpleGrid>
  )
}

export default StandardReport
