/** 
On the frontend we have an extra role "owner", that doesn't exists on the backend
so before we patch the user on the backend we have to convert the role to a valid server role.
A owner role is just a administrator with the organization permission
*/
export const toServerRole = (
  role: UiRole,
): { role: Role; permissions: Partial<UserPermissions> } => {
  if (role === 'owner')
    return {
      role: 'administrator',
      permissions: defaultPermissionsPerRole.owner,
    }

  return { role: role, permissions: defaultPermissionsPerRole[role] }
}

/**
    On the frontend we have an extra role "owner", that doesn't exists on the backend.
    A owner role is just a administrator with the organization permission
    */
export const toUiRole = (
  role: Role,
  permissions: Partial<UserPermissions>,
): UiRole => {
  if (role === 'administrator' && permissions.organization) {
    return 'owner'
  }
  return role
}

export const defaultUserPermissions: UserPermissions = {
  organization: false,
  editDashboard: false,
  editTracker: false,
  editSegment: false,
  editScore: false,
  editLibrary: false,
  playback: false,
  download: false,
  editEverything: false,
  editMasterTracker: false,
  viewReports: false,
  text: false,
  qualityAssurance: false,
  hideUserInfo: false,
}

export type UserPermissions = {
  organization: boolean
  editDashboard: boolean
  editTracker: boolean
  editSegment: boolean
  editScore: boolean
  editLibrary: boolean
  playback: boolean
  download: boolean
  editEverything: boolean
  editMasterTracker: boolean
  viewReports: boolean
  text: boolean
  qualityAssurance: boolean
  hideUserInfo: boolean
}

export type Role = 'user' | 'teamlead' | 'administrator'
export type UiRole = 'user' | 'teamlead' | 'administrator' | 'owner'

export const defaultPermissionsPerRole: {
  [key in UiRole]: Partial<UserPermissions>
} = {
  owner: {
    organization: true,
    editDashboard: true,
    editTracker: true,
    editSegment: true,
    editScore: true,
    editLibrary: true,
    playback: true,
    download: true,
    viewReports: true,
    editEverything: false,
    editMasterTracker: false,
    text: false,
    qualityAssurance: true,
  },
  administrator: {
    editDashboard: true,
    editTracker: true,
    editSegment: true,
    editScore: true,
    editLibrary: true,
    playback: true,
    download: true,
    viewReports: true,
    editMasterTracker: false,
    text: false,
    qualityAssurance: true,
  },
  teamlead: {
    editDashboard: true,
    editTracker: true,
    editScore: true,
    editSegment: true,
    editLibrary: true,
    playback: true,
    viewReports: false,
    text: false,
    qualityAssurance: true,
  },
  user: {
    playback: true,
    editLibrary: true,
    editSegment: true,
    viewReports: false,
    text: false,
  },
}
