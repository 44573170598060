import { Box, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../../../Loader'
import { RefreshButton } from '../../../RefreshButton'
import ConversationsJson from './ConversationsJson'
import ConversationsTable from './ConversationsTable'
import SearchField, { type Filter } from './SearchField'
import SelectFields from './SelectFields'
import { useConversations } from './useConversations'

const Conversations: React.FC = () => {
  const { uid: organizationUid } = useParams()
  const [filter, setFilter] = useState<Filter>({})

  const [showAsJson, setShowAsJson] = useState(false)
  const [fieldsToShow, setFieldsToShow] = useState<string[]>([
    'dateTime',
    'subject',
  ])

  const { data, refetch, isFetching, isLoading } = useConversations(
    organizationUid,
    filter,
  )

  const handleCopyFullJson = () => {
    navigator.clipboard.writeText(JSON.stringify(data, undefined, '   '))
  }

  if (organizationUid == null) return <div>Not found</div>

  return (
    <Box>
      <Flex justifyContent="flex-end" mb="2">
        <RefreshButton
          refresh={refetch}
          isLoading={isFetching}
          position="initial"
        />
      </Flex>
      <SearchField
        onSearch={setFilter}
        isLoading={isLoading}
        showAsJson={showAsJson}
        setShowAsJson={setShowAsJson}
        copyFullJson={handleCopyFullJson}
        numberOfResults={data?.length}
      />
      <SelectFields
        fields={data?.[0] ? extractFlatFields(data[0]) : []}
        setSelectedFields={setFieldsToShow}
        selectedFields={fieldsToShow}
      />
      {isLoading && <Loader />}
      {data && !showAsJson && (
        <ConversationsTable data={data} fieldsToShow={fieldsToShow} />
      )}
      {data && showAsJson && (
        <ConversationsJson data={data} fieldsToShow={fieldsToShow} />
      )}
    </Box>
  )
}

export default Conversations

const extractFlatFields = (o: Record<string, unknown>): string[] => {
  return Object.entries(o).reduce<string[]>((memo, [key, value]) => {
    if (value instanceof Date) memo.push(key)
    if (typeof value === 'string') memo.push(key)
    if (typeof value === 'number') memo.push(key)
    // if (value == null) memo.push(key)

    return memo
  }, [])
}
