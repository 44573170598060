import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import type { Filter } from './SearchField'

export const useConversations = (
  organizationUid: string | undefined,
  filter: Filter,
): UseQueryResult<ConversationResponse[], ResponseError> =>
  useQuery({
    queryFn: () =>
      request.post<ConversationResponse[]>(
        `superpowers/info/conversations/${organizationUid}`,
        {
          json: filter,
        },
      ),
    queryKey: ['superpowers', organizationUid, 'conversations', filter],
    enabled: !!organizationUid,
  })

export type ConversationResponse = {
  uid: string
  dateTime: string
  OrganizationUid: string
  subject: string
  duration: number
  metrics: {
    created: Date
    dataAdded: Date | string
    metadataAdded: Date | string
    audioAdded: Date | string
    processingDone: Date | string
  }
  state: 'Created' | 'Success' | 'Processing' | 'NoRecognizedWords' | 'Error'
} & Record<string, unknown>
