import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

export const useSettingsOverview = (): UseQueryResult<
  { organizations: SystemSettings[] },
  ResponseError
> =>
  useQuery({
    queryKey: ['systemadmin', 'organization', 'settings'],
    queryFn: () => request.get('systemadmin/organization/settings'),
  })

type CustomNumberProp = {
  enabled: boolean
  label: {
    property1: string
    property2: string
  }
  filterType: string
  min: number
  max: number
  filters: Array<{
    label: {
      property1: string
      property2: string
    }
    min: number
    max: number
  }>
}
type CustomProp = {
  enabled: boolean
  label: {
    property1: string
    property2: string
  }
  filterType: string
  values: Array<{
    value: string
    label: {
      property1: string
      property2: string
    }
  }>
}

type SystemSettings = {
  organizationUid: string
  metadata: {
    customNumberProp1: CustomNumberProp
    customNumberProp2: CustomNumberProp
    customNumberProp3: CustomNumberProp
    customNumberProp4: CustomNumberProp
    customNumberProp5: CustomNumberProp
    customNumberProp6: CustomNumberProp
    customNumberProp7: CustomNumberProp
    customNumberProp8: CustomNumberProp
    customNumberProp9: CustomNumberProp
    customNumberProp10: CustomNumberProp
    customProp1: CustomProp
    customProp2: CustomProp
    customProp3: CustomProp
    customProp4: CustomProp
    customProp5: CustomProp
    customProp6: CustomProp
    customProp7: CustomProp
    customProp8: CustomProp
    customProp9: CustomProp
    customProp10: CustomProp
  }
  deletionStrategy: {
    created: string
    createdByUserUid: string
    updated: string
    updatedByUserUid: string
    conversationSoftTimeToLive: string
    conversationHardTimeToLive: string
    audioSnippetTimeToLive: string
    caseTimeToLive: string
    anonymizeAsrData: boolean
    deleteCustomer: boolean
    lastChanged: string
    auditLog: Array<{
      date: string
      message: string
    }>
  }
  ai: {
    enabled: boolean
    provider: string
    useGlobalCache: boolean
    summaryPromptUid: string
    summaryPrompt: string
    maxSummaryWords: number
    keyTopicThreshold: number
  }
  conversationsWithoutConsent: boolean
  deleted: string
}
