import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import React, {} from 'react'

type Props = { secretToken: string } & BaseModalComponentProps

const CreateTokenModal: React.FC<Props> = ({ onClose, secretToken }) => {
  const toast = useToast()

  const copyTokenToClipboard = (): void => {
    navigator.clipboard.writeText(secretToken)
    toast({ title: 'Copied token to clipboard', status: 'info' })
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Secret Token</ModalHeader>

          <ModalBody>
            <Text fontWeight="bold">Token is only vissible once!</Text>
            <Text
              onClick={(e) => e.currentTarget.focus()}
              mt="2"
              p="2"
              bg="gray.100"
              borderRadius="md"
              userSelect="all"
              fontFamily="mono"
            >
              {secretToken}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" color="blue" onClick={copyTokenToClipboard}>
              Copy
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CreateTokenModal
