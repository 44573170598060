import request, { ResponseError } from '@capturi/request'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ModalCloseButton,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import React from 'react'

type Props = {
  resultUid: string
  spokenLanguage: string
  onSuccess: () => void
  onClose: () => void
}

const ConfirmDeleteTranscription: React.FC<Props> = ({
  onClose,
  onSuccess,
  resultUid,
  spokenLanguage,
}) => {
  const toast = useToast()
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const { isPending, error, mutate } = useMutation<string, ResponseError>({
    mutationKey: ['customTranscription', resultUid, spokenLanguage],
    mutationFn: () =>
      request.delete(`superpowers/asr/transcription/custom/${resultUid}`),
  })

  return (
    <AlertDialog
      isOpen
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      closeOnEsc={!isPending}
      closeOnOverlayClick={!isPending}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>Delete {resultUid}</AlertDialogHeader>
        <ModalCloseButton isDisabled={isPending} />
        <AlertDialogBody>
          <div>
            <Text fontWeight="bold">Are you sure?</Text>
          </div>
          <Text>This will delete the transcription and audiofile.</Text>
        </AlertDialogBody>
        <AlertDialogFooter>
          <div>
            <Stack direction="row" spacing={4} justify="flex-end">
              <Button ref={cancelRef} onClick={onClose} isDisabled={isPending}>
                Cancel
              </Button>
              <Button
                isLoading={isPending}
                colorScheme="red"
                onClick={() => {
                  mutate(undefined, {
                    onSuccess: () => {
                      toast({
                        status: 'success',
                        title: `Successfully deleted ${resultUid}`,
                      })
                      onSuccess()
                    },
                    onError: (error) => {
                      toast({
                        status: 'error',
                        title: `Could not delete ${resultUid}`,
                        description: error.message,
                      })
                    },
                  })
                }}
              >
                Delete
              </Button>
            </Stack>
            {error && <Text color="red">{error.message}</Text>}
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ConfirmDeleteTranscription
