import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { Text } from '@chakra-ui/react'
import noop from 'lodash/noop'
import { type FC } from 'react'
import { useResetFilesOnSftp } from '../useOrganizationIntegrations'

type Props = {
  onClose: () => void
  onSubmit: (date: Date) => void
  organizationUid: string
  integrationKey: string
  integrationType: string
}

export const ResetFilesModal: FC<Props> = ({
  onClose,
  organizationUid,
  integrationKey,
  integrationType,
}) => {
  const { mutate, isPending, error } = useResetFilesOnSftp(organizationUid)
  const toast = useToast()
  const handleReset = (): void => {
    mutate(
      {
        integrationType,
        integrationKey,
      },
      {
        onSuccess: () => {
          toast({ status: 'success', title: 'Wuhuu - stuff updated' })
          onClose()
        },
      },
    )
  }

  return (
    <Modal isOpen onClose={isPending ? noop : onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Try all files on ftp again</ModalHeader>
        {!isPending && <ModalCloseButton />}

        <ModalBody display="flex" justifyContent="center">
          <Button primary onClick={handleReset} isLoading={isPending}>
            Reset
          </Button>
          {error && (
            <Text textAlign="center" color="danger">
              {error.message}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} isDisabled={isPending}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
