import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { addWeeks, endOfWeek, getWeek, setWeek, subMonths } from 'date-fns'
import React, { ReactElement, useState } from 'react'
import useSWR from 'swr'

import Loader from '../../../Loader'

type Props = { organizationUid: string }

type Day = {
  count: number
  first: Date
}

const emptyRow = [0, 0, 0, 0, 0, 0, 0]

const getDates = (key: string): [Date, Date] => {
  const [year, week] = key.split('-')
  const firstDayOfWeek = setWeek(new Date(Number(year), 0, 0), Number(week))
  return [firstDayOfWeek, endOfWeek(firstDayOfWeek)]
}

const numberFormat = (number: number): string =>
  new Intl.NumberFormat('da-DK').format(number)

const generateKey = (date: Date): string =>
  `${date.getFullYear()}-${getWeek(date)}`

const generateFullList = (
  startDate: Date,
  endDate: Date,
  data: Day[],
): {
  key: string
  data: { count: number; date: Date }[]
}[] => {
  const formattedData = data.reduce<
    Record<string, { count: number; date: Date }[]>
  >((memo, day) => {
    const date = day.first
    const key = generateKey(date)

    const days = [...(memo[key] || emptyRow)]

    days[date.getDay()] = { count: day.count, date: date }
    memo[key] = days
    return memo
  }, {})

  let currentDate = startDate
  const allWeeks: Record<string, { count: number; date: Date }[]> = {}

  const end = endOfWeek(endDate)

  while (currentDate < end) {
    const key = generateKey(currentDate)
    allWeeks[key] = formattedData[key] || emptyRow
    currentDate = addWeeks(currentDate, 1)
  }

  const list = Object.entries(allWeeks)
    .map(([key, value]) => {
      return {
        key: key,
        data: value,
      }
    })
    .reverse()

  return list
}

const ConversationsPerDay: React.FC<Props> = ({ organizationUid }) => {
  const [onlySucceeded, setOnlySucceeded] = useState<boolean>(false)
  const [monthsToLookBack, setMonthsToLookBack] = useState<number>(6)
  const { data } = useSWR<Day[]>(
    `superpowers/organization/${organizationUid}/insights/conversations-per-day?onlySucceeded=${onlySucceeded}&monthsToLookBack=${monthsToLookBack}`,
    { suspense: false },
  )

  const allWeeks = generateFullList(
    subMonths(Date.now(), monthsToLookBack),
    new Date(),
    data || [],
  )
  const todayDay = new Date().getDay()
  const renderCell = (
    value: { count: number; date: Date },
    day: number,
  ): ReactElement => {
    const isToday = day === todayDay
    return (
      <Td
        textAlign="center"
        borderBottom={isToday ? 'none' : undefined}
        bg={isToday ? 'primary.100' : undefined}
        key={day}
        title={value.date?.toLocaleDateString()}
      >
        {numberFormat(value.count || 0)}
      </Td>
    )
  }
  const renderHeaderCell = (value: string, day: number): ReactElement => {
    const isToday = day === todayDay
    return (
      <Th
        textAlign="center"
        color={isToday ? 'primary.400' : undefined}
        key={day}
      >
        {value}
      </Th>
    )
  }

  const renderRow = ({
    key,
    data,
  }: {
    key: string
    data: { count: number; date: Date }[]
  }): ReactElement => {
    const [startOfWeek, endOfWeek] = getDates(key)
    const tooltipText = `${startOfWeek.toLocaleDateString(
      'da-DK',
    )} - ${endOfWeek.toLocaleDateString('da-DK')}`
    return (
      <Tr key={key}>
        <Td>
          <Tooltip label={tooltipText}>{key}</Tooltip>
        </Td>
        {data.map(renderCell)}
      </Tr>
    )
  }

  return (
    <Box mt="20">
      <Flex width="100%" justifyContent="space-between">
        <Text fontSize="xl">Conversation per Week</Text>
        <Flex alignItems="center">
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="only-succeeded" mb="0">
              Only show Succeeded
            </FormLabel>
            <Switch
              id="only-succeeded"
              onChange={(e) => setOnlySucceeded(e.currentTarget.checked)}
              isChecked={onlySucceeded}
            />
          </FormControl>
          <NumberInput
            title="Months to look back"
            size="xs"
            width="32"
            min={1}
            mr="4"
            onChange={(e) => {
              setMonthsToLookBack(Number(e))
            }}
            value={monthsToLookBack}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Flex>

      {!data ? (
        <Box my="20">
          <Loader />
        </Box>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Key</Th>
              {[
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ].map(renderHeaderCell)}
            </Tr>
          </Thead>
          <Tbody>{allWeeks.map(renderRow)}</Tbody>
        </Table>
      )}
    </Box>
  )
}

export default ConversationsPerDay
