import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import Loader from '../Loader'

type Props = { organizationUid: string } & BaseModalComponentProps

type Response = {
  organizationUid: string
  organizationName: string
  markedForDeletion: Date
  markedForDeletionBy: string
  markedForDeletionByName: string
  markedForDeletionByEmail: string
  deletionInitiated: Date
  deletionLog: string[]
}

const DeletionReportDetailsModal: React.FC<Props> = ({
  onClose,
  organizationUid,
}) => {
  const { data, isLoading, error } = useQuery<Response, ResponseError>({
    queryKey: ['superpowers', 'deletionReports', organizationUid],
    queryFn: () =>
      request.get(
        `systemadmin/organization/${organizationUid}/deletion-report`,
      ),
  })

  const renderBody = () => {
    if (error) return <Text>{error.message}</Text>
    if (isLoading) return <Loader />

    return (
      <Text
        whiteSpace="break-spaces"
        fontFamily="monospace"
        bg="gray.100"
        p="2"
        borderRadius="5px"
      >
        {JSON.stringify(data, undefined, '   ').replaceAll(/{|}|\[|\]|"/g, '')}
      </Text>
    )
  }

  return (
    <Modal isOpen onClose={onClose} size="4xl">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Delteion report details</ModalHeader>

          <ModalCloseButton />
          <ModalBody>{renderBody()}</ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button primary onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default DeletionReportDetailsModal
