import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { MdChevronRight } from 'react-icons/md'

import { Question } from './useAiQuestions'

type Props = {
  question: Question
  setQuestion: (state: Question) => void
}

const AskAiQuestionList: React.FC<Props> = ({ question, setQuestion }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      key={question.uid}
      _hover={{ bg: 'gray.200' }}
      cursor="pointer"
      p="2"
      width="100%"
      borderBottom="1px solid"
      borderColor="gray.100"
      onClick={() => setQuestion(question)}
    >
      <Text>{question.name}</Text>
      <MdChevronRight />
    </Flex>
  )
}

export default AskAiQuestionList
