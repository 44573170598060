import { useScrollable } from '@capturi/react-utils'
import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Flex,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import React, { useRef, useState } from 'react'
import { convertToCsv } from '../../utils/convertToCsv'

type Props = {
  integrationKey: string
  sessionUid?: string
  integrationType: string
} & BaseModalComponentProps
type Response = {
  recordset: Record<string, string | Date | null>[]
}

const QuerySql: React.FC<Props> = ({
  onClose,
  integrationKey,
  integrationType,
}) => {
  const [command, setCommand] = useState(
    "select top(100) * from call_events where EVENT_TYPE = 'r' order by dte_start desc",
  )
  const [tabelView, setTabelView] = useState(true)
  const ref = useRef(null)
  const heigth = useScrollable(ref, 70)
  const { data, isPending, error, mutate } = useMutation<
    Response,
    ResponseError
  >({
    mutationFn: async () =>
      request.post<Response>(
        `superpowers/integration/${integrationType}/${integrationKey}/sql`,
        {
          json: { sql: command },
          timeout: 100_000_000,
        },
      ),
  })

  const dataList = Array.isArray(data) ? data : data?.recordset || []

  return (
    <Modal isOpen onClose={onClose} size="full">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader display="flex">
            <Flex>
              <Button
                isDisabled={isPending}
                mr="4"
                variant="outline"
                primary
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.stringify(dataList, undefined, '   '),
                  )
                }}
              >
                Copy json
              </Button>
              <Button
                isDisabled={isPending}
                mr="4"
                variant="outline"
                primary
                onClick={() => {
                  navigator.clipboard.writeText(convertToCsv(dataList))
                }}
              >
                Copy CSV
              </Button>
              <Flex alignItems="center" marginRight="80px">
                <Switch
                  p={1}
                  onChange={(e) => setTabelView(e.currentTarget.checked)}
                  isChecked={tabelView}
                />
                Table view
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="sql statement"
              fontFamily="monospace"
              value={command}
              onChange={(e) => setCommand(e.currentTarget.value)}
              height={200}
            />
            <Button
              mt="2"
              isLoading={isPending}
              primary
              onClick={() => {
                mutate()
              }}
            >
              query
            </Button>

            {error && <Text>{error.message}</Text>}

            <Box
              whiteSpace="nowrap"
              fontFamily="monospace"
              overflow="auto"
              ref={ref}
              height={`${heigth}px`}
            >
              {tabelView ? (
                <Table>
                  <Thead>
                    <Tr>
                      {Object.keys(dataList[0] || {}).map((v) => (
                        <Th key={v}>{v}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody overflow="scroll">
                    {dataList.map((d, index) => {
                      return (
                        <Tr key={index} _hover={{ bg: 'gray.50' }}>
                          {Object.values(d).map((v, index) => {
                            if (typeof v === 'string')
                              return <Td key={index}>{v}</Td>
                            if (typeof v === 'number')
                              return (
                                <Td isNumeric key={index}>
                                  {v}
                                </Td>
                              )
                            if (v instanceof Date)
                              return (
                                <Td key={index}>{v.toLocaleString('da-DK')}</Td>
                              )
                            return (
                              <Td key={index} color="gray.500">
                                null
                              </Td>
                            )
                          })}
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Text whiteSpace="break-spaces" bg="gray.100">
                  {JSON.stringify(dataList, undefined, '   ')}
                </Text>
              )}
            </Box>
            {/* </Text> */}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button primary onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default QuerySql
