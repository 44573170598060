import { useInput } from '@capturi/react-utils'
import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Textarea,
} from '@chakra-ui/react'
import React from 'react'

import {
  useAddSummaryPrompts,
  useDeleteSummaryPrompts,
  useUpdateSummaryPrompts,
} from '../../FeatureFlags/api/useSummaryPrompts'

type Props = { uid?: string; name: string; prompt: string; onClose: () => void }

const CustomSummaryPromptsEditor: React.FC<Props> = ({
  uid,
  name,
  prompt,
  onClose,
}) => {
  const [state, setState] = useInput({ name: name, prompt: prompt })
  const { mutate: addSummary, isPending: isLoadingAdd } = useAddSummaryPrompts()
  const { mutate: updateSummary, isPending: isLoadingUpdate } =
    useUpdateSummaryPrompts(uid)
  const { mutate: deleteSummary, isPending: isLoadingDelete } =
    useDeleteSummaryPrompts(uid)
  const isLoading = isLoadingAdd || isLoadingUpdate || isLoadingDelete
  const toast = useToast()
  const hasChanges = name !== state.name || prompt !== state.prompt

  const handleDelete = (): void => {
    deleteSummary(undefined, {
      onSuccess: () => {
        onClose()
      },
      onError: (err) => {
        toast({
          title: 'Could not delete prompt',
          status: 'error',
          description: err.message,
        })
      },
    })
  }

  const handleSave = (): void => {
    if (uid) {
      updateSummary(
        {
          name: state.name,
          prompt: state.prompt,
        },
        {
          onSuccess: () => {
            onClose()
          },
        },
      )
    } else {
      addSummary(state, {
        onSuccess: () => {
          onClose()
        },
      })
    }

    onClose()
  }

  return (
    <Box pl="4">
      <FormControl>
        <FormLabel htmlFor="name">Name</FormLabel>
        <Input
          id="name"
          placeholder="name"
          {...setState.name}
          isDisabled={isLoading}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="prompt">Prompt</FormLabel>
        <Textarea
          isDisabled={isLoading}
          id="prompt"
          placeholder="prompt"
          {...setState.prompt}
          height="300"
        />
      </FormControl>

      <Flex mt="2" justifyContent="space-between">
        <Button
          onClick={handleDelete}
          colorScheme="red"
          isDisabled={isLoading || !uid}
          isLoading={isLoadingDelete}
        >
          Delete
        </Button>
        <HStack spacing="2" justifyContent="flex-end">
          <Button onClick={onClose} isDisabled={isLoading}>
            Cancel
          </Button>
          <Button
            primary
            isLoading={isLoadingAdd}
            onClick={handleSave}
            isDisabled={!hasChanges || isLoadingDelete}
          >
            Save
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}

export default CustomSummaryPromptsEditor
