import { Button } from '@capturi/ui-components'
import { Flex, HStack, Text, useToast } from '@chakra-ui/react'
import React, { ReactElement, useEffect, useState } from 'react'
import PhrasesEditor from '../components/PhrasesEditor'
import {
  useAllKeyTopicsWordCloudBlacklist,
  useKeyTopicsWordCloudBlacklist,
  useUpdateKeyTopicsWordCloudBlacklist,
} from './useKeyTopicsWordCloudBlacklist'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const KeyTopicsWordCloudBlacklist: React.FC = () => {
  const [language, setLanguage] = useState<string>('da-DK')
  const toast = useToast()

  const { data, isLoading } = useKeyTopicsWordCloudBlacklist(language)
  const { data: wordCloudSettingsList } = useAllKeyTopicsWordCloudBlacklist()
  const { mutate: updateKeyTopicsBlacklist } =
    useUpdateKeyTopicsWordCloudBlacklist()
  const [stopWords, setStopWords] = useState<string[]>(data?.stopWords ?? [])
  const [nGrams, setNGrams] = useState<string[]>(data?.blacklistNgrams ?? [])

  const handleUpdateWords = () => {
    updateKeyTopicsBlacklist(
      { stopWords: stopWords, blacklistNgrams: nGrams, language: language },
      {
        onSuccess: () => {
          toast({ title: 'words & ngrams updated', status: 'success' })
        },
        onError: (error) => {
          toast({
            title: 'Updating words & ngrams failed.',
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  useEffect(() => {
    if (data) {
      setStopWords(data.stopWords)
      setNGrams(data.blacklistNgrams)
    }
  }, [data])

  const renderButtons = (): ReactElement => {
    return (
      <>
        {wordCloudSettingsList?.wordCloudSettings.map((settings, i) => (
          <Button
            key={settings.language}
            primary
            borderLeftRadius={i === 0 ? 'lg' : 0}
            borderRightRadius={
              i + 1 === wordCloudSettingsList.wordCloudSettings.length
                ? 'lg'
                : 0
            }
            variant={language === settings.language ? 'solid' : 'outline'}
            onClick={() => setLanguage(settings.language)}
          >
            {settings.language}
          </Button>
        ))}
      </>
    )
  }

  return (
    <>
      <Flex align="center" mb="4" justifyContent="center">
        {renderButtons()}
      </Flex>
      <>
        <PhrasesEditor
          state={stopWords}
          onChange={setStopWords}
          isLoading={isLoading}
          isSuccess={true}
          title="Stop words"
          placeholder="Stop word"
          mb="4"
        />
        <PhrasesEditor
          state={nGrams}
          onChange={setNGrams}
          isLoading={isLoading}
          isSuccess={true}
          title="Blacklisted nGrams"
          placeholder="Blacklisted nGram"
          mb="4"
        />
      </>
      <HStack justify="space-between">
        <Text>
          Last updated: {data?.updated?.toLocaleDateString('da-DK', dateFormat)}
        </Text>
        <Button onClick={handleUpdateWords} primary>
          Save
        </Button>
      </HStack>
    </>
  )
}

export default KeyTopicsWordCloudBlacklist
