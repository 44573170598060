import request, { getErrorObject } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { Text } from '@chakra-ui/react'
import noop from 'lodash/noop'
import React, { useEffect, useState } from 'react'
import { useCopyToClipboard } from 'react-use'

import Loader from '../../../Loader'

type Props = { organizationUid: string; onClose: () => void }

const CreateApiToken: React.FC<Props> = ({ organizationUid, onClose }) => {
  const [token, setToken] = useState<string>('')
  const [expireDate, setExpireDate] = useState<Date | null>(null)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)

  const [, copy] = useCopyToClipboard()
  const toast = useToast()

  useEffect(() => {
    const fetchToken = async (): Promise<void> => {
      try {
        setInProgress(true)

        const resp = await request.post<{
          token: string
          expireDate: Date
          organizationUid: string
        }>(`superpowers/organization/${organizationUid}/short-lived-api-token`)
        setToken(resp.token)
        setExpireDate(resp.expireDate)
        setInProgress(false)
      } catch (e) {
        setInProgress(false)
        const error = await getErrorObject(e)
        setError(error)
      }
    }

    fetchToken()
  }, [organizationUid])

  const copyTokenToClipboard = (): void => {
    copy(token)
    toast({ title: 'Copied token to clipboard', status: 'info' })
  }

  return (
    <Modal
      isOpen
      onClose={inProgress ? noop : onClose}
      closeOnOverlayClick={!inProgress}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Api token</ModalHeader>
        {!inProgress && <ModalCloseButton />}
        <ModalBody>
          {inProgress && <Loader />}
          {error && <Text>{error.message}</Text>}
          {token && expireDate && (
            <>
              <Text>Expire date: {expireDate.toLocaleString()}</Text>
              <Text
                onClick={(e) => e.currentTarget.focus()}
                mt="2"
                p="2"
                bg="gray.100"
                borderRadius="md"
                userSelect="all"
                fontFamily="mono"
              >
                {token}
              </Text>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" isDisabled={inProgress} onClick={onClose}>
            Close
          </Button>
          <Button
            variant="ghost"
            color="blue"
            isDisabled={inProgress}
            onClick={copyTokenToClipboard}
          >
            Copy
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateApiToken
