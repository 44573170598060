import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

type HeimdalStatus = {
  newestEntry: Date
  oldestEntry: Date
  durationMs: number
  duration: string
  totalConversationsInDatabase: number
}
export const useHeimdalStatus = (): UseQueryResult<
  HeimdalStatus,
  ResponseError
> =>
  useQuery({
    queryKey: ['heimdal', 'status'],
    queryFn: () => request.get('heimdal/status'),
  })

export type HeimdalData = {
  name: string
  organizationUid: string
  avg: number
  current: number
  from: Date
  to: Date
}

export const useHeimdal = (): UseQueryResult<HeimdalData[], ResponseError> =>
  useQuery({
    placeholderData: keepPreviousData,
    queryKey: ['heimdal', 'all'],
    staleTime: 5 * 60 * 1000,
    queryFn: () => request.get('heimdal/missing?sync=false'),
  })

type BackSyncConfig = {
  weeks: number
}

type SyncResponse = {
  added: number
  fromDate: Date
  durationMs: number
  duration: string
}

export const useBackSyncHeimdal = (): UseMutationResult<
  SyncResponse,
  ResponseError,
  BackSyncConfig,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['heimdal'],
    mutationFn: (model) =>
      request.post('heimdal/back-sync', { json: model, timeout: false }),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['heimdal'],
      })
    },
  })
}

export const useSyncHeimdal = (): UseMutationResult<
  SyncResponse,
  ResponseError,
  void,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['heimdal'],
    mutationFn: () => request.post('heimdal/sync'),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['heimdal'],
      })
    },
  })
}

export type Muted = {
  muteEndTime: Date
  organizationUid: string
  name: string
  mutedBy: string
}

export const useMute = (): UseMutationResult<
  Muted,
  ResponseError,
  { organizationUid: string; muteEndTime: Date },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload) => request.post('heimdal/mute', { json: payload }),
    onSuccess: (response) => {
      queryClient.setQueryData<HeimdalData[]>(['heimdal', 'all'], (data) => {
        if (!data) return data

        return data.filter(
          (d) => d.organizationUid !== response.organizationUid,
        )
      })

      queryClient.setQueryData<Muted[]>(['heimdal', 'muted'], (oldDate) => {
        if (!oldDate) return [response]

        return [...oldDate, response]
      })
    },
  })
}
export const useUnmute = (): UseMutationResult<
  null,
  ResponseError,
  string,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (organizationUid) =>
      request.post(`heimdal/unmute/${organizationUid}`),
    onSuccess: (_, organizationUid) => {
      queryClient.refetchQueries({
        queryKey: ['heimdal', 'all'],
      })
      queryClient.setQueryData<Muted[]>(['heimdal', 'muted'], (oldData) => {
        if (!oldData) return []
        return oldData.filter((o) => o.organizationUid !== organizationUid)
      })
    },
  })
}

const sortMuted = (data: Muted[]): Muted[] =>
  data.sort((a, b) => a.muteEndTime.getTime() - b.muteEndTime.getTime())

export const useMuted = (): UseQueryResult<Muted[], ResponseError> =>
  useQuery({
    queryKey: ['heimdal', 'muted'],
    queryFn: () => request.get<Muted[]>('heimdal/muted'),
    select: sortMuted,
  })
