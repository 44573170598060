import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import React from 'react'
import { IntegrationConfig } from './IntegrationConfig'
import {
  useAudioImporterConfig,
  useAudioImporterSchema,
} from './useIntegrationConfig'

type Props = {
  integrationKey: string
  integrationType: string
} & BaseModalComponentProps

const ConfigModal: React.FC<Props> = ({
  onClose,
  integrationKey,
  integrationType,
}) => {
  const schema = useAudioImporterSchema(integrationType, integrationKey)
  const config = useAudioImporterConfig(integrationType, integrationKey)

  const isLoading = config.isLoading || schema.isLoading
  const isError = config.isError || schema.isError

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="6xl"
      closeOnOverlayClick={isLoading || isError}
    >
      <ModalOverlay>
        {isLoading && (
          <ModalContent>
            <ModalHeader>Loading config</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              minH="xl"
            >
              <Spinner size="xl" />
            </ModalBody>
          </ModalContent>
        )}

        {isError && (
          <ModalContent>
            <ModalHeader>Loading Error</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              minH="xl"
            >
              {schema.isError && (
                <Text fontSize="xl" color="danger">
                  {schema.error.message}
                </Text>
              )}
              {config.isError && (
                <Text fontSize="xl" color="danger">
                  {config.error.message}
                </Text>
              )}
            </ModalBody>
          </ModalContent>
        )}

        {schema.data && config.data && (
          <IntegrationConfig
            onClose={onClose}
            integrationType={integrationType}
            integrationKey={integrationKey}
            schema={schema.data}
            currentIntegrationOptions={config.data.integrationOptions}
            currentCustomFields={config.data.customFields}
            currentContinuationToken={config.data.continuationToken}
          />
        )}
      </ModalOverlay>
    </Modal>
  )
}

export default ConfigModal
