import { Spinner, useToast } from '@capturi/ui-components'
import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
  Text,
} from '@chakra-ui/react'
import noop from 'lodash/noop'
import React, { ReactElement, useEffect, useState } from 'react'

import {
  useSystemSettings,
  useUpdateSystemSettings,
} from '../../../useSystemSettings'

type Props = { organizationUid: string; onClose: () => void; isNew: boolean }

type DeletionStrategy = {
  deleteCustomer: boolean
  conversationSoftTimeToLive: string | null
  conversationHardTimeToLive: string | null
  caseTimeToLive: string
  audioSnippetTimeToLive: string | null
  anonymizeAsrData: boolean
}

const DEFAULT_TTL = {
  softDeleteConversations: 90,
  hardDeleteConversations: 365,
  caseTimeToLive: 1100,
  anonymizeAsrData: true,
  audioSnuppetsDelete: 1095,
}

const renderTitle = (title: string, mt?: string): ReactElement => {
  return (
    <Text
      mt={mt}
      mb="2"
      pb="1"
      fontWeight="500"
      borderBottom="1px solid"
      borderColor="gray.100"
      w="100%"
    >
      {title}
    </Text>
  )
}

const numberToTimeSpan = (n: number): string => `${n}.00:00:00`
const timeSpanToNumber = (
  timeSpan: string | undefined | null,
  defaultValue: number,
): number => (timeSpan ? Number(timeSpan.split('.')[0]) : defaultValue)

const DeletionStrategy: React.FC<Props> = ({
  organizationUid,
  onClose,
  isNew,
}) => {
  const { data, error: fetchError } = useSystemSettings(organizationUid)
  const {
    mutate,
    isPending: inProgress,
    error,
  } = useUpdateSystemSettings(organizationUid)
  const toast = useToast()

  const [deleteCustomer, setDeleteCustomer] = useState<boolean>(false)
  const [conversationsWithoutConsent, setConversationsWithoutConsent] =
    useState(false)
  //Hard delete conversations
  const [enableConversationHardDelete, setEnableConversationHardDelete] =
    useState(isNew)
  const [conversationHardDeleteDays, setConversationHardDeleteDays] =
    useState<number>(DEFAULT_TTL.hardDeleteConversations)

  //Soft delete conversation
  const [enableConversationSoftDelete, setEnableConversationSoftDelete] =
    useState(isNew)
  const [conversationSoftDeleteDays, setConversationSoftDeleteDays] =
    useState<number>(DEFAULT_TTL.softDeleteConversations)

  //Case deletion TTL
  const [caseTimeToLive, setCaseTimeToLive] = useState(
    DEFAULT_TTL.caseTimeToLive,
  )

  const [anonymizeAsrData, setAnonymizeAsrData] = useState<boolean>(isNew)

  //Audio snippets deletion strategy
  const [enableAudioSnippetsDelete, setEnableAudioSnippetsDelete] = useState(
    isNew || data?.deletionStrategy.audioSnippetTimeToLive != null,
  )
  const [audioSnippetsDeleteDays, setAudioSnippetsDeleteDaysDays] =
    useState<number>(
      timeSpanToNumber(
        data?.deletionStrategy.audioSnippetTimeToLive,
        DEFAULT_TTL.audioSnuppetsDelete,
      ),
    )

  useEffect(() => {
    if (isNew) return
    setDeleteCustomer(data?.deletionStrategy.deleteCustomer ?? false)
    setConversationsWithoutConsent(data?.conversationsWithoutConsent ?? false)

    //Hard delete conversations
    setEnableConversationHardDelete(
      data?.deletionStrategy.conversationHardTimeToLive !== null,
    )
    setConversationHardDeleteDays(
      timeSpanToNumber(
        data?.deletionStrategy.conversationHardTimeToLive,
        DEFAULT_TTL.hardDeleteConversations,
      ),
    )
    //Soft delete conversation
    setEnableConversationSoftDelete(
      data?.deletionStrategy.conversationSoftTimeToLive !== null,
    )
    setConversationSoftDeleteDays(
      timeSpanToNumber(
        data?.deletionStrategy.conversationSoftTimeToLive,
        DEFAULT_TTL.softDeleteConversations,
      ),
    )
    //Case deletion TTL
    setCaseTimeToLive(
      timeSpanToNumber(
        data?.deletionStrategy.caseTimeToLive,
        DEFAULT_TTL.caseTimeToLive,
      ),
    )

    //Audio snippets deletion
    setEnableAudioSnippetsDelete(
      data?.deletionStrategy.audioSnippetTimeToLive != null,
    )
    setAudioSnippetsDeleteDaysDays(
      timeSpanToNumber(
        data?.deletionStrategy.audioSnippetTimeToLive,
        DEFAULT_TTL.audioSnuppetsDelete,
      ),
    )

    setAnonymizeAsrData(data?.deletionStrategy.anonymizeAsrData ?? false)
  }, [data, isNew])

  const handleSave = (): void => {
    const payload: DeletionStrategy = {
      deleteCustomer: deleteCustomer,
      audioSnippetTimeToLive: enableAudioSnippetsDelete
        ? numberToTimeSpan(audioSnippetsDeleteDays)
        : null,
      conversationHardTimeToLive: enableConversationHardDelete
        ? numberToTimeSpan(conversationHardDeleteDays)
        : null,
      conversationSoftTimeToLive: enableConversationSoftDelete
        ? numberToTimeSpan(conversationSoftDeleteDays)
        : null,
      caseTimeToLive: numberToTimeSpan(caseTimeToLive),
      anonymizeAsrData: anonymizeAsrData,
    }

    mutate(
      {
        deletionStrategy: payload,
        conversationsWithoutConsent: conversationsWithoutConsent,
      },
      {
        onSuccess: () => {
          toast({ title: 'Deletion strategy updated' })
          onClose()
        },
      },
    )
  }

  const renderRow = (
    text: string,
    isEnabled: boolean,
    toggle: React.Dispatch<React.SetStateAction<boolean>>,
    days: number,
    setDays: (val: number) => void,
    color: 'red' | 'orange',
  ): ReactElement => {
    return (
      <FormControl mt="3" whiteSpace="nowrap" alignItems="center">
        <Flex justifyContent="space-between">
          <Flex alignItems="center">
            <Switch
              key="enableSoftDelete"
              colorScheme={color}
              isChecked={isEnabled}
              onChange={() => toggle((val) => !val)}
              isDisabled={inProgress || !data}
              size="sm"
              mr="4"
            />

            <Text m="0" fontWeight="normal">
              {text}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <NumberInput
              isDisabled={!(isEnabled && data)}
              w="100px"
              min={0}
              size="xs"
              onChange={(_, number) => setDays(number)}
              value={days}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text ml="2">days</Text>
          </Flex>
        </Flex>
      </FormControl>
    )
  }

  return (
    <Modal
      isOpen
      onClose={inProgress ? noop : onClose}
      closeOnOverlayClick={false}
      closeOnEsc={!isNew}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Deletion Strategy</ModalHeader>
        {!inProgress && <ModalCloseButton />}

        <ModalBody my="4">
          {!data && <Spinner />}
          {fetchError && <Text>{fetchError.message}</Text>}
          {data && (
            <>
              {renderTitle('Delete Customer Channel')}
              <FormControl whiteSpace="nowrap" alignItems="center">
                <Flex justifyContent="space-between">
                  <Flex alignItems="center">
                    <Switch
                      key="enableDeleteCustomer"
                      isChecked={deleteCustomer}
                      onChange={() => setDeleteCustomer((val) => !val)}
                      isDisabled={inProgress || !data}
                      size="sm"
                      mr="4"
                    />
                    <Text m="0" fontWeight="normal">
                      Delete customer
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
              {renderTitle(
                'Conversation Deletion Strategy (recorded calls)',
                '8',
              )}
              {renderRow(
                'Soft Delete Conversations after',
                enableConversationSoftDelete,
                setEnableConversationSoftDelete,
                conversationSoftDeleteDays,
                setConversationSoftDeleteDays,
                'orange',
              )}
              <FormControl
                mt="2"
                ml="4"
                whiteSpace="nowrap"
                alignItems="center"
              >
                <Flex justifyContent="space-between">
                  <Flex alignItems="center">
                    <Switch
                      key="enableAnonymizeAsrData"
                      isChecked={anonymizeAsrData}
                      onChange={() => setAnonymizeAsrData((val) => !val)}
                      isDisabled={
                        inProgress ||
                        !data ||
                        !enableConversationSoftDelete ||
                        conversationsWithoutConsent
                      }
                      size="sm"
                      mr="4"
                    />
                    <Text m="0" fontWeight="normal">
                      Anonymize ASR Data
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
              {renderRow(
                'Hard Delete Conversations after',
                enableConversationHardDelete,
                setEnableConversationHardDelete,
                conversationHardDeleteDays,
                setConversationHardDeleteDays,
                'red',
              )}
              <FormControl mt="3" whiteSpace="nowrap" alignItems="center">
                {renderTitle('Case deletion policy')}
                <Flex justifyContent="space-between">
                  <Text m="0" fontWeight="normal">
                    Delete cases after
                  </Text>
                  <Flex alignItems="center">
                    <NumberInput
                      isDisabled={!data}
                      w="100px"
                      min={0}
                      size="xs"
                      onChange={(_, number) => setCaseTimeToLive(number)}
                      value={caseTimeToLive}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text ml="2">days</Text>
                  </Flex>
                </Flex>
              </FormControl>
              {renderTitle('Audio Snippet Deletion Strategy', '8')}
              {renderRow(
                'Delete Audio Snippets After',
                enableAudioSnippetsDelete,
                setEnableAudioSnippetsDelete,
                audioSnippetsDeleteDays,
                setAudioSnippetsDeleteDaysDays,
                'orange',
              )}

              {renderTitle('Anonymize conversations right after import', '8')}

              <FormControl mt="2" whiteSpace="nowrap" alignItems="center">
                <Flex justifyContent="space-between">
                  <Flex alignItems="center">
                    <Switch
                      key="conversationsWithoutConsent"
                      colorScheme="red"
                      isChecked={conversationsWithoutConsent}
                      onChange={() =>
                        setConversationsWithoutConsent((val) => !val)
                      }
                      isDisabled={inProgress || !data}
                      size="sm"
                      mr="4"
                    />
                    <Text m="0" fontWeight="normal">
                      Anonymize conversations right after import
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {!isNew && (
            <Button variant="ghost" isDisabled={inProgress} onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            isLoading={inProgress}
            colorScheme="primary"
            onClick={handleSave}
          >
            {isNew ? 'Save' : 'Update'}
          </Button>
        </ModalFooter>
        {error != null && (
          <Text textAlign="center" p="3" color="danger">
            {error.message}
          </Text>
        )}
      </ModalContent>
    </Modal>
  )
}

export default DeletionStrategy
