const formatter = new Intl.DateTimeFormat('en-US', {
  timeStyle: 'long',
  dateStyle: 'long',
})

const formatDate = (d: Date): string => {
  return formatter.format(d)
}

export default formatDate
