import { Tag } from '@chakra-ui/react'
import React from 'react'
import { integrations } from '../integrations'
import { getTextColor } from './Integration/utils/getTextColor'

type Props = {
  integrationType: string
  integrationKey: string
  fallbackColor?: string
  size?: 'sm' | 'md' | 'lg'
}

const IntegrationChip: React.FC<Props> = ({
  integrationKey,
  integrationType,
  fallbackColor = '#eeeeee',
  size = 'sm',
}) => {
  const { color, name } = integrations.get(integrationType) || {
    color: integrationType === 'External' ? '#216D97' : fallbackColor,
    name: integrationType,
  }

  return (
    <div>
      <Tag
        whiteSpace="nowrap"
        title={integrationKey}
        size={size}
        bg={color}
        color={getTextColor(color)}
        key={integrationType + integrationKey}
        mr="1"
      >
        {name}
      </Tag>
    </div>
  )
}
export default IntegrationChip
