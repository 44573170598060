import { Box, FormControl, FormLabel, Switch } from '@chakra-ui/react'
import React, { useState } from 'react'

import KeepLabels from './KeepLabels'
import RemoveLabels from './RemoveLabels'

const LabelEditor: React.FC = () => {
  const [mode, setMode] = useState<'remove' | 'keep'>('remove')

  return (
    <Box>
      <FormControl display="flex" mt="4">
        <Switch
          colorScheme="green"
          id="keepMode"
          isChecked={mode === 'keep'}
          onChange={(e) => setMode(e.currentTarget.checked ? 'keep' : 'remove')}
          mr="2"
        />
        <FormLabel htmlFor="keepMode">Keep Mode</FormLabel>
      </FormControl>

      {mode === 'remove' ? <RemoveLabels /> : <KeepLabels />}
    </Box>
  )
}

export default LabelEditor
