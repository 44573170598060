import { Flex, FlexProps, Spinner } from '@chakra-ui/react'
import React from 'react'

const Loader: React.FC<FlexProps> = (props) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="100%"
      h="100%"
      {...props}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  )
}

export default Loader
