import request, { getErrorMessage } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import useSWR from 'swr'

enum Role {
  user = 'user',
  administrator = 'administrator',
  teamlead = 'teamlead',
}

const ShiftRole: React.FC = () => {
  const toast = useToast()

  const { data: user } = useSWR<{ role: Role }>('authentication/me', {
    dedupingInterval: 1000 * 60 * 7, //We request a new auth token every 7 minut,
    revalidateOnFocus: false,
    suspense: false,
  })
  const [role, setRole] = useState<Role>(user?.role || Role.user)

  const handleRoleMe = async (userRole: Role): Promise<void> => {
    setRole(userRole as Role)
    try {
      await request.put('superpowers/me/role/', {
        json: { role: userRole },
      })
      toast({
        status: 'success',
        title: 'Refresh page for changes to be applied',
      })
    } catch (error) {
      const errorMsg = getErrorMessage(error)

      toast({ status: 'error', title: errorMsg })
    }
  }

  return (
    <FormControl display="flex" alignItems="center">
      <RadioGroup
        onChange={(value) => {
          handleRoleMe(value as Role)
        }}
        value={role}
      >
        <Stack direction="row">
          <Radio value="user">User</Radio>
          <Radio value="teamlead">Team Lead</Radio>
          <Radio value="administrator">Administrator</Radio>
        </Stack>
      </RadioGroup>
      <FormLabel htmlFor="showLogEvents" fontWeight="normal" m={0} ml={10}>
        ShiftRole - Change user role
      </FormLabel>
    </FormControl>
  )
}

export default ShiftRole
