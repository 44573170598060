import { Flex, FormControl, FormLabel, Input, Link } from '@chakra-ui/react'
import React, { useEffect } from 'react'

type Props = {
  inProgress: boolean
  setState: (key: string) => void
  state: string
  setError: (value: boolean) => void
}

const cronRegex = /^$|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*|\*\/\d) ?){5})/

const ScheduleConfig: React.FC<Props> = ({
  inProgress,
  state,
  setState,
  setError,
}) => {
  const isInvalid = !cronRegex.test(state)
  useEffect(() => {
    setError(isInvalid)
  }, [isInvalid, setError])

  return (
    <FormControl mt="2">
      <FormLabel htmlFor="schedule">
        Schedule cron - leave empty for default
      </FormLabel>
      <Input
        isInvalid={isInvalid}
        id="schedule"
        onChange={(e) => setState(e.currentTarget.value)}
        value={state}
        isDisabled={inProgress}
        placeholder="*/30 * * * *"
        focusBorderColor={isInvalid ? 'none' : state ? 'success' : undefined}
      />
      <Flex>
        <Link target="_blank" href="https://crontab.guru/#*/30_*_*_*_*">
          How to cron
        </Link>
      </Flex>
    </FormControl>
  )
}

export default ScheduleConfig
