import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React from 'react'

type ErrorsModalProps = { errors: string[] } & BaseModalComponentProps
export const ErrorsModal: React.FC<ErrorsModalProps> = ({
  onClose,
  errors,
}) => {
  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{'Errors'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errors.map((e, index) => (
            <Text
              py="2"
              borderBottom="1px solid"
              borderColor="gray.200"
              key={index}
            >
              {e}
            </Text>
          ))}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
