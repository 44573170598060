import {
  Box,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import useSWR from 'swr'

import Loader from '../../../Loader'

type Props = { organizationUid: string }
type Response = {
  weekNumber: number
  agents: number
  startDate: Date
  endDate: Date
}

const dateTimeFormat = new Intl.DateTimeFormat('da', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
})

const formatRange = (startDate: Date, endDate: Date): string =>
  `${dateTimeFormat.format(startDate)} - ${dateTimeFormat.format(endDate)}`

const formatNumber = new Intl.NumberFormat('da-DK', {
  maximumFractionDigits: 2,
})
const numberFormat = (number: number): string => formatNumber.format(number)

const AgentsPerWeek: React.FC<Props> = ({ organizationUid }) => {
  const { data, error } = useSWR<Response[], Error>(
    `superpowers/organization/${organizationUid}/insights/agents-with-conversations-per-week`,
    { suspense: false },
  )

  if (error) {
    return <Text color="danger">{error.message}</Text>
  }

  if (!data)
    return (
      <Box my="6">
        <Loader />
      </Box>
    )

  const renderHeader = (d: Response): ReactElement => {
    const { startDate, endDate, weekNumber } = d
    return (
      <Th key={weekNumber}>
        <Tooltip shouldWrapChildren label={formatRange(startDate, endDate)}>
          {weekNumber}
        </Tooltip>
      </Th>
    )
  }

  return (
    <Box mt="20">
      <Text fontSize="xl">Agents per Week</Text>
      <Text color="subtitle">
        Unique agents we have imported conversations for per week
      </Text>
      <Table>
        <Thead>
          <Tr>
            <Th>Week</Th>
            {data.map(renderHeader)}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Th as="td">Agents</Th>
            {data.map((d) => (
              <Td key={d.weekNumber}>
                <Tooltip
                  shouldWrapChildren
                  label={formatRange(d.startDate, d.endDate)}
                >
                  {d.agents}
                </Tooltip>
              </Td>
            ))}
          </Tr>
        </Tbody>
        <TableCaption>{`Average agents per week the last ${
          data.length
        } weeks: ${numberFormat(calculateAverage(data))}`}</TableCaption>
      </Table>
    </Box>
  )
}

const calculateAverage = (data: Response[]): number => {
  const numberOfWeeks = data.length
  const totalAgents = data.reduce((memo, { agents }) => agents + memo, 0)
  return totalAgents / numberOfWeeks
}

export default AgentsPerWeek
