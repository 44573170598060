import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { isWeekend } from 'date-fns'
import React, { useState } from 'react'
import useSWR from 'swr'

type Props = { organizationUid: string }
type Response = {
  dayOfYear: number
  agents: number
  startDate: Date
  endDate: Date
}

const dateTimeFormat = new Intl.DateTimeFormat('da', {
  month: 'short',
  day: 'numeric',
  weekday: 'long',
})

const formatNumber = new Intl.NumberFormat('da-DK', {
  maximumFractionDigits: 2,
})
const numberFormat = (number: number): string => formatNumber.format(number)

const AgentsPerDay: React.FC<Props> = ({ organizationUid }) => {
  const [daysToLookBack, setDaysToLookBack] = useState<number>(0)

  const { data, error } = useSWR<Response[], Error>(
    daysToLookBack === 0
      ? null
      : `superpowers/organization/${organizationUid}/insights/agents-with-conversations-per-day?daysToLookBack=${daysToLookBack}`,
    { suspense: false },
  )
  const [hideWeekends, setHideWeekends] = useState<boolean>(false)
  if (error) {
    return <Text color="danger">{error.message}</Text>
  }

  const days = (data || []).reduce<
    { dayOfYear: number; agents: number; date: Date }[]
  >((memo, curr) => {
    const date = curr.startDate

    if (hideWeekends && isWeekend(date)) {
      return memo
    }

    memo.push({ agents: curr.agents, date: date, dayOfYear: curr.dayOfYear })
    return memo
  }, [])

  const totalAgents = days.reduce((memo, { agents }) => agents + memo, 0)
  const numberOfDays = days.length

  return (
    <Box mt="20">
      <Flex width="100%" justifyContent="space-between">
        <div>
          <Text fontSize="xl">Agents per Day</Text>
          <Text color="subtitle">
            Unique agents we have imported conversations for per day
          </Text>
        </div>
        <Flex alignItems="center">
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="hide-weekends" mb="0">
              Hide weekends
            </FormLabel>
            <Switch
              id="hide-weekends"
              onChange={(e) => setHideWeekends(e.currentTarget.checked)}
              isChecked={hideWeekends}
            />
          </FormControl>
          <NumberInput
            title="Days to look back"
            size="xs"
            width="32"
            min={1}
            mr="4"
            onChange={(e) => {
              setDaysToLookBack(Number(e))
            }}
            value={daysToLookBack}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </Flex>

      {daysToLookBack === 0 ? (
        <Text fontSize="medium" my="5" align="center">
          Select days to look back to see some data
        </Text>
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Day</Th>
              <Th>Unique agents</Th>
            </Tr>
          </Thead>
          <Tbody>
            {days.map((d) => (
              <Tr key={d.dayOfYear}>
                <Th as="td">{dateTimeFormat.format(d.date)}</Th>
                <Td>{d.agents}</Td>
              </Tr>
            ))}
          </Tbody>
          <TableCaption>
            {`Average unique agents per day the last ${
              days.length
            } days: ${numberFormat(totalAgents / numberOfDays)}.`}
            <br />
            {`Total unique agents the last ${days.length} days: ${numberFormat(
              totalAgents,
            )}`}
          </TableCaption>
        </Table>
      )}
    </Box>
  )
}

export default AgentsPerDay
