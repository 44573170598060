import { Report } from '@capturi/api-systemadmin'
import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { apiVersion, baseUrl, cacheKey } from '../constants'

export type ReportResponse = {
  reports: Report[]
}

export const useReports = (
  organizationUid: string | undefined,
): UseQueryResult<Report[], ResponseError> =>
  useQuery({
    queryKey: [cacheKey, 'organisation', organizationUid],
    queryFn: () =>
      request.get<Report[]>(`${baseUrl}/${organizationUid}/list/${apiVersion}`),
    enabled: !!organizationUid,
  })
