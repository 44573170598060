import request, { getErrorMessage } from '@capturi/request'
import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import noop from 'lodash/noop'
import React, { useState } from 'react'

type ReprocessConversationsProps = {
  userName: string
  userUid: string
  onSubmit: (count: number) => void
} & Omit<BaseModalComponentProps<void>, 'onSubmit'>

const ReprocessConversations: React.FC<ReprocessConversationsProps> = ({
  userName,
  userUid,
  onClose,
  onSubmit,
}) => {
  const [fromDate, setFromDate] = useState<string>('')
  const [toDate, setToDate] = useState<string>('')

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const queryKey = [
    `superpowers/asr/speaker-model/${userUid}/reprocess-conversations/${fromDate}/${toDate}`,
  ]
  const { data, isSuccess, isLoading } = useQuery<{
    numConversations: number
  }>({
    queryKey,
    queryFn: () =>
      request.post(
        `superpowers/asr/speaker-model/${userUid}/reprocess-conversations`,
        {
          json: {
            fromDate: new Date(fromDate),
            toDate: new Date(toDate),
          },
        },
      ),
    enabled: !!fromDate && !!toDate,
  })

  const handleSave = async (): Promise<void> => {
    try {
      setInProgress(true)

      const response = await request.post<{ numConversations: number }>(
        `superpowers/asr/speaker-model/${userUid}/reprocess-conversations`,
        {
          json: {
            fromDate: new Date(fromDate),
            toDate: new Date(toDate),
            dryRun: false,
          },
        },
      )

      onSubmit?.(response.numConversations)
    } catch (error) {
      const msg = getErrorMessage(error) || 'Unknown error'
      setErrorMsg(msg)
      setInProgress(false)
    }
  }

  return (
    <Modal isOpen onClose={inProgress ? noop : onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Update Speaker model for ${userName}`}</ModalHeader>
        <ModalCloseButton isDisabled={inProgress} />
        <ModalBody>
          <FormControl mt="2">
            <FormLabel mb="0" ml="1" htmlFor="name">
              From
            </FormLabel>
            <Input
              isDisabled={inProgress}
              id="from"
              type="datetime-local"
              value={fromDate}
              onChange={(event) => setFromDate(event.currentTarget.value)}
              autoComplete="none"
            />
          </FormControl>
          <FormControl mt="2">
            <FormLabel mb="0" ml="1" htmlFor="email">
              To
            </FormLabel>
            <Input
              isDisabled={inProgress}
              id="to"
              type="datetime-local"
              value={toDate}
              onChange={(event) => setToDate(event.currentTarget.value)}
              autoComplete="none"
            />
          </FormControl>

          {errorMsg && (
            <Text mt="2" textAlign="end" color="danger">
              {errorMsg}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} isDisabled={inProgress}>
            Close
          </Button>
          <Button
            primary
            onClick={handleSave}
            isLoading={inProgress || isLoading}
            isDisabled={!isSuccess}
          >
            Reprocess {data?.numConversations} conversations
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReprocessConversations
