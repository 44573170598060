import { useInput } from '@capturi/react-utils'
import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Textarea,
} from '@chakra-ui/react'
import React from 'react'

import {
  useAddAiQuestion,
  useDeleteAiQuestion,
  useUpdateAiQuestion,
} from './useAiQuestions'

type Props = {
  uid?: string
  language: string
  name: string
  question: string
  onClose: () => void
}

const AskAiQuestion: React.FC<Props> = ({
  name,
  uid,
  question,
  language,
  onClose,
}) => {
  const [state, setState] = useInput({
    language: language,
    name: name,
    question: question,
  })
  const { mutate: addAiQuestion, isPending: isLoadingAdd } = useAddAiQuestion()
  const { mutate: updateAiQuestion, isPending: isLoadingUpdate } =
    useUpdateAiQuestion(uid)
  const { mutate: deleteAiQuestion, isPending: isLoadingDelete } =
    useDeleteAiQuestion(uid)

  const toast = useToast()

  const isLoading = isLoadingAdd || isLoadingUpdate || isLoadingDelete
  const hasChanges = name !== state.name || question !== state.question

  const handleSave = (): void => {
    if (uid) {
      updateAiQuestion(
        {
          language: state.language,
          name: state.name,
          question: state.question,
          uid: uid,
        },
        {
          onSuccess: () => {
            onClose()
          },
        },
      )
    } else {
      addAiQuestion(state, {
        onSuccess: () => {
          onClose()
        },
      })
    }
  }

  const handleDelete = (): void => {
    deleteAiQuestion(undefined, {
      onSuccess: () => {
        onClose()
      },
      onError: (err) => {
        toast({
          title: 'Could not delete prompt',
          status: 'error',
          description: err.message,
        })
      },
    })
  }
  return (
    <Box pl="4">
      <FormControl mb={4}>
        <FormLabel htmlFor="name">Display name</FormLabel>
        <Input
          id="name"
          placeholder="name"
          {...setState.name}
          isDisabled={isLoading}
        />
      </FormControl>
      <FormLabel htmlFor="ui-language">Language</FormLabel>
      <Select
        disabled={isLoading}
        id="ui-language"
        {...setState.language}
        mb={4}
      >
        <option value="da-DK">da-DK</option>
        <option value="en-US">en-US</option>
        <option value="de-DE">de-DE</option>
        <option value="fi-FI">fi-FI</option>
        <option value="sv-SE">sv-SE</option>
        <option value="nb-NO">nb-NO</option>
        <option value="nl-NL">nl-NL</option>
        <option value="pl-PL">pl-PL</option>
      </Select>
      <FormControl>
        <FormLabel htmlFor="question">Question used in prompt</FormLabel>
        <Textarea
          isDisabled={isLoading}
          id="question"
          placeholder="question"
          {...setState.question}
          height="300"
        />
      </FormControl>

      <Flex mt="2" justifyContent="space-between">
        <Button
          onClick={handleDelete}
          colorScheme="red"
          isDisabled={isLoading || !uid}
          isLoading={isLoadingDelete}
        >
          Delete
        </Button>
        <HStack spacing="2" justifyContent="flex-end">
          <Button onClick={onClose}>Cancel</Button>
          <Button
            primary
            isLoading={isLoadingAdd}
            onClick={handleSave}
            isDisabled={!hasChanges || isLoadingDelete}
          >
            Save
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}

export default AskAiQuestion
