import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import React from 'react'

type Props = {
  inProgress: boolean
  setState: (key: string) => void
  state: string
}

const StartDateConfig: React.FC<Props> = ({ inProgress, state, setState }) => {
  return (
    <FormControl>
      <FormLabel htmlFor="startDate">Start date</FormLabel>
      <Input
        type="date"
        id="startDate"
        aria-describedby="start date"
        value={state}
        onChange={(event) => setState(event.currentTarget.value)}
        isDisabled={inProgress}
      />
    </FormControl>
  )
}

export default StartDateConfig
