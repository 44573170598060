import { useInput } from '@capturi/react-utils'
import request from '@capturi/request/src/request'
import { Button, useToast } from '@capturi/ui-components'
import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { lightFormat } from 'date-fns/fp'
import React, { useState } from 'react'

type Props = { organizationUid: string }
const formatDate = lightFormat("yyy-MM-dd'T'HH:mm")

export const BackSyncAi: React.FC<Props> = ({ organizationUid }) => {
  const toast = useToast()
  const [inputs, setInputs] = useInput({
    from: formatDate(new Date()),
    to: formatDate(new Date()),
  })
  const [overrideExisting, setOverrideExisting] = useState(false)
  const { isPending, mutate, error } = useMutation({
    mutationFn: ({
      from,
      organizationUid,
      overrideExisting,
      to,
    }: {
      organizationUid: string
      from: Date
      to: Date
      overrideExisting: boolean
    }) =>
      request.post('systemadmin/organization/generate-ai-insights', {
        searchParams: {
          organizationUid,
          from: from.toJSON(),
          to: to.toJSON(),
          overrideExisting: overrideExisting,
        },
      }),
  })
  const daysToBackSync = Math.round(
    (new Date(inputs.to).getTime() - new Date(inputs.from).getTime()) /
      (1000 * 3600 * 24),
  )

  return (
    <Box>
      <Text fontSize="xl">Back Sync</Text>
      <Text color="danger">
        This is expensive - ask an adult before doing anything
      </Text>
      <Flex>
        <FormControl>
          <FormLabel htmlFor="friendlyName">From date</FormLabel>
          <Input
            size="sm"
            type="datetime-local"
            isDisabled={isPending}
            {...setInputs.from}
          />
        </FormControl>
        <FormControl mx="2">
          <FormLabel htmlFor="friendlyName">To date</FormLabel>
          <Input
            size="sm"
            type="datetime-local"
            isDisabled={isPending}
            {...setInputs.to}
          />
        </FormControl>
      </Flex>
      <Flex justifyContent="end" mt="2">
        <Checkbox
          colorScheme="primary"
          isChecked={overrideExisting}
          onChange={(e) => setOverrideExisting(e.target.checked)}
          isDisabled={isPending}
        >
          Override Existing
        </Checkbox>
        <Button
          isLoading={isPending}
          primary
          mx="2"
          onClick={() => {
            mutate(
              {
                organizationUid: organizationUid,
                from: new Date(inputs.from),
                to: new Date(inputs.to),
                overrideExisting: overrideExisting,
              },
              {
                onSuccess: () => {
                  toast({ status: 'success', title: 'Back sync started' })
                },
              },
            )
          }}
        >
          Back sync {daysToBackSync} days
        </Button>
      </Flex>

      {error && <Text>{error.message}</Text>}
    </Box>
  )
}
