import request from '@capturi/request/src/request'
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { endOfWeek, setWeek } from 'date-fns'
import React, { ReactElement } from 'react'

import Loader from '../../../Loader'

type Props = { organizationUid: string }

type Cell = {
  dayOfWeek: number
  created: number
  date: null | string
  year: number
  week: number
}

type Response = {
  data: Cell[][]
  duration: string
  durationMs: number
}

const getDates = (year: number, week: number): [Date, Date] => {
  const firstDayOfWeek = setWeek(new Date(year, 0, 1), week)
  return [firstDayOfWeek, endOfWeek(firstDayOfWeek)]
}

const numberFormat = (number: number): string =>
  new Intl.NumberFormat('da-DK').format(number)

const formatRange = (date1: Date, date2: Date): string =>
  new Intl.DateTimeFormat('da', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).formatRange(date1, date2)

const ConversationsPerDayV2: React.FC<Props> = ({ organizationUid }) => {
  const { data } = useQuery<Response>({
    queryKey: ['heimdal', organizationUid, 'day-overview'],
    queryFn: () =>
      request.get(`heimdal/${organizationUid}/day-overview
    `),
  })

  if (!data) return <Loader />

  const todayDay = new Date()
  const renderCell = ({ created, date, dayOfWeek }: Cell): ReactElement => {
    const d = date ? new Date(date) : null

    const isToday = dayOfWeek === todayDay.getDay()
    return (
      <Td
        textAlign="center"
        borderBottom={isToday ? 'none' : undefined}
        bg={isToday ? 'primary.200' : undefined}
        key={d?.getTime()}
        title={d?.toLocaleDateString() || undefined}
      >
        {numberFormat(created)}
      </Td>
    )
  }

  const renderHeaderCell = (value: string, day: number): ReactElement => {
    const isToday = day === todayDay.getDay()
    return (
      <Th
        textAlign="center"
        color={isToday ? 'primary.400' : undefined}
        key={day}
      >
        {value}
      </Th>
    )
  }
  const renderRow = (data: Cell[], key: number): ReactElement => {
    const first = data.at(0)
    if (!first) return <Tr key={key} />

    const [startOfWeek, endOfWeek] = getDates(first.year, first.week)
    const tooltipText = formatRange(startOfWeek, endOfWeek)

    return (
      <Tr key={key}>
        <Td>{tooltipText}</Td>
        {data.map(renderCell)}
      </Tr>
    )
  }

  return (
    <Box mt="4">
      <Text m="4" fontSize="2xl">
        Conversations Per day
      </Text>
      {!data ? (
        <Loader />
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Week</Th>
              {[
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ].map(renderHeaderCell)}
            </Tr>
          </Thead>
          <Tbody>{data.data.map(renderRow)}</Tbody>
        </Table>
      )}
    </Box>
  )
}

export default ConversationsPerDayV2
