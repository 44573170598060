import { Box } from '@chakra-ui/react'
import React, { Fragment } from 'react'

import { formatSeconds } from '../../utils'
import { LinkRow, Row, SmartRow, TableCard } from '../components'
import { AsrPriority, Conversation as Data } from '../types'
import ConversationAudio from './ConversationAudio'

type Props = { data: Data }

const ConversationInfo: React.FC<Props> = ({ data }) => {
  const { OrganizationUid, UserUid, IgnoredPhrases, Metrics, ...rest } = data
  const { AudioAdded, ProcessingDone } = Metrics
  const totalProcessingTime =
    ProcessingDone && AudioAdded
      ? (ProcessingDone.getTime() - AudioAdded.getTime()) / 1000
      : null

  return (
    <>
      <TableCard title="Info">
        {LinkRow(
          'OrganizationUid',
          OrganizationUid,
          `organization/${OrganizationUid}`,
        )}
        {LinkRow('UserUid', UserUid, `user?uid=${UserUid}`)}
        {Object.entries(rest).map(([key, val]) => SmartRow(key, val))}
      </TableCard>
      <div>
        <TableCard title="Metrics">
          {Object.entries(Metrics).map(([key, val]) => SmartRow(key, val))}
        </TableCard>

        <TableCard title="Statistics" mt="4">
          {Row('Asr Priority', AsrPriority[data.Options.AsrPriority])}
          {Row(
            'Processing time',
            totalProcessingTime ? formatSeconds(totalProcessingTime) : '',
          )}
          {Row('Duration', data.Duration ? formatSeconds(data.Duration) : '')}
          {Row(
            'Factor',
            data.Duration && totalProcessingTime
              ? (totalProcessingTime / data.Duration).toFixed(2)
              : '',
          )}
        </TableCard>
        <ConversationAudio conversationUid={data._id} />
      </div>
      {!!IgnoredPhrases.length && (
        <TableCard title="Ignored Phrases">
          {IgnoredPhrases.map((v, index) => (
            <Fragment key={index}>
              <Box as="tr" borderBottom="1px solid #ededed">
                <td /> <td />
              </Box>
              {SmartRow(index.toString(), v)}
            </Fragment>
          ))}
        </TableCard>
      )}
    </>
  )
}

export default ConversationInfo
