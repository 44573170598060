import {
  Button,
  IconButton,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { ReactElement, useState } from 'react'
import { MdClose } from 'react-icons/md'

type Props = {
  title: string
  data: [string, string][]
  onRemove: (key: string) => void
  onAdd: (key: string, value: string) => void
  inProgress: boolean
}

const DataTable: React.FC<Props> = ({
  title,
  data,
  onRemove,
  onAdd,
  inProgress,
}) => {
  const [key, setKey] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  const handleAdd = (): void => {
    onAdd(key, comment)
    setKey('')
    setComment('')
  }

  const renderRow = ([key, comment]: [string, string]): ReactElement => {
    return (
      <Tr key={key}>
        <Td>{key}</Td>
        <Td>{comment}</Td>
        <Td>
          <IconButton
            aria-label="remove"
            variant="ghost"
            isRound
            icon={<MdClose />}
            fontSize="lg"
            size="xs"
            colorScheme="red"
            isDisabled={inProgress}
            onClick={() => onRemove(key)}
          />
        </Td>
      </Tr>
    )
  }

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>{title}</Th>
          <Th>Comment</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {data.map(renderRow)}
        <Tr>
          <Td>
            <Input
              onChange={(e) => setKey(e.target.value)}
              value={key}
              variant="unstyled"
              placeholder={title}
              isDisabled={inProgress}
            />
          </Td>
          <Td>
            <Input
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              variant="unstyled"
              placeholder="Comment"
              isDisabled={inProgress}
            />
          </Td>
          <Td>
            <Button
              isDisabled={inProgress || !(key && comment)}
              // isLoading={inProgress}
              colorScheme="primary"
              onClick={handleAdd}
            >
              Add
            </Button>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  )
}

export default DataTable
