import { Button } from '@capturi/ui-components'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'

import { Integrations, useIntegrations } from './useIntegrations'

const compare = new Intl.Collator().compare

const renderIntegration = (i: Integrations): ReactElement => {
  const { integrationType, orgs } = i
  const active = orgs
    .filter((o) => o.active)
    .sort((a, b) => compare(a.name, b.name))
  const inactive = orgs
    .filter((o) => !o.active)
    .sort((a, b) => compare(a.name, b.name))

  const hasActive = !!active.length
  const hasInactive = !!inactive.length

  return (
    <Box py="10" borderBottom="1px solid #eaedef">
      <Text fontSize="2xl">{`${integrationType.toUpperCase()} (${
        active.length
      })`}</Text>
      <Box ml="4">
        {hasActive && (
          <>
            <Text fontSize="large" color="#4b4b4b">
              Active
            </Text>
            <Box borderLeft="#2e96d0 4px solid" p="4" m="2">
              {active.map((a) => (
                <div key={a.integrationKey}>
                  <Link to={`/superpowers/organization/${a.organizationUid}`}>
                    {a.name}
                  </Link>
                </div>
              ))}
            </Box>
          </>
        )}
        {hasInactive && (
          <>
            <Text mt="4" fontSize="large" color="#7f7f7f">
              Inactive
            </Text>
            <Box borderLeft="#e0e0e0 4px solid" p="4" m="2">
              <Box>
                {inactive.map((a) => (
                  <div key={a.integrationKey}>
                    <Link to={`/superpowers/organization/${a.organizationUid}`}>
                      {a.name}
                    </Link>
                  </div>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

const IntegrationsOverview: React.FC = () => {
  const intergation = useIntegrations()
  const [, copy] = useCopyToClipboard()
  const [color, setColor] = useState<string | undefined>(undefined)

  const copyIntegrationList = (): void => {
    setColor('green')
    copy(
      intergation
        .map((i) => i.integrationType)
        .sort(compare)
        .join('\n'),
    )
  }

  return (
    <div>
      <Flex justifyContent="flex-end">
        <Button color={color} variant="ghost" onClick={copyIntegrationList}>
          Copy Integrations List
        </Button>
      </Flex>
      <div>{intergation.map(renderIntegration)}</div>
    </div>
  )
}

export default IntegrationsOverview
