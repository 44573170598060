import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import type { ConfigField } from '../IntegrationConfigSchemaType'

export const useAudioImporterAuthSchema = (
  integrationType: string,
): UseQueryResult<{ fields: ConfigField[] }, ResponseError> =>
  useQuery({
    queryKey: ['importers', 'audio', 'schema', 'auth', integrationType],
    queryFn: () => request.get(`importer/${integrationType}/auth-schema`),
  })
