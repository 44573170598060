import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { formatDistanceToNow } from 'date-fns'
import React, { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatSeconds } from '../../../utils'
import type { ConversationResponse } from './useConversations'

type Props = { fieldsToShow: string[]; data: ConversationResponse[] }

const ConversationsTable: React.FC<Props> = ({ fieldsToShow, data }) => {
  const navigate = useNavigate()

  const renderRow = (
    row: ConversationResponse,
    index: number,
  ): ReactElement => {
    const { metrics, uid, duration } = row
    const processingTimeInSeconds =
      metrics.audioAdded instanceof Date &&
      metrics.processingDone instanceof Date
        ? (metrics.processingDone.getTime() - metrics.audioAdded.getTime()) /
          1000
        : undefined

    return (
      <Tr
        cursor="pointer"
        key={index}
        onClick={() => navigate(`/superpowers/conversation?&uid=${uid}`)}
      >
        {fieldsToShow.map((f) => {
          const val = row[f]

          if (val instanceof Date)
            return (
              <Td key={f} whiteSpace="nowrap">
                {val.toLocaleString('da-DK')}
              </Td>
            )
          if (Array.isArray(val))
            return (
              <Td key={f} whiteSpace="nowrap">
                [...]
              </Td>
            )
          if (val == null)
            return (
              <Td key={f} whiteSpace="nowrap">
                null
              </Td>
            )
          if (typeof val === 'object')
            return (
              <Td key={f} whiteSpace="nowrap">
                {'{...}'}
              </Td>
            )
          if (typeof val === 'string' || typeof val === 'number')
            return (
              <Td key={f} whiteSpace="nowrap">
                {val}
              </Td>
            )
        })}

        <Td title={metrics.created.toLocaleString()}>
          {formatDistanceToNow(metrics.created, { addSuffix: true })}
        </Td>
        <Td
          title={
            processingTimeInSeconds
              ? `Processing time: ${formatSeconds(processingTimeInSeconds)}`
              : undefined
          }
        >
          {processingTimeInSeconds
            ? (processingTimeInSeconds / duration).toFixed(2)
            : ''}
        </Td>
      </Tr>
    )
  }

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          {fieldsToShow.map((f) => (
            <Th whiteSpace="nowrap" key={f}>
              {f}
            </Th>
          ))}

          <Th>Created</Th>
          <Th>Factor</Th>
        </Tr>
      </Thead>
      <Tbody>{data.map(renderRow)}</Tbody>
    </Table>
  )
}

export default ConversationsTable
