import { Button } from '@capturi/ui-components'
import { Flex, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import PhrasesEditor from '../../../components/PhrasesEditor'
import {
  useSystemSettings,
  useUpdateSystemSettings,
} from '../../useSystemSettings'

type Props = { organizationUid: string }
const NGramsEditor: React.FC<Props> = ({ organizationUid }) => {
  const {
    data: systemSettings,
    isLoading,
    isSuccess,
    error,
  } = useSystemSettings(organizationUid)
  const { mutate: updateSystemSettings } =
    useUpdateSystemSettings(organizationUid)
  const toast = useToast()

  const [nGrams, setNGrams] = useState<string[]>(
    systemSettings?.wordCloud ? systemSettings?.wordCloud.blacklistNgrams : [],
  )

  const handleSave = () => {
    updateSystemSettings(
      { wordCloud: { blacklistNgrams: nGrams } },
      {
        onSuccess: () => {
          toast({
            title: 'Successfully updated blacklisted nGrams',
            status: 'success',
          })
        },
        onError: (error) => {
          toast({
            title: 'Failed to update blacklisted nGrams',
            status: 'error',
            description: error.message,
          })
        },
      },
    )
  }

  useEffect(() => {
    if (systemSettings?.wordCloud) {
      setNGrams(systemSettings.wordCloud.blacklistNgrams)
    }
  }, [systemSettings])

  if (systemSettings) {
    return (
      <>
        <PhrasesEditor
          state={nGrams}
          onChange={setNGrams}
          isLoading={isLoading}
          isSuccess={isSuccess}
          title="Blacklisted nGrams"
          placeholder="nGrams"
        />
        <Flex mt="4" justifyContent="space-between">
          <Button
            colorScheme="red"
            onClick={() => setNGrams([])}
            isDisabled={isLoading || nGrams.length === 0}
          >
            Clear
          </Button>

          <Button primary onClick={handleSave}>
            Save nGrams
          </Button>
        </Flex>
        {error && <Text>{error.message}</Text>}
      </>
    )
  }
  return null
}

export default NGramsEditor
