import { Heading, HeadingProps } from '@chakra-ui/react'
import React from 'react'

export const noop = (): void => {}

export const Title: React.FC<HeadingProps> = (props) => (
  <Heading as="h1" fontSize="2xl" fontWeight="normal" {...props} />
)
export const Subtitle: React.FC<HeadingProps> = (props) => (
  <Heading
    as="h2"
    fontSize="organizationNamexl"
    fontWeight="normal"
    {...props}
  />
)

const padTokens = (x: number): string => x.toString().padStart(2, '0')

export const formatSeconds = (time = 0): string => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor(time / 60) % 60
  const seconds = Math.floor(time) % 60

  if (hours === 0) return `${padTokens(minutes)}m ${padTokens(seconds)}s`
  return `${padTokens(hours)}h ${padTokens(minutes)}m ${padTokens(seconds)}s`
}
