import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  ModalCloseButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, {} from 'react'
import { useScimLogs } from './useScimLogs'

type Props = { organizationUid: string } & BaseModalComponentProps

const ViewLogsModal: React.FC<Props> = ({ onClose, organizationUid }) => {
  const { data, isFetching, error, refetch, dataUpdatedAt } =
    useScimLogs(organizationUid)

  return (
    <Modal isOpen onClose={onClose} size="full">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Button isLoading={isFetching} onClick={() => refetch()}>
              Refresh
            </Button>
            <Text>
              Last refresh: {new Date(dataUpdatedAt).toLocaleString()}
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <TableContainer>
              <Table variant="simple" whiteSpace="pre-wrap">
                <Thead>
                  <Tr>
                    <Th>Level</Th>
                    <Th>Action</Th>
                    <Th>Target</Th>
                    <Th>Date</Th>
                    <Th>Text</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {error && <Text color="red">{error.message}</Text>}
                  {data?.map((v) => (
                    <Tr key={v.ID}>
                      <Td>{v.Level}</Td>
                      <Td>{v.Action}</Td>
                      <Td>{v.Target}</Td>
                      <Td>{v.CreatedAt.toLocaleString()}</Td>
                      <Td>{v.Text}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default ViewLogsModal
