import { Flex, Input, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Info from './Info'

const guidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}/

const User: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const uid = searchParams.get('uid')
  const [term, setTerm] = useState<string>(uid || '')
  const isValidGuid = guidRegex.test(term)

  useEffect(() => {
    if (isValidGuid) {
      const userUid = guidRegex.exec(term)?.[0]
      if (userUid && userUid !== uid) {
        setTerm(userUid)
        setSearchParams({
          uid: userUid,
        })
      }
    }
    if (term === '') {
      setSearchParams({
        uid: '',
      })
    }
  }, [isValidGuid, term, setSearchParams, uid])

  return (
    <>
      <Flex
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mb="4"
      >
        <Text fontSize="xl" mb="2">
          User Uid
        </Text>
        <Input
          textAlign="center"
          borderRadius="100px"
          w="350px"
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          onChange={(e) => setTerm(e.target.value.toLowerCase())}
          value={term}
          onFocus={(e) => e.target.select()}
        />
      </Flex>
      {isValidGuid && uid && <Info userUid={uid} />}
    </>
  )
}

export default User
