import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

import { User } from '../useUsers'
import EditUser from './EditUser'

type Props = {
  user: User | null
  onClose: () => void
}

const EditUserDrawer: React.FC<Props> = ({ user, onClose }) => {
  return (
    <Drawer isOpen={!!user} onClose={onClose} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          borderBottom="1px"
          borderBottomColor="gray.200"
          lineHeight={1.4}
          display="flex"
          alignItems="center"
          pr={10}
        >
          Edit user
        </DrawerHeader>
        <DrawerBody>
          {user && <EditUser user={user} onClose={onClose} />}
        </DrawerBody>
        <DrawerFooter>
          <Link to={`/superpowers/user?uid=${user?.id}`}>
            Go to users conversations
          </Link>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default EditUserDrawer
