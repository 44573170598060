import { IconButton, type IconButtonProps } from '@chakra-ui/react'
import React from 'react'
import { MdRefresh } from 'react-icons/md'

export const RefreshButton: React.FC<
  {
    refresh: () => void
  } & Omit<IconButtonProps, 'aria-label'>
> = ({ refresh, ...restProps }) => {
  return (
    <IconButton
      icon={<MdRefresh />}
      onClick={refresh}
      aria-label={'refresh'}
      variant="ghost"
      {...restProps}
    />
  )
}
