import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import React, {} from 'react'

type Props = { token: string } & BaseModalComponentProps

const ViewLogLinkModal: React.FC<Props> = ({ onClose, token }) => {
  const toast = useToast()

  const copyTokenToClipboard = (): void => {
    navigator.clipboard.writeText(link)
    toast({ title: 'Copied token to clipboard', status: 'info' })
  }
  const link = `https://scim.capturi.ai/monitoring/${token}`
  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Log link</ModalHeader>

          <ModalBody>
            <Text
              onClick={(e) => e.currentTarget.focus()}
              mt="2"
              p="2"
              bg="gray.100"
              borderRadius="md"
              userSelect="all"
              fontFamily="mono"
            >
              {link}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" color="blue" onClick={copyTokenToClipboard}>
              Copy
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default ViewLogLinkModal
