import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

type Payload = {
  createdByIntegration: boolean
  name: string
  externalUid: string | null
  uid: string
}

export type Team = {
  uid: string
  name: string
  externalUid?: string | null
  createdByIntegration: boolean
  integrationType?: string
  users?: {
    name: string
    email: string
    externlaUids?: string[]
    uid: string
  }[]
}

export const useTeams = (
  organizationUid: string,
): UseQueryResult<Team[], ResponseError> =>
  useQuery({
    queryKey: ['superpowers', organizationUid, 'teams'],
    queryFn: async () => {
      const result = await request.get<{ comments: Comment[] }>(
        `authentication/superpowers/organization/${organizationUid}/teams`,
      )
      return result
    },
  })

export const useUpdateTeam = (
  organizationUid: string,
): UseMutationResult<Payload, ResponseError, Payload> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ createdByIntegration, externalUid, name, uid }: Payload) =>
      request.put(
        `authentication/superpowers/organization/${organizationUid}/teams/${uid}`,
        {
          json: {
            createdByIntegration,
            name,
            externalUid: externalUid || null,
          },
        },
      ),
    onSuccess: (result) => {
      queryClient.setQueryData<Team[]>(
        ['superpowers', organizationUid, 'teams'],
        (oldData) => {
          if (!oldData) return oldData
          return oldData.map((o) =>
            o.uid === result.uid ? { ...o, ...result } : o,
          )
        },
      )
    },
  })
}
