import { useSet } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import { Flex } from '@chakra-ui/react'
import React, { type ReactElement } from 'react'

type Props = {
  fields: string[]
  selectedFields: string[]
  setSelectedFields: React.Dispatch<React.SetStateAction<string[]>>
}

const SelectFields: React.FC<Props> = ({
  fields,
  selectedFields,
  setSelectedFields,
}) => {
  const s = useSet(selectedFields)

  const handleSelect = (field: string): void => {
    if (s.has(field)) {
      s.delete(field)

      setSelectedFields((s) => s.filter((f) => f !== field))
    } else {
      s.add(field)
      setSelectedFields((s) => [field, ...s])
    }
  }

  const renderButton = (field: string): ReactElement => {
    return (
      <Button
        borderRadius="100px"
        m="1"
        size="xs"
        key={field}
        primary={s.has(field)}
        onClick={() => handleSelect(field)}
      >
        {field}
      </Button>
    )
  }

  return (
    <Flex wrap={'wrap'}>
      <Button
        borderRadius="100px"
        m="1"
        size="xs"
        primary={selectedFields.length === fields.length}
        onClick={() => {
          if (selectedFields.length === fields.length) {
            setSelectedFields(['dateTime', 'subject'])
            s.clear()
            s.add('dateTime')
            s.add('subject')
          } else {
            setSelectedFields(fields)
            fields.forEach((f) => s.add(f))
          }
        }}
      >
        ALL
      </Button>
      {fields.map(renderButton)}
    </Flex>
  )
}

export default SelectFields
