import { FieldOption } from '../../../integrations'

export const optionsToFields = (
  config: FieldOption[],
  values: Record<string, string[] | boolean | number | string>,
): Record<string, string[] | boolean | number | string | undefined> => {
  return config.reduce<
    Record<string, string[] | number | boolean | string | undefined>
  >((memo, c) => {
    if (c.type === 'list') {
      memo[c.key] = String(values[c.key] || '')
        .split(',')
        .map((v) => v.trim())
        .filter(Boolean)
    } else if (c.type === 'boolean') {
      memo[c.key] = values[c.key] || c.defaultValue
    } else if (c.type === 'number') {
      memo[c.key] = Number(values[c.key]) || c.defaultValue
    } else {
      memo[c.key] = String(values[c.key] || '').trim()
    }

    return memo
  }, {})
}
