import { StandardReport } from '@capturi/api-systemadmin'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { apiVersion } from '../constants'

const baseUrl = 'systemadmin/metabase/standard-reports'

type ToggleReport = {
  reportUid: string
  organizationUid: string
  isChecked: boolean
}

const cacheKey = 'standard-reports'

export const useStandardReports = (
  organizationUid: string | undefined,
): UseQueryResult<StandardReport[], ResponseError> =>
  useQuery({
    queryKey: [cacheKey, organizationUid],
    queryFn: async () => {
      const result = await request.get<{ reports: StandardReport[] }>(
        `${baseUrl}/list/${organizationUid}${apiVersion}`,
      )
      return result.reports
    },
  })

export const useStandardReportToggle = (
  reportUid: string,
  organizationUid: string,
): UseMutationResult<
  StandardReport,
  ResponseError,
  ToggleReport,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ reportUid, organizationUid, isChecked }: ToggleReport) => {
      return request.post<StandardReport>(
        `${baseUrl}/${reportUid}/toggle/${organizationUid}/?enable=${isChecked}&api-version=1`,
      )
    },
    onSuccess: () => {
      queryClient.setQueryData<StandardReport[]>(
        [cacheKey, organizationUid],
        (oldData) => {
          if (!oldData) {
            return oldData
          }
          const updatedReport = oldData?.find((r) => r.uid === reportUid)
          const updatedReports = oldData?.reduce<StandardReport[]>(
            (acc, report) => {
              if (report.uid === updatedReport?.uid) {
                let currentState = updatedReport?.enabled
                currentState = !currentState
                acc.push({ ...report, enabled: currentState })
              } else {
                acc.push(report)
              }
              return acc
            },
            [],
          )

          return updatedReports
        },
      )
    },
  })
}
