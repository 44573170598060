import { GlobalReport } from '@capturi/api-systemadmin'
import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { apiVersion } from '../constants'
import { GlobalReportResponse } from './useGlobalReports'

export type UpdateReportPayload = {
  reportUid: string
  report: Partial<GlobalReport>
}

export type DeleteReportPlayload = {
  reportUid: string
}

export type CreateReportPayload = {
  report: Partial<Omit<GlobalReport, 'uid'>>
}

const baseUrl = 'systemadmin/metabase/standard-reports'
const cacheKey = 'global-reports'

export const useUpdateGlobalReport = (): UseMutationResult<
  GlobalReport,
  ResponseError,
  UpdateReportPayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: ({ reportUid, report }) => {
      return request.patch<GlobalReport>(
        `${baseUrl}/${reportUid}${apiVersion}`,
        {
          json: report,
        },
      )
    },
    onSuccess: (newReport) => {
      queryClient.setQueryData<GlobalReportResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { reports: [newReport] }
        }
        return {
          reports: oldData.reports.map((f) =>
            f.uid === newReport.uid ? newReport : f,
          ),
        }
      })
      queryClient.setQueryData<GlobalReportResponse>(
        [cacheKey, 'report', newReport.uid],
        () => {
          return { reports: [newReport] }
        },
      )
    },
  })
}

export const useDeleteGlobalReport = (): UseMutationResult<
  GlobalReport,
  ResponseError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: (reportUid) =>
      request.delete<GlobalReport>(`${baseUrl}/${reportUid}${apiVersion}`),
    onSuccess: (_, reportUid) => {
      queryClient.setQueryData<GlobalReportResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { reports: [] }
        }
        return {
          reports: oldData.reports.filter((f) => f.uid !== reportUid),
        }
      })
      queryClient.removeQueries({
        queryKey: [cacheKey, 'report', reportUid],
      })
    },
  })
}

export const useCreateGlobalReport = (): UseMutationResult<
  GlobalReport,
  ResponseError,
  CreateReportPayload
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [cacheKey],
    mutationFn: ({ report }: CreateReportPayload) =>
      request.post<GlobalReport>(`${baseUrl}`, {
        json: report,
      }),
    onSuccess: (newReport) => {
      queryClient.setQueryData<GlobalReportResponse>([cacheKey], (oldData) => {
        if (!oldData) {
          return { reports: [newReport] }
        }
        return { reports: [newReport, ...oldData.reports] }
      })
    },
  })
}
