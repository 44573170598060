import React from 'react'

import { SmartRow, TableCard } from './components'
import type { User as Data } from './types'

type Props = { data: Data }

const UserCard: React.FC<Props> = ({ data }) => {
  const { provider, ...rest } = data

  return (
    <>
      <TableCard title="User">
        {Object.entries(rest).map(([key, val]) => SmartRow(key, val))}
      </TableCard>
      {provider && (
        <TableCard title="Provider">
          {Object.entries(provider).map(([key, val]) => SmartRow(key, val))}
        </TableCard>
      )}
    </>
  )
}

export default UserCard
