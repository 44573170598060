import { AuthService } from '@capturi/auth'
import { useInput } from '@capturi/react-utils'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useActionToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import { addHours } from 'date-fns'
import React from 'react'

import { useAddAccessUser } from '../useUsers'

type Props = {
  name: string
  title: string
  email: string
  organizationUid: string
} & BaseModalComponentProps

const AccessModal: React.FC<Props> = ({
  onClose,
  email,
  name,
  title,
  organizationUid,
}) => {
  const { mutate, isPending: inProgress } = useAddAccessUser()
  const actionToast = useActionToast()
  const toast = useToast()
  const [state, setState] = useInput({
    email,
    name,
    title,
    ttl: '2',
    reason: '',
  })

  const handleGetAccess = (goToNew = false): void => {
    const { email, name, reason, title, ttl } = state

    const ttlDate = ttl === 'never' ? null : addHours(new Date(), Number(ttl))
    mutate(
      {
        organizationUid,
        payload: {
          email,
          name,
          reason,
          title,
          ttl: ttlDate,
        },
      },
      {
        onError: (error) => {
          toast({
            status: 'error',
            title: 'Could not grant access',
            description: error.message,
          })
        },
        onSuccess: () => {
          if (goToNew) {
            AuthService.changeOrganization(organizationUid)
          } else {
            actionToast({
              status: 'success',
              title: 'Access Granted',
              actionText: 'Go to Org',
              onActionClick: () =>
                AuthService.changeOrganization(organizationUid),
            })
            onClose()
          }
        },
      },
    )
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Get Access to Organization</ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel htmlFor="title">Title</FormLabel>
              <Input
                type="text"
                id="title"
                aria-describedby="user title"
                isDisabled={inProgress}
                {...setState.title}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="name">Name</FormLabel>
              <Input
                type="text"
                id="name"
                aria-describedby="user name"
                isDisabled={inProgress}
                {...setState.name}
              />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor="mail">Email</FormLabel>
              <Input
                type="text"
                id="mail"
                aria-describedby="mail"
                isDisabled={inProgress}
                {...setState.email}
              />
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor="ttl">Auto Delete User In</FormLabel>
              <Select {...setState.ttl}>
                <option value="2">2 hours</option>
                <option value="12">12 hours</option>
                <option value="168">1 week</option>
                <option value="336">2 weeks</option>
                <option value="672">1 month</option>
                <option value="2016">3 months</option>
                <option value="never">Never</option>
              </Select>
            </FormControl>
            <FormControl mt="2">
              <FormLabel htmlFor="reason">
                Reason for access (Visible for customer)
              </FormLabel>
              <Textarea
                id="reason"
                aria-describedby="reason"
                isDisabled={inProgress}
                {...setState.reason}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button ml="4" onClick={onClose}>
              Cancel
            </Button>
            <Button ml="2" onClick={() => handleGetAccess(false)}>
              Create Access
            </Button>
            <Button ml="2" primary onClick={() => handleGetAccess(true)}>
              {"Create'n Go"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default AccessModal
