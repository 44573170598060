import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import { Box, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React, { ReactElement, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Loader from '../Loader'
import { useOrganizations } from '../Organization/useOrganizations'

type OverviewResponse = {
  _id: string
  orgs: Array<string>
  names: Array<string>
  emails: Array<string>
  owner: {
    id: string
    displayName: string
    email: string
  }
}

type Item = {
  orgs: {
    uid: string
    name: string
  }[]
  _id: string
  names: Array<string>
  emails: Array<string>
  owner: {
    id: string
    displayName: string
    email: string
  }
}

const sort = Intl.Collator('da-DK').compare

const AccessOverview: React.FC = () => {
  const { data: orgList = [] } = useOrganizations()
  const { data, isLoading, error } = useQuery<
    OverviewResponse[],
    ResponseError
  >({
    queryFn: () => request.get('authentication/superpowers/access-overview'),
    queryKey: ['superpowers', 'access', 'overview'],
  })

  const list = useMemo(() => {
    if (!data) return []

    const orgNames = new Map(orgList.map((o) => [o.uid, o.name]))
    return data?.map((d) => {
      return {
        ...d,
        orgs: d.orgs
          .map((o) => ({ uid: o, name: orgNames.get(o) || o }))
          .sort((a, b) => sort(a.name, b.name)),
      }
    })
  }, [data, orgList])

  const renderItem = ({ owner, emails, orgs, names }: Item): ReactElement => {
    return (
      <Box my="4">
        <Text fontSize="xl">
          {owner.displayName} - {owner.email} ({orgs.length})
        </Text>
        <Text color="gray.700" mb="2">
          {names.sort(sort).join(', ')} | {emails.sort(sort).join(', ')}
        </Text>
        {orgs.map((o) => (
          <Box
            key={o.uid}
            _hover={{ color: 'primary.500' }}
            ml="4"
            width="fit-content"
          >
            <Link to={`/superpowers/organization/${o.uid}/access`}>
              {o.name}
            </Link>
          </Box>
        ))}
      </Box>
    )
  }

  if (error)
    return (
      <Text textAlign="center" mt="33%" fontSize="xl" color="red.800">
        {error.error}
      </Text>
    )
  if (isLoading) return <Loader />

  return list.map(renderItem)
}

export default AccessOverview
