import request, { ResponseError } from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { Box, Flex, Switch } from '@chakra-ui/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { useParams } from 'react-router-dom'

import Loader from '../../../Loader'
import { Title } from '../../../utils'
import DataTable from './DataTable'
import Scim from './SCIM'

type Response = {
  ipWhitelist: { [ip: string]: string }
  domainWhitelist: { [domain: string]: string }
  enableDomainWhitelist: boolean
  enableIpWhitelist: boolean
}

const AuthSettings: React.FC = () => {
  const { uid: organizationUid } = useParams()

  const url = `superpowers/organization/${organizationUid}/auth-settings/`
  const { data, error } = useQuery<Response, ResponseError>({
    queryKey: [url],
  })
  const queryClient = useQueryClient()
  const toast = useToast()

  const mutate = useMutation<Response, ResponseError, Response>({
    mutationFn: (model) =>
      request.put(url, {
        json: model,
      }),
    onMutate: async (model) => {
      await queryClient.cancelQueries({
        queryKey: [url],
      })
      queryClient.setQueryData<Response>([url], model)
      return
    },
    onSuccess: () => {
      toast({ status: 'success', title: 'Auth settings updated' })
    },
    onError: async (e) => {
      await queryClient.invalidateQueries({
        queryKey: [url],
      })
      toast({
        status: 'error',
        title: 'Oh no, something went wrong',
        description: e.message,
      })
    },
  })

  if (!organizationUid) {
    return <div>Missing organizationUid</div>
  }

  if (error) {
    return <div>{error.message}</div>
  }

  if (!data) {
    return <Loader />
  }
  const domainWhitelist = Object.entries(data.domainWhitelist)
  const ipWhitelist = Object.entries(data.ipWhitelist)

  const handleAddDomain = (key: string, value: string): void => {
    const newSettings = data
    newSettings.domainWhitelist[key] = value

    mutate.mutate(newSettings)
  }
  const handleRemoveDomain = (key: string): void => {
    const newSettings = data
    delete newSettings.domainWhitelist[key]
    mutate.mutate(newSettings)
  }
  const handleAddIp = (key: string, value: string): void => {
    const newSettings = data
    newSettings.ipWhitelist[key] = value

    mutate.mutate(newSettings)
  }
  const handleRemoveIp = (key: string): void => {
    const newSettings = data
    delete newSettings.ipWhitelist[key]
    mutate.mutate(newSettings)
  }

  const handleToggleIpWhitelist = (): void => {
    const newSettings = data
    newSettings.enableIpWhitelist = !data.enableIpWhitelist
    mutate.mutate(newSettings)
  }

  const handleToggleDomainWhitelist = (): void => {
    const newSettings = data
    newSettings.enableDomainWhitelist = !data.enableDomainWhitelist
    mutate.mutate(newSettings)
  }

  return (
    <Box>
      <Scim organizationUid={organizationUid} />
      <Flex mt="8" mb="6">
        <Switch
          size="sm"
          color="primary"
          isChecked={data.enableDomainWhitelist}
          onChange={handleToggleDomainWhitelist}
          mr={2}
          isDisabled={mutate.isPending}
        />
        <Title>Domain Whitelist</Title>
      </Flex>

      <DataTable
        title="Domain"
        data={domainWhitelist}
        onRemove={handleRemoveDomain}
        onAdd={handleAddDomain}
        inProgress={mutate.isPending}
      />

      <Flex mt="8" mb="6">
        <Switch
          size="sm"
          color="primary"
          isChecked={data.enableIpWhitelist}
          onChange={handleToggleIpWhitelist}
          mr={2}
          isDisabled={mutate.isPending}
        />
        <Title>Ip address Whitelist</Title>
      </Flex>

      <DataTable
        title="Ip"
        data={ipWhitelist}
        onRemove={handleRemoveIp}
        onAdd={handleAddIp}
        inProgress={mutate.isPending}
      />
    </Box>
  )
}

export default AuthSettings
