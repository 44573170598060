export const emptyOrgSettings = {
  metadata: {
    customNumberProp1: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp2: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp3: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp4: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp5: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp6: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp7: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp8: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp9: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customNumberProp10: {
      enabled: false,
      label: {},
      filterType: null,
      min: null,
      max: null,
      filters: [],
    },
    customProp1: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp2: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp3: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp4: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp5: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp6: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp7: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp8: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp9: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
    customProp10: {
      enabled: false,
      label: {},
      filterType: null,
      values: [],
    },
  },
  deletionStrategy: {
    created: '0001-01-01T00:00:00.000Z',
    createdByUserUid: '00000000-0000-0000-0000-000000000000',
    updated: '0001-01-01T00:00:00.000Z',
    updatedByUserUid: '00000000-0000-0000-0000-000000000000',
    conversationSoftTimeToLive: null,
    conversationHardTimeToLive: null,
    audioSnippetTimeToLive: null,
    caseTimeToLive: '1100.00:00:00',
    anonymizeAsrData: false,
    deleteCustomer: false,
    lastChanged: '2024-05-01T13:31:11.915Z',
    auditLog: [],
  },
  ai: {
    enabled: false,
    provider: 'OpenAI',
    useGlobalCache: false,
    summaryPromptUid: null,
    summaryPrompt: null,
    maxSummaryWords: null,
    keyTopicThreshold: null,
  },
  conversationsWithoutConsent: false,
  deleted: null,
}
