import { Button, useToast } from '@capturi/ui-components'
import { Flex, FormControl, FormLabel, Input, Select } from '@chakra-ui/react'
import React, { ReactElement, useState } from 'react'

import { User, useUpdateUser } from '../useUsers'
import Permissions from './Permissions'
import {
  UiRole,
  UserPermissions,
  defaultUserPermissions,
  toServerRole,
  toUiRole,
} from './permissionsUtils'

type Props = {
  user: User
  onClose: () => void
}

const EditUser: React.FC<Props> = ({ user, onClose }) => {
  const [state, setState] = useState(user)
  const { mutate, isPending } = useUpdateUser()

  const [permissions, setPermissions] = useState<UserPermissions>({
    ...defaultUserPermissions,
    ...user.permissions,
  })
  const uiRole = toUiRole(state.role, permissions)
  const toast = useToast()
  const handleSave = async (): Promise<void> => {
    const { name, title, role, email, id, organizationId } = state
    const payload = {
      name,
      title,
      role,
      email,
      permissions,
    }

    mutate(
      { organizationUid: organizationId, payload, userUid: id },
      {
        onSuccess: () => {
          toast({ status: 'success', title: `${name} updated` })
          onClose()
        },
        onError: (error) => {
          toast({
            status: 'error',
            title: 'Could not update error',
            description: error.message,
          })
        },
      },
    )
  }

  const handleRoleChange = (newRole: UiRole): void => {
    const { permissions, role } = toServerRole(newRole)
    setState((s) => ({
      ...s,
      role: role,
    }))
    setPermissions({
      ...defaultUserPermissions,
      ...permissions,
    })
  }

  const updateState = (key: keyof User, value: string): void =>
    setState((s) => ({ ...s, [key]: value }))

  const renderTextField = (
    key: keyof User,
    label: string,
    isDisabled = false,
  ): ReactElement => {
    return (
      <FormControl mt="2">
        <FormLabel mb="0" ml="1" htmlFor="name">
          {label}
        </FormLabel>
        <Input
          isDisabled={isPending || isDisabled}
          id="name"
          value={state[key] as string}
          onChange={(event) => updateState(key, event.currentTarget.value)}
          autoComplete="none"
        />
      </FormControl>
    )
  }

  return (
    <>
      {renderTextField('title', 'Title')}
      {renderTextField('name', 'Name')}
      {renderTextField('email', 'Email')}
      {renderTextField('externalUids', 'External uids', true)}
      <FormControl mt="2">
        <FormLabel mb="0" ml="1" htmlFor="email">
          Role
        </FormLabel>
        <Select
          onChange={(e) => handleRoleChange(e.target.value as UiRole)}
          value={uiRole}
          isDisabled={isPending}
        >
          <option value="user">User</option>
          <option value="teamlead">Team Lead</option>
          <option value="administrator">Administrator</option>
          <option value="owner">Owner</option>
        </Select>
      </FormControl>
      <Permissions
        inProgress={isPending}
        permissions={permissions}
        setPermissions={setPermissions}
        role={uiRole}
      />

      <Flex justifyContent="end" mt="8">
        <Button onClick={onClose} isDisabled={isPending}>
          Cancel
        </Button>
        <Button primary ml="2" isLoading={isPending} onClick={handleSave}>
          Update
        </Button>
      </Flex>
    </>
  )
}

export default EditUser
