import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { format } from 'date-fns/fp'
import type { FC } from 'react'
type Props =
  | {
      value: string
      onChange: (values: string) => void
      isDisabled?: boolean
      type: 'string'
    }
  | {
      value: Date
      onChange: (values: Date) => void
      isDisabled?: boolean
      type: 'date'
    }

const toTime = format("yyyy-MM-dd'T'HH:mm")

export const ContinuationTokenField: FC<Props> = ({
  type,
  onChange,
  value,
  isDisabled,
}) => {
  return (
    <FormControl mt="2" key={'continuationToken'}>
      <FormLabel mb="0" ml="1" htmlFor={'continuationToken'}>
        Continuation Token
      </FormLabel>
      <Input
        type={type === 'date' ? 'datetime-local' : 'text'}
        isDisabled={isDisabled}
        id="continuationToken"
        value={type === 'date' ? toTime(value) : value}
        onChange={(event) =>
          type === 'date'
            ? onChange(new Date(event.currentTarget.value))
            : onChange(event.currentTarget.value)
        }
        autoComplete="none"
      />
    </FormControl>
  )
}
