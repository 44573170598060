import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
} from '@chakra-ui/react'
import { formatDistance } from 'date-fns'
import React from 'react'
import { useSingleOrganization } from '../Organization/useOrganizations'
import { Muted, useMuted, useUnmute } from './useHeimdalStatus'

const MutedTable: React.FC = () => {
  const { data } = useMuted()

  return (
    <Box>
      <Heading pb="2" size="2xl" color="GrayText">
        Muted
      </Heading>

      {data?.map((d) => (
        <MuteRow {...d} key={d.organizationUid} />
      ))}
    </Box>
  )
}

export default MutedTable

const MuteRow: React.FC<Muted> = ({
  name,
  organizationUid,
  muteEndTime,
  mutedBy,
}) => {
  const { data } = useSingleOrganization(organizationUid)
  const { mutate, isPending } = useUnmute()

  return (
    <Card size="sm" mb="4">
      <CardHeader>
        <Heading size="xl">{name || data?.name || organizationUid}</Heading>
      </CardHeader>
      <CardBody>
        <Text title={muteEndTime.toLocaleString()}>
          Expires in {formatDistance(muteEndTime, new Date())}
        </Text>
        <Text>Muted By {mutedBy}</Text>
      </CardBody>
      <CardFooter>
        <Button
          colorScheme="primary"
          variant="ghost"
          isLoading={isPending}
          onClick={() => {
            mutate(organizationUid)
          }}
        >
          Unmute
        </Button>
      </CardFooter>
    </Card>
  )
}
