import request from '@capturi/request/src/request'
import { Box, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import React, { ReactElement } from 'react'

import Loader from '../../../Loader'

type Props = { organizationUid: string }

type Cell = {
  dayOfWeek: number
  created: number
  hour: number
}

type Response = {
  data: Cell[][]
  duration: string
  durationMs: number
}

const numberFormat = (number: number): string =>
  new Intl.NumberFormat('da-DK').format(number)

const ConversationsPerHour: React.FC<Props> = ({ organizationUid }) => {
  const { data } = useQuery<Response>({
    queryKey: ['heimdal', organizationUid, 'hour-avg'],
    queryFn: () =>
      request.get(`heimdal/${organizationUid}/hour-avg
    `),
  })

  if (!data) return

  const now = new Date()
  const renderCell = ({ created, dayOfWeek, hour }: Cell): ReactElement => {
    const isToday = dayOfWeek === now.getDay()
    const isNow = isToday && now.getUTCHours() === hour
    return (
      <Td
        textAlign="center"
        borderBottom={isToday ? 'none' : undefined}
        bg={isNow ? 'yellow.500' : isToday ? 'yellow.200' : undefined}
        key={dayOfWeek}
      >
        {numberFormat(created)}
      </Td>
    )
  }

  const renderHeaderCell = (value: string, day: number): ReactElement => {
    const isToday = day === now.getDay()
    return (
      <Th
        textAlign="center"
        color={isToday ? 'yellow.500' : undefined}
        key={day}
      >
        {value}
      </Th>
    )
  }
  const renderRow = (data: Cell[], key: number): ReactElement => {
    const first = data.at(0)
    if (!first) return <Tr key={key} />
    const d = new Date()
    d.setUTCHours(first.hour)
    const hour = d.getHours()
    const isCurrentHour = hour === now.getHours()
    return (
      <Tr key={key} bg={isCurrentHour ? 'yellow.200' : undefined}>
        <Td>{`${hour}:00-${hour}:59`}</Td>
        {data.map(renderCell)}
      </Tr>
    )
  }

  return (
    <Box mt="12">
      <Text m="4" fontSize="2xl">
        Conversations Average per hour
      </Text>

      {!data ? (
        <Loader />
      ) : (
        <Table>
          <Thead>
            <Tr>
              <Th>Hour</Th>
              {[
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ].map(renderHeaderCell)}
            </Tr>
          </Thead>
          <Tbody>{data.data.map(renderRow)}</Tbody>
        </Table>
      )}
    </Box>
  )
}

export default ConversationsPerHour
