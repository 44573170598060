import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const CACHE_KEY = ['superpowers', 'summary-prompts']

export type SummaryPrompt = {
  uid: string
  name: string
  prompt: string
}

export const useSummaryPrompts = (): UseQueryResult<
  SummaryPrompt[],
  ResponseError
> =>
  useQuery({
    queryKey: CACHE_KEY,
    queryFn: async () => {
      const result = await request.get<{ prompts: SummaryPrompt[] }>(
        'systemadmin/summary-prompts',
      )
      return result.prompts
    },
  })

export const useAddSummaryPrompts = (): UseMutationResult<
  SummaryPrompt,
  ResponseError,
  { name: string; prompt: string },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (model) =>
      request.post('systemadmin/summary-prompts', {
        json: model,
      }),
    onSuccess: (prompt) => {
      queryClient.setQueryData<SummaryPrompt[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [prompt]
        }
        return [...oldData, prompt]
      })
    },
  })
}

type UpdateSummaryPrompts = Partial<{ name: string; prompt: string }>

export const useUpdateSummaryPrompts = (
  uid: string | undefined,
): UseMutationResult<
  SummaryPrompt,
  ResponseError,
  UpdateSummaryPrompts,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: (payload) =>
      request.patch(`systemadmin/summary-prompts/${uid}`, {
        json: payload,
      }),
    onSuccess: (importer) => {
      queryClient.setQueryData<SummaryPrompt[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return [importer]
        }
        return oldData.map((d) => (d.uid === uid ? { ...d, ...importer } : d))
      })
    },
  })
}
export const useDeleteSummaryPrompts = (
  uid: string | undefined,
): UseMutationResult<void, ResponseError, void, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: CACHE_KEY,
    mutationFn: () => request.delete(`systemadmin/summary-prompts/${uid}`),
    onSuccess: () => {
      queryClient.setQueryData<SummaryPrompt[]>(CACHE_KEY, (oldData) => {
        if (!oldData) {
          return []
        }
        return oldData.filter((d) => d.uid !== uid)
      })
    },
  })
}
