import { ResponseError } from '@capturi/request'
import { Flex, Text } from '@chakra-ui/react'
import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'

import Loader from '../../Loader'
import { RefreshButton } from '../../RefreshButton'
import UserCard from '../UserCard'
import type {
  ConversationMapping as ConversationMappingResponse,
  Conversation as ConversationResponse,
  ConversationTranscriptionInfo as TranscriptionResponse,
  User as UserResponse,
} from '../types'
import ConversationInfo from './ConversationInfo'
import ConversationMapping from './ConversationMapping'
import ConversationTranscriptionInfo from './ConversationTranscriptionInfo'

type Props = { conversationUid: string }
type Response = [
  UseQueryResult<ConversationResponse>,
  UseQueryResult<ConversationMappingResponse>,
  UseQueryResult<TranscriptionResponse>,
]
const Info: React.FC<Props> = ({ conversationUid }) => {
  const [errors, setErrors] = useState<string[]>([])
  const response = useQueries({
    queries: [
      {
        queryKey: [`superpowers/info/conversation/${conversationUid}`],
      },
      {
        queryKey: [`superpowers/info/mapping/${conversationUid}`],
      },
      {
        queryKey: [`superpowers/info/transcription/${conversationUid}`],
      },
    ],
  }) as Response
  const [conversation, mapping, transcription] = response

  const user = useQuery<UserResponse>({
    queryKey: [`superpowers/info/user/${conversation.data?.UserUid}`],
    enabled: !!conversation.data?.UserUid,
  })

  useEffect(() => {
    ;(() => {
      const errorsMsgs: string[] = []
      for (const r of response) {
        if (r.error && r.error instanceof ResponseError) {
          errorsMsgs.push(r.error.message)
        }
      }

      if (user.error && user.error instanceof ResponseError) {
        const error = user.error
        errorsMsgs.push(error.message)
      }

      if (!isEqual(errors, errorsMsgs)) setErrors(errorsMsgs)
    })()
  }, [errors, response, user.error])

  const refetch = (): void => {
    conversation.refetch()
    mapping.refetch()
    transcription.refetch()
    user.refetch()
  }

  return (
    <div>
      <RefreshButton
        refresh={refetch}
        isLoading={user.isFetching && response.some((r) => r.isFetching)}
      />

      <Flex flexWrap="wrap">
        {conversation.isSuccess && (
          <ConversationInfo data={conversation.data} />
        )}
        {mapping.isSuccess && <ConversationMapping data={mapping.data} />}
        {transcription.isSuccess && (
          <ConversationTranscriptionInfo data={transcription.data} />
        )}
        {user.isSuccess && <UserCard data={user.data} />}
      </Flex>
      {errors.map((e) => (
        <Text
          key={e}
          w="100%"
          textAlign="center"
          my="4"
          color="danger"
          fontSize="lg"
        >
          {e}
        </Text>
      ))}

      {user.isLoading || (response.some((r) => r.isLoading) && <Loader />)}
    </div>
  )
}

export default Info
