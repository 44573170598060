import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import React from 'react'
import { IntegrationAuthConfig } from './IntegrationAuthConfig'
import { useAudioImporterAuthSchema } from './useAudioImporterAuthSchema'

type Props = {
  organizationName: string
  integrationType: string
  organizationUid: string
} & BaseModalComponentProps

export const AddIntegrationV2: React.FC<Props> = ({
  onClose,
  organizationName,
  integrationType,
  organizationUid,
}) => {
  const { data, isLoading, isError, error } =
    useAudioImporterAuthSchema(integrationType)

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="xl"
      closeOnOverlayClick={isLoading || isError}
    >
      <ModalOverlay>
        {isLoading && (
          <ModalContent>
            <ModalHeader>Loading config</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              minH="xl"
            >
              <Spinner size="xl" />
            </ModalBody>
          </ModalContent>
        )}

        {isError && (
          <ModalContent>
            <ModalHeader>Loading Error</ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              minH="xl"
            >
              <Text fontSize="xl" color="danger">
                {error.message}
              </Text>
            </ModalBody>
          </ModalContent>
        )}

        {data && (
          <IntegrationAuthConfig
            organizationUid={organizationUid}
            onClose={onClose}
            integrationType={integrationType}
            schema={data.fields}
            organizationName={organizationName}
          />
        )}
      </ModalOverlay>
    </Modal>
  )
}
