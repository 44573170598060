import { systemAdminAPI } from '@capturi/api-systemadmin'
import { useModal } from '@capturi/use-modal'
import { Button, Card, CardBody, Text } from '@chakra-ui/react'
import React from 'react'
import { MdDelete } from 'react-icons/md'
import useSWR from 'swr'
import { Title } from '../../../../utils'
import DeletionScheduling from './DeletionScheduling'
import formatDate from './formatDate'

type Props = { organizationUid: string }

const DangerZone: React.FC<Props> = ({ organizationUid }) => {
  const [showDeletionSchedulingModal] = useModal(DeletionScheduling)
  const { data } = useSWR<{ deleted: Date | null }>(
    systemAdminAPI.getOrganizationSettings(organizationUid).url,
    { suspense: false },
  )
  return (
    <Card border={'solid 2px #FF5C5C'}>
      <CardBody>
        <Title>Danger Zone</Title>

        <Button
          mt="4"
          leftIcon={<MdDelete />}
          size="sm"
          colorScheme="red"
          onClick={() =>
            showDeletionSchedulingModal({ organizationUid: organizationUid })
          }
        >
          Schedule Organisation Deletion
        </Button>
        {data?.deleted && (
          <Text color="danger">
            Scheduled for deletion at: {formatDate(data.deleted)}
          </Text>
        )}
      </CardBody>
    </Card>
  )
}

export default DangerZone
