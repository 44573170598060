import React from 'react'

import { integrations } from '../../../integrations'
import {
  ConversationOptions,
  Integration,
  IntegrationConfig,
} from '../../types'
import IntegrationModalContent from './IntegrationModalContent'

type Props = {
  onClose: () => void
  organizationUid: string
  integration: Integration | null
  integrationType: string
  integrationKey?: string
}
const IntegrationModal: React.FC<Props> = ({
  integration,
  integrationType,
  onClose,
  organizationUid,
  integrationKey,
}) => {
  const integrationSchema = integrations.get(integrationType)

  if (!integrationSchema) return null
  if (integrationSchema.v2) return null
  const conversationOptions: ConversationOptions = {
    asrPriority: 'DefaultPriority',
    audioChannel: integrationSchema.audioChannel.defaultValue,
    deleteCustomer: false,
  }

  const config: IntegrationConfig = {
    useTeamFromDialer: !!integrationSchema.config?.showUseTeamFromDialer,
    ...(integration?.config || {}),
  }

  return (
    <IntegrationModalContent
      integrationKey={integrationKey}
      onClose={onClose}
      organizationUid={organizationUid}
      config={config}
      conversationOptions={
        integration
          ? { ...conversationOptions, ...integration.conversationOptions }
          : conversationOptions
      }
      integrationOptions={integration?.integrationOptions || {}}
      schedule={integration?.schedule || ''}
      needsExtraRessources={integration?.needsExtraRessources}
      integrationType={integrationType}
      integrationSchema={integrationSchema}
    />
  )
}

export default IntegrationModal
