export function flattenObject(ob: Record<string, unknown>) {
  const toReturn: Record<string, unknown> = {}

  for (const i in ob) {
    // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
    if (!ob.hasOwnProperty(i)) continue

    if (
      typeof ob[i] === 'object' &&
      !(ob[i] instanceof Date) &&
      ob[i] !== null
    ) {
      const flatObject = flattenObject(ob[i] as Record<string, unknown>)
      for (const x in flatObject) {
        // biome-ignore lint/suspicious/noPrototypeBuiltins: <explanation>
        if (!flatObject.hasOwnProperty(x)) continue
        toReturn[`${i}.${x}`] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}
