import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { Integration } from './useOrganizationsWithIntegrations'

export const useIntegrations = <T = Integration[]>(
  select?: ((data: Integration[]) => T) | undefined,
): UseQueryResult<T, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'integrations'],
    queryFn: () => request.get<Integration[]>('superpowers/integration/list'),
    select,
  })

const byOrgUidSelector = (organizationUid: string) => (data: Integration[]) =>
  data.filter((d) => d.active && d.organizationUid === organizationUid)

export const useActiveOrganizationIntegrations = (
  organizationUid: string,
): UseQueryResult<Integration[], ResponseError> =>
  useIntegrations(byOrgUidSelector(organizationUid))
