export function getTextColor(
  bgColor: string,
  lightColor = '#fff',
  darkColor = '#000',
): string {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  const r = Number.parseInt(color.substring(0, 2), 16) // hexToR
  const g = Number.parseInt(color.substring(2, 4), 16) // hexToG
  const b = Number.parseInt(color.substring(4, 6), 16) // hexToB

  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return ((col + 0.055) / 1.055) ** 2.4
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.29 ? darkColor : lightColor
}
