import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import React, { ReactElement, useRef, useState } from 'react'
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai'
import { HiOutlineViewGrid, HiOutlineViewList } from 'react-icons/hi'
import { MdCheck, MdContentCopy } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'

import Deduplicate from './Deduplicate.svg'

type Props = {
  state: string[]
  onChange: (state: string[]) => void
  isLoading: boolean
  isSuccess: boolean
  isDisabled?: boolean
  placeholder?: string
  title?: string
} & Omit<BoxProps, 'onChange'>

const BoostPhrasesEditor: React.FC<Props> = ({
  state,
  onChange,
  isLoading,
  isSuccess,
  placeholder = 'Phrase',
  title,
  ...boxProps
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [newPhrase, setNewPhrase] = useState<string>('')
  const [list, setList] = useState<boolean>(false)
  const [sortDirection, setSortDirection] = useState<boolean>(false)
  const [, copy] = useCopyToClipboard()

  const sort = (): void => {
    const direction = sortDirection ? -1 : 1
    setSortDirection(!sortDirection)
    onChange([...state].sort((a, b) => a.localeCompare(b) * direction))
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')

    // split at comma and when more than max allowed number of words
    const sentences = text.split(',').map((t) => t.trim().toLocaleLowerCase())
    const newState = [...state, ...sentences]
    onChange(newState)
    setNewPhrase('')
    inputRef.current?.focus()
  }

  const handelRemoveDuplicates = (): void => {
    const newState = [...new Set(state)]
    onChange(newState)
  }

  const handleSave = (): void => {
    if (newPhrase) {
      onChange([...state, newPhrase])
      setNewPhrase('')
      inputRef.current?.focus()
    }
  }

  const removePhrase = (index: number): void => {
    const newState = [...state]
    newState.splice(index, 1)
    onChange(newState)
    inputRef.current?.focus()
  }

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (newPhrase && ['Enter', ',', 'Tab', '.'].includes(e.key)) {
      e.preventDefault()
      handleSave()
    }
  }

  const renderPhrase = (phrase: string, index: number): ReactElement => {
    return (
      <Tag m="1" justifyContent="space-between" key={index}>
        <TagLabel>{phrase}</TagLabel>
        <TagCloseButton onClick={() => removePhrase(index)} />
      </Tag>
    )
  }

  return (
    <Box {...boxProps}>
      <Flex direction="row" justifyContent="space-between" alignItems="center">
        <Flex direction="row" alignItems="center">
          {title && <Text fontSize="lg">{title}</Text>}
          <IconButton
            onClick={sort}
            icon={
              sortDirection ? (
                <AiOutlineSortDescending />
              ) : (
                <AiOutlineSortAscending />
              )
            }
            aria-label="sort"
            variant="ghost"
            isRound
            title="Sort"
          />
          <IconButton
            isRound
            variant="ghost"
            onClick={() => setList(!list)}
            icon={list ? <HiOutlineViewGrid /> : <HiOutlineViewList />}
            aria-label="view"
            title="Change view"
          />
          <IconButton
            isRound
            variant="ghost"
            onClick={handelRemoveDuplicates}
            icon={<Deduplicate />}
            aria-label="Remove duplicates"
            title="Remove duplicates"
          />
        </Flex>
        {isLoading === true && <Spinner mr="2" size="xs" color="gray" />}
        {isLoading === false && isSuccess === true && newPhrase === '' && (
          <Box mr="2">
            <MdCheck color="gray" />{' '}
          </Box>
        )}
      </Flex>

      <Flex
        onClick={() => inputRef.current?.focus()}
        border="solid 1px"
        borderColor="border.light"
        borderRadius="md"
        height="100%"
        minHeight="300px"
        padding="2"
        justify="space-between"
        flexDirection="column"
      >
        <Flex
          flexDirection={list ? 'column' : 'row'}
          wrap="wrap"
          alignItems={list ? 'stretch' : 'center'}
        >
          {state.map(renderPhrase)}
          <Box
            as="input"
            m={1}
            py={1}
            px={0}
            minW="100px"
            outline={0}
            placeholder={placeholder}
            ref={inputRef}
            onBlur={handleSave}
            onKeyDown={handleKeypress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewPhrase(e.target.value.toLocaleLowerCase())
            }
            value={newPhrase}
            onPaste={handlePaste}
          />
        </Flex>
        <IconButton
          aria-label="copy"
          alignSelf="flex-end"
          icon={<MdContentCopy />}
          size="xs"
          onClick={() => {
            copy(state.join(', '))
          }}
          variant="ghost"
          isRound
        />
      </Flex>
    </Box>
  )
}

export default BoostPhrasesEditor
