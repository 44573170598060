import { Box, Grid, Text } from '@chakra-ui/react'
import React from 'react'

import MissingColumn from './MissingColumn'
import Muted from './Muted'
import Status from './Status'
import { useHeimdal } from './useHeimdalStatus'

const Heimdal: React.FC = () => {
  const { data, isLoading, error } = useHeimdal()

  return (
    <>
      <Text fontSize="3xl">Heimdal</Text>
      <Box>
        <Status />
        <Grid
          templateColumns={{ sm: '1fr', lg: '1fr 1fr 1fr' }}
          width="100%"
          gap="6"
          mt="8"
        >
          <MissingColumn
            color="#e81111"
            title="Error"
            isLoading={isLoading}
            error={error}
            rows={data?.filter((d) => d.avg > 10).sort((a, b) => b.avg - a.avg)}
          />
          <MissingColumn
            color="#ffc107"
            title="Missing"
            isLoading={isLoading}
            error={error}
            rows={data?.filter((d) => d.avg < 10).sort((a, b) => b.avg - a.avg)}
          />
          <Muted />
        </Grid>
      </Box>
    </>
  )
}

export default Heimdal
