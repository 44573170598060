import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

export type ScimLog = {
  ID: string
  OrganizationUID: string
  RequestID: string
  Text: string
  CreatedAt: Date
  Level: string
  Action: string
  Target: string
  Changes: {
    before?: Record<string, unknown>
  }
}

type Response = {
  RequestID: string
  Logs: ScimLog[]
}[]
export const useScimLogs = (
  organizationUid: string,
): UseQueryResult<ScimLog[] | null, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'scim', organizationUid, 'logs'],
    queryFn: async () => {
      const result = await request.get<Response>(
        `superpowers/scim/monitoring/${organizationUid}`,
      )

      return result.flatMap((r) => r.Logs)
    },
  })

export const useCreateScimLogToken = (
  organizationUid: string,
): UseMutationResult<{ token: string }, ResponseError, null, ResponseError> => {
  return useMutation({
    mutationFn: () =>
      request.post(`superpowers/scim/monitoring/${organizationUid}/link`),
  })
}
