import { Modal, ModalOverlay } from '@capturi/ui-components'
import {
  Box,
  Flex,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react'
import React, { ReactElement, useRef, useState } from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { useEffectOnce } from 'react-use'

import { integrations } from '../../../integrations'
import { getTextColor } from '../utils/getTextColor'

type Props = {
  onSubmit: (val: { type: string; v2: boolean }) => void
  onClose: () => void
}

type Integration = {
  name: string
  key: string
  color: string
  v2: boolean
  type: string
}

const integrationsArray: Integration[] = Array.from(
  integrations,
  ([key, i]) => ({
    key,
    type: i.type || key,
    name: i.name,
    color: i.color,
    v2: !!i.v2,
  }),
)
const ChooseIntegrationType: React.FC<Props> = ({ onSubmit, onClose }) => {
  const [term, setTerm] = useState<string>('')
  const ref = useRef<HTMLInputElement>(null)

  useEffectOnce(() => {
    ref.current?.focus()
  })

  const renderIntegrationButton = ({
    name,
    key,
    type,
    color,
    v2,
  }: Integration): ReactElement => {
    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        as="button"
        p="2"
        _hover={{
          bg: 'primary.50',
        }}
        width="100%"
        borderColor="border.light"
        borderBottomWidth="1px"
        key={key}
        onClick={() => onSubmit({ type, v2 })}
      >
        <Flex alignItems="center">
          <Box
            w="28px"
            h="28px"
            fontSize="1.2rem"
            bg={color}
            mr="4"
            borderRadius="4"
            justifyContent="center"
            alignItems="center"
            color={getTextColor(color)}
          >
            {name[0]}
          </Box>
          {name}
        </Flex>
        <RiArrowRightSLine color="gray" size="25" />
      </Flex>
    )
  }

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Integration</ModalHeader>
        <ModalCloseButton />

        <ModalBody display="flex" alignItems="center" flexDirection="column">
          <Input
            onChange={(e) => setTerm(e.target.value)}
            value={term}
            placeholder="Search"
            mb="4"
            ref={ref}
          />
          <Box maxHeight="66vh" w="100%" overflow="auto">
            {integrationsArray.reduce<ReactElement[]>((memo, i) => {
              if (!term) {
                memo.push(renderIntegrationButton(i))
              } else if (i.name.toLocaleLowerCase().includes(term)) {
                memo.push(renderIntegrationButton(i))
              }
              return memo
            }, [])}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ChooseIntegrationType
