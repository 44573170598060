import { useCurrentUser } from '@capturi/core'
import { ResponseError } from '@capturi/request'
import { Button, ButtonProps, useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, Switch, Text } from '@chakra-ui/react'
import { subDays } from 'date-fns'
import React, { ReactElement, useMemo } from 'react'
import { AiOutlineConsoleSql } from 'react-icons/ai'
import { MdAdd, MdEdit, MdKey, MdList } from 'react-icons/md'
import { PiFileSql } from 'react-icons/pi'
import { AddIntegrationV2 } from '../../IntegrationConfig/AddIntegrationV2'
import ConfigModal from '../../IntegrationConfig/ConfigModal'
import { useToggleAudioImporter } from '../../IntegrationConfig/useIntegrationConfig'
import { useSingleOrganization } from '../../Organization/useOrganizations'
import { Title } from '../../utils'
import { Integration } from '../types'
import { useSystemSettings } from '../useSystemSettings'
import IntegrationAuthModal from './IntegrationAuthModal'
import IntegrationModal from './IntegrationModal'
import ChooseIntegrationType from './IntegrationModal/ChooseIntegrationTypeModal'
import { ResetFilesModal } from './IntegrationModal/ResetFilesModal'
import SetMostRecentRecordDateModal from './IntegrationModal/SetMostRecentRecordDateModal'
import QuerySql from './QuerySql'
import Support from './Support'
import CallsInRawDataModal from './puzzel/CallsInRawDataModal'
import FilesOnFtpModal from './puzzel/FilesOnFtpModal'
import {
  useOrganizationIntegrations,
  useResetEndDate,
  useToggleIntegration,
} from './useOrganizationIntegrations'

type Props = { organizationUid: string }

const AddButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      variant="unstyled"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color="primary.400"
      border="1px"
      borderColor="gray.200"
      borderRadius="5px"
      height="auto"
      mt={4}
      mr={4}
      p={2}
      transition="background-color 200ms"
      _hover={{ backgroundColor: 'primary.100' }}
      {...props}
    />
  )
}

const getCutOffDate = (timeSpan: string | null | undefined): Date | null => {
  if (timeSpan == null) return null
  const daysToLive = Number(timeSpan.split('.')[0])
  return subDays(new Date(), daysToLive)
}
const IntegrationOverview: React.FC<Props> = ({ organizationUid }) => {
  const { data: org } = useSingleOrganization(organizationUid)

  const { data: systemSettings } = useSystemSettings(organizationUid)
  const [openFilesOnFtpModal] = useModal(FilesOnFtpModal)
  const [openCallsInRawDataModal] = useModal(CallsInRawDataModal)
  const [openQuerySql] = useModal(QuerySql)
  const { ownerUid } = useCurrentUser()

  const { mutate: toggleIntegrationV2 } = useToggleAudioImporter()

  const { mutate: toggleIntegrationV1 } = useToggleIntegration(organizationUid)
  const { mutate: resetEndDate } = useResetEndDate(organizationUid)

  const cutOffDate = getCutOffDate(
    systemSettings?.deletionStrategy.conversationSoftTimeToLive ||
      systemSettings?.deletionStrategy.conversationHardTimeToLive,
  )

  const { data } = useOrganizationIntegrations(organizationUid)
  const toast = useToast()
  const [openSetMostRecentRecordDateModal] = useModal(
    SetMostRecentRecordDateModal,
  )
  const [openResetFilesModal] = useModal(ResetFilesModal)
  const [openChooseIntegrationType] = useModal(ChooseIntegrationType)
  const [openIntegrationModal] = useModal(IntegrationModal)
  const [openIntegrationModalV2] = useModal(ConfigModal)
  const [openAddIntegrationV2] = useModal(AddIntegrationV2)

  const [openAuthModal] = useModal(IntegrationAuthModal)

  const toggleIntegration = (
    integrationType: string,
    integrationKey: string,
    active: boolean,
    v2: boolean,
  ): void => {
    if (!v2) {
      toggleIntegrationV1({
        active: active,
        integrationKey,
        integrationType,
      })
    } else {
      toggleIntegrationV2({
        action: active ? 'start' : 'stop',
        integrationKey,
        integrationType,
      })
    }
  }

  const integrations = useMemo(
    () =>
      (data || []).sort((a, b) =>
        a.integrationType.localeCompare(b.integrationType),
      ),
    [data],
  )

  const editMostRecentRecordDate = (
    integrationType: string,
    integrationKey: string,
    mostRecentRecordDate: Date,
  ): void => {
    openSetMostRecentRecordDateModal({
      integrationKey,
      integrationType,
      mostRecentRecordDate,
      organizationUid,
      minDate: cutOffDate,
    })
  }
  const handleResetEndDate = (
    integrationType: string,
    integrationKey: string,
  ): void => {
    try {
      // setInProgress(true)

      resetEndDate({
        lastAttemptEnd: new Date(),
        integrationType,
        integrationKey,
      })
    } catch (error) {
      if (error instanceof ResponseError)
        toast({
          title: 'Could not reset end time',
          description: error.message,
          status: 'error',
        })
    }
  }
  const renderIntegration = (i: Integration): ReactElement => {
    const isDisabled =
      !!cutOffDate && i.mostRecentRecordDate <= cutOffDate && !i.active
    return (
      <Flex
        key={i.integrationType + i.integrationKey}
        width="300px"
        borderColor="gray.200"
        borderWidth="1px"
        borderRadius="5px"
        mt="4"
        p="2"
        mr="4"
      >
        <Box width="100%">
          <Flex justifyContent="space-between" mb="4">
            <Flex alignItems="center" width="100%" position="relative">
              <Switch
                title={
                  isDisabled
                    ? 'Cannot fetch conversations further back then the deleteion date'
                    : undefined
                }
                isDisabled={isDisabled}
                colorScheme="green"
                mt="4px"
                isChecked={i.active}
                onChange={(e) => {
                  toggleIntegration(
                    i.integrationType,
                    i.integrationKey,
                    e.currentTarget.checked,
                    i.configV2,
                  )
                }}
              />

              <Text fontSize="xl" ml="2">
                {i.integrationType}
              </Text>
            </Flex>
            {
              //Only visible for SIK
              ownerUid === '6048aa227d2a2f416c93945e' && (
                <Box
                  cursor="pointer"
                  onClick={() =>
                    openAuthModal({
                      integrationType: i.integrationType,
                      integrationKey: i.integrationKey,
                    })
                  }
                >
                  <MdKey />
                </Box>
              )
            }
            {i.integrationType === 'puzzel-v3' && (
              <>
                <Box
                  mx="1"
                  title="show files on ftp"
                  cursor="pointer"
                  onClick={() =>
                    openFilesOnFtpModal({
                      integrationKey: i.integrationKey,
                    })
                  }
                >
                  <MdList />
                </Box>
                <Box
                  mx="1"
                  title="show raw data"
                  cursor="pointer"
                  onClick={() =>
                    openCallsInRawDataModal({
                      integrationKey: i.integrationKey,
                    })
                  }
                >
                  <PiFileSql />
                </Box>
                <Box
                  mx="1"
                  title="query sql data"
                  cursor="pointer"
                  onClick={() =>
                    openQuerySql({
                      integrationType: 'puzzel',
                      integrationKey: i.integrationKey,
                    })
                  }
                >
                  <AiOutlineConsoleSql />
                </Box>
              </>
            )}
            {i.integrationType === 'leaddesk' && (
              <Box
                mx="1"
                title="query sql data"
                cursor="pointer"
                onClick={() =>
                  openQuerySql({
                    integrationType: 'leaddesk',
                    integrationKey: i.integrationKey,
                  })
                }
              >
                <AiOutlineConsoleSql />
              </Box>
            )}
            <Box
              ml="2"
              cursor="pointer"
              onClick={() =>
                i.configV2
                  ? openIntegrationModalV2({
                      integrationKey: i.integrationKey,
                      integrationType: i.integrationType,
                    })
                  : openIntegrationModal({
                      integration: i,
                      integrationType: i.integrationType,
                      organizationUid: i.organizationUid,
                      integrationKey: i.integrationKey,
                    })
              }
            >
              <MdEdit />
            </Box>
          </Flex>

          <Text>{`integrationKey: "${i.integrationKey}"`}</Text>
          <Flex
            color={isDisabled ? 'red.800' : undefined}
            title={
              isDisabled
                ? 'Cannot fetch conversations further back then the deleteion date'
                : undefined
            }
            cursor="pointer"
            onClick={
              i.configV2
                ? undefined
                : i.ftpQueue
                  ? () => openResetFilesModal(i)
                  : () =>
                      editMostRecentRecordDate(
                        i.integrationType,
                        i.integrationKey,
                        i.mostRecentRecordDate,
                      )
            }
          >
            <Text>
              Newest conv.: {i.mostRecentRecordDate?.toLocaleString()}
            </Text>
          </Flex>
          <Text>Last start: {i.lastAttemptStart?.toLocaleString() || ''}</Text>
          <Text
            onDoubleClick={
              i.configV2
                ? undefined
                : () => handleResetEndDate(i.integrationType, i.integrationKey)
            }
          >
            Last end: {i.lastAttemptEnd?.toLocaleString() || ''}
          </Text>
        </Box>
      </Flex>
    )
  }

  const renderAddButton = (): ReactElement => {
    return (
      <AddButton
        onClick={() =>
          openChooseIntegrationType({
            onSubmit: ({ type, v2 }) => {
              if (v2) {
                openAddIntegrationV2({
                  integrationType: type,
                  organizationUid,
                  organizationName: org?.name || organizationUid,
                })
              } else {
                openIntegrationModal({
                  integrationType: type,
                  organizationUid: organizationUid,
                  integration: null,
                })
              }
            },
          })
        }
      >
        <MdAdd size="90" />
        <Text color="text" fontSize="xl">
          Add Integration
        </Text>
      </AddButton>
    )
  }

  return (
    <>
      <div>
        <Title>🚀 Integrations</Title>

        <Flex mx="4" mb="4" flexWrap="wrap">
          {integrations.map(renderIntegration)}
          {renderAddButton()}
        </Flex>
        <Support integrations={integrations} />
      </div>
    </>
  )
}

export default IntegrationOverview
