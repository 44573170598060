import { Button, Spinner } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Text, useToast } from '@chakra-ui/react'
import React from 'react'
import CreateTokenModal from './CreateTokenModal'
import DeleteTokenModal from './DeleteTokenModal'
import { useCreateScimToken, useGetScimToken } from './useScimToken'

type Props = { organizationUid: string }

const ScimTokens: React.FC<Props> = ({ organizationUid }) => {
  const {
    data = [],
    isLoading,
    error,
    isError,
  } = useGetScimToken(organizationUid)
  const [openDeleteModal] = useModal(DeleteTokenModal)
  const [openCreateModal] = useModal(CreateTokenModal)
  const create = useCreateScimToken(organizationUid)
  const toast = useToast()

  if (isLoading) return <Spinner />
  if (isError) return <Text>{error.message}</Text>

  const handleCreateToken = () => {
    create.mutate(null, {
      onSuccess: (data) => {
        openCreateModal({ secretToken: data.Secret })
      },
      onError: (error) => {
        toast({
          title: 'Could not create token',
          description: error.message,
          status: 'error',
        })
      },
    })
  }

  return (
    <Box>
      {data.map((d) => (
        <Box my="2" key={d.TokenID}>
          <Text fontWeight="bold">{d.TokenID}</Text>
          <Text>{d.Created.toLocaleString()}</Text>
          <Text>{d.SyncActive ? 'Sync is Active' : 'Sync not Active'}</Text>
          <Button
            variant="link"
            colorScheme="red"
            onClick={() => {
              openDeleteModal({ token: d.TokenID, organizationUid })
            }}
          >
            Delete
          </Button>
        </Box>
      ))}
      <Button
        width="100%"
        primary
        isLoading={create.isPending}
        onClick={handleCreateToken}
      >
        Create Token
      </Button>
    </Box>
  )
}

export default ScimTokens
