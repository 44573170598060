import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import noop from 'lodash/noop'
import React from 'react'
import { useDeleteScimToken } from './useScimToken'

type Props = {
  organizationUid: string
  token: string
} & BaseModalComponentProps

const DeleteTokenModal: React.FC<Props> = ({
  onClose,
  organizationUid,
  token,
}) => {
  const { mutate, isPending, error, isError } =
    useDeleteScimToken(organizationUid)
  const toast = useToast()
  const handleDelete = () => {
    mutate(token, {
      onError: (error) => {
        toast({
          title: 'Could not Deleted Token',
          description: error.message,
          status: 'error',
        })
      },
      onSuccess: () => {
        toast({ title: 'Deleted Token', status: 'success' })
        onClose()
      },
    })
  }

  return (
    <Modal
      isOpen
      onClose={isPending ? noop : onClose}
      closeOnOverlayClick={!isPending}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Are you sure</ModalHeader>

          <ModalCloseButton isDisabled={isPending} />
          <ModalBody>
            <Text>This will delete token with id: {token}</Text>
            {isError && <Text colorScheme="red">{error.message}</Text>}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button mr="4" onClick={onClose} isDisabled={isPending}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              isLoading={isPending}
            >
              <Trans>Delete</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default DeleteTokenModal
