import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom'

import Loader from '../../../Loader'
import NGramsEditor from './NGramsEditor'

const WordCloud: React.FC = () => {
  const { uid: organizationUid } = useParams()

  if (organizationUid == null) return <div>Not Found</div>

  return (
    <Suspense fallback={<Loader />}>
      <NGramsEditor organizationUid={organizationUid} />
    </Suspense>
  )
}

export default WordCloud
