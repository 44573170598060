import { Box, Card, CardBody, Text } from '@chakra-ui/react'

import { Button, useToast } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import React from 'react'
import Loader from '../../../../Loader'
import { Title } from '../../../../utils'
import {
  useCreateToken,
  useOrganizationExternalApi,
} from '../../../useExternalApi'
import CreateTokenModal from './CreateTokenModal'
import DeleteTokenModal from './DeleteTokenModal'

type Props = { organizationUid: string }

const ExternalApi: React.FC<Props> = ({ organizationUid }) => {
  const {
    data = [],
    isLoading,
    isError,
    error,
  } = useOrganizationExternalApi(organizationUid)
  const create = useCreateToken(organizationUid)
  const [openDeleteModal] = useModal(DeleteTokenModal)
  const [openCreateModal] = useModal(CreateTokenModal)
  const toast = useToast()

  if (isLoading) return <Loader />
  if (isError) return <Text>{error.message}</Text>

  const handleCreateToken = () => {
    create.mutate(null, {
      onSuccess: (data) => {
        openCreateModal({ secretToken: data.secret })
      },
      onError: (error) => {
        toast({
          title: 'Could not create token',
          description: error.message,
          status: 'error',
        })
      },
    })
  }
  return (
    <Card>
      <CardBody>
        <Title>External Api Tokens</Title>
        {data.map((d) => (
          <Box my="2" key={d.TokenID}>
            <Text fontWeight="bold">{d.TokenID}</Text>
            <Text>{d.Created.toLocaleString()}</Text>
            <Button
              variant="link"
              colorScheme="red"
              onClick={() => {
                openDeleteModal({ token: d.TokenID, organizationUid })
              }}
            >
              Delete
            </Button>
          </Box>
        ))}
        <Button
          width="100%"
          primary
          isLoading={isLoading}
          onClick={handleCreateToken}
        >
          Create External API Token
        </Button>
      </CardBody>
    </Card>
  )
}

export default ExternalApi
