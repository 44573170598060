import { useSet } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import ConfirmKeepLabelsModal from './ConfirmKeepLabelsModal'

type LabelsResponse = {
  result: string[]
  errorCode: string
  errorMessage: string
  hasError: boolean
}

const KeepLabels: React.FC = () => {
  const { uid: organizationUid = '' } = useParams()

  const [showConfirm] = useModal(ConfirmKeepLabelsModal)
  const labelsToKeep = useSet<string>([])

  const { data: labelResult, mutate } = useSWR<LabelsResponse>(
    `systemadmin/list-labels/${organizationUid}`,
    { suspense: false },
  )

  const onkeep = (): void => {
    showConfirm({
      allLabels: labelResult?.result || [],
      labelsToKeep: [...labelsToKeep],
      organizationUid: organizationUid,
      onSubmit: () => {
        mutate(
          (l) =>
            l?.result && {
              ...l,
              result: l.result.filter((label) => labelsToKeep.has(label)),
            },
        )
        labelsToKeep.clear()
      },
    })
  }

  const labels = labelResult?.result || []

  return (
    <div>
      <Flex justifyContent="space-between">
        <div>
          <Text fontSize="xl"> Select labels you want to keep</Text>
          <Text color="subtitle">
            Updates labels on all conversations in an organization.
          </Text>
          <Text color="subtitle">
            Use with caution. Will create a significant load on the system
          </Text>
        </div>
        <Button onClick={() => labels.map((l) => labelsToKeep.add(l))}>
          Select All
        </Button>
      </Flex>
      <Box ml="4" mt="4">
        {labels.map((l) => (
          <Box
            key={l}
            cursor="pointer"
            onClick={() => {
              if (labelsToKeep.has(l)) {
                labelsToKeep.delete(l)
              } else {
                labelsToKeep.add(l)
              }
            }}
          >
            <Text
              _hover={{
                color: labelsToKeep.has(l) ? 'mediumseagreen' : 'grey',
              }}
              as="span"
              color={labelsToKeep.has(l) ? 'green' : undefined}
            >
              {l}
            </Text>
          </Box>
        ))}
      </Box>

      <Flex justifyContent="flex-end">
        <Button onClick={() => labelsToKeep.clear()}>Reset</Button>
        <Button
          colorScheme="green"
          ml="2"
          onClick={onkeep}
          isDisabled={labelsToKeep.size === 0}
        >
          Keep {labelsToKeep.size} labels
        </Button>
      </Flex>
    </div>
  )
}

export default KeepLabels
