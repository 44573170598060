import { Box, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import React from 'react'
import useSWR from 'swr'

import Loader from '../../../Loader'

type Props = { organizationUid: string }
type Response = {
  count: number
  newest: Date
  oldest: Date
}

const numberFormat = (number: number): string =>
  new Intl.NumberFormat('da-DK').format(number)
const ConversationsOverview: React.FC<Props> = ({ organizationUid }) => {
  const { data } = useSWR<Response>(
    `superpowers/organization/${organizationUid}/insights/total-conversations`,
    { suspense: false },
  )

  if (!data)
    return (
      <Box my="6">
        <Loader />
      </Box>
    )

  const { count, oldest, newest } = data
  return (
    <Box my="6">
      <Text fontSize="xl">Conversation Overview</Text>
      <Table>
        <Tbody>
          <Tr>
            <Td> Total number of conversations</Td>
            <Td>{numberFormat(count)}</Td>
          </Tr>
          <Tr>
            <Td>Oldest conversation</Td>
            <Td>{oldest.toLocaleString('da-DK')}</Td>
          </Tr>
          <Tr>
            <Td>Newest conversation</Td>
            <Td>{newest.toLocaleString('da-DK')}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  )
}

export default ConversationsOverview
