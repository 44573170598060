import request, { ResponseError } from '@capturi/request'
import {
  type UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

type ExternalIntegration = {
  TokenID: 'string'
  TokenHash: 'string'
  OrganizationUID: 'string'
  Created: 'string'
}

export const useExternalApi = <T = ExternalIntegration[]>(
  select?: ((data: ExternalIntegration[]) => T) | undefined,
): UseQueryResult<T, ResponseError> =>
  useQuery({
    queryKey: ['superpowers', 'externalIntegration'],
    queryFn: () =>
      request.get<ExternalIntegration[]>('superpowers/external-api/list'),
    select,
  })

const byOrgUidSelector =
  (organizationUid: string) => (data: ExternalIntegration[]) =>
    data.filter((d) => d.OrganizationUID === organizationUid)

export const useOrganizationExternalApi = (
  organizationUid: string,
): UseQueryResult<ExternalIntegration[], ResponseError> =>
  useExternalApi(byOrgUidSelector(organizationUid))

export const useCreateToken = (
  organizationUid: string,
): UseMutationResult<
  { secret: string },
  ResponseError,
  null,
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () =>
      request.post<{ secret: string }>(
        `superpowers/external-api/${organizationUid}`,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['superpowers', 'externalIntegration'],
      })
    },
  })
}
export const useDeleteToken = (
  organizationUid: string,
): UseMutationResult<null, ResponseError, string, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (token) =>
      request.delete(`superpowers/external-api/${organizationUid}/${token}`),
    onSuccess: (_, token) => {
      queryClient.setQueryData<ExternalIntegration[]>(
        ['superpowers', 'externalIntegration'],
        (oldData = []) => oldData.filter((d) => d.TokenID !== token),
      )
    },
  })
}
