import { useScrollable } from '@capturi/react-utils'
import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Flex,
  Input,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React, { useRef, useState } from 'react'

type Props = {
  integrationKey: string
  sessionUid?: string
} & BaseModalComponentProps
type Response = {
  recordset: Record<string, string | Date | null>[]
}

const guidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}/

const CallsInRawDataModal: React.FC<Props> = ({
  onClose,
  integrationKey,
  sessionUid: propSessionUid = '',
}) => {
  const [sessionUid, setSessionUid] = useState(propSessionUid)
  const [tabelView, setTabelView] = useState(true)
  const ref = useRef(null)
  const heigth = useScrollable(ref, 70)
  const { data, isLoading, error, fetchStatus } = useQuery<
    Response,
    ResponseError
  >({
    queryKey: [
      'superpowers',
      'integrations',
      'puzzel',
      integrationKey,
      'sql',
      sessionUid,
    ],
    enabled: guidRegex.test(sessionUid),
    queryFn: async () =>
      request.get(
        `superpowers/integration/puzzel/${integrationKey}/sql/${sessionUid}`,
      ),
  })

  return (
    <Modal isOpen onClose={onClose} size="full">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader display="flex">
            <Flex>
              <Button
                mr="4"
                variant="outline"
                primary
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.stringify(data?.recordset, undefined, '   '),
                  )
                }}
              >
                Copy json
              </Button>
              <Flex alignItems="center" marginRight="80px">
                <Switch
                  p={1}
                  onChange={(e) => setTabelView(e.currentTarget.checked)}
                  isChecked={tabelView}
                />
                Table view
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            {propSessionUid === '' && (
              <Input
                placeholder="puzzel session uid"
                value={sessionUid}
                onChange={(e) => setSessionUid(e.currentTarget.value)}
              />
            )}

            {error && <Text>{error.message}</Text>}
            {isLoading && fetchStatus === 'fetching' && <Spinner />}

            <Box
              whiteSpace="nowrap"
              fontFamily="monospace"
              overflow="auto"
              ref={ref}
              height={`${heigth}px`}
            >
              {tabelView ? (
                <Table>
                  <Thead>
                    <Tr>
                      {Object.keys(data?.recordset?.[0] || {}).map((v) => (
                        <Th key={v}>{v}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody overflow="scroll">
                    {data?.recordset.map((d, index) => {
                      return (
                        <Tr key={index} _hover={{ bg: 'gray.50' }}>
                          {Object.values(d).map((v, index) => {
                            if (typeof v === 'string')
                              return <Td key={index}>{v}</Td>
                            if (typeof v === 'number')
                              return (
                                <Td isNumeric key={index}>
                                  {v}
                                </Td>
                              )
                            if (v instanceof Date)
                              return (
                                <Td key={index}>{v.toLocaleString('da-DK')}</Td>
                              )
                            return (
                              <Td key={index} color="gray.500">
                                null
                              </Td>
                            )
                          })}
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Text whiteSpace="break-spaces" bg="gray.100">
                  {JSON.stringify(data?.recordset, undefined, '   ')}
                </Text>
              )}
            </Box>
            {/* </Text> */}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button primary onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default CallsInRawDataModal
