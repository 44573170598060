export type Conversation = {
  _id: string
  DateTime: Date
  UserUid: string
  OrganizationUid: string
  ExternalIdentity: string
  Subject: string
  Duration: number
  AudioChannels: string
  Deleted: boolean
  Options: { AsrPriority: number }
  Metadata: { CustomerSatisfaction: number }
  Metrics: {
    AudioAdded?: Date
    Created: Date
    DataAdded?: Date
    MetadataAdded?: Date
    ProcessingDone?: Date
  }
  HasStatistics: boolean
  HasInsights: boolean
  HasTrackerHits: boolean
  Language: string
  IgnoredPhrases: string[]
}

export type ConversationMapping = {
  integrationKey: string
  integrationType: string
  integrationUid: string
  created: Date
  lastTry: Date
  attempts: number
  errors: string[]
  succeeded: boolean
  conversationUid: string
  data: Record<string, unknown>
}

export type ConversationTranscriptionInfo = {
  AsrPriority: AsrPriority
  AsrProcessingStatus: AsrProcessingStatus
  Deleted: boolean
  ProcessingStartedDate: Date
  ProcessingEndedDate: Date
  SpokenLanguage: string
  MonoNumSpeakers: MonoNumSpeakers
  StereoSalesChannel: number
}

export enum AsrProcessingStatus {
  NotStarted = 0,
  Processing = 1,
  Success = 2,
  Error = 3,
  TimedOut = 4,
  NeedsSpeakerModel = 5,
  MissingAudio = 6,
  InvalidAudio = 7,
  NoCustomerToDeleteError = 8,
}
export enum AsrPriority {
  Lowest = 0,
  Low = 1,
  Default = 2,
  High = 3,
}
export enum MonoNumSpeakers {
  Unknown = 0,
  Mono1Speaker = 1,
  Mono2Speaker = 2,
  MonoDeleteCustomer = 3,
}

export type User = {
  id: string
  organizationId: string
  email: string
  name: string
  role: string
  title: string
  teamUid: null | string
  language: string
  timeZone: string
  teamLeadTeamUids: string[]
  emailInvitationSent: boolean
  hasSuperpowers: boolean
  deny: string[]
  owner: string
  provider: {
    [provide: string]: {
      id: string
      displayName: string
      email: string
    }
  } | null
}

export type UserConversation = {
  _id: string
  DateTime: string
  UserUid: string
  OrganizationUid: string
  ExternalIdentity: string
  Subject: string
  Duration: number
  Labels: string[]

  HasStatistics: boolean
  HasInsights: boolean
  HasTrackerHits: boolean
}

export type SpeakerModelStatusResponse = {
  status: 'Success' | 'Processing' | 'Error'
}
