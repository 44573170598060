import request, { getErrorMessage } from '@capturi/request'
import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react'
import noop from 'lodash/noop'
import React, { useState } from 'react'

type RetrainSpeakerModelProps = {
  userName: string
  userUid: string
  organizationUid: string
} & BaseModalComponentProps<void>

const guidRegex =
  /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}/g

const RetrainSpeakerModel: React.FC<RetrainSpeakerModelProps> = ({
  userName,
  userUid,
  organizationUid,
  onClose,
  onSubmit,
}) => {
  const [uids, setUids] = useState<string>('')
  const [count, setCount] = useState<number>(0)

  const [inProgress, setInProgress] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const reformatText = (value?: string): void => {
    const uidArray = (value || uids).match(guidRegex)
    const formattedText = uidArray?.reduce((memo, u) => `${memo}${u},\n`, '')

    setUids(formattedText || '')
    setCount(uidArray?.length ?? 0)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (['Enter', ' ', 'Tab', ',', '.'].includes(e.key)) {
      e.preventDefault()
      reformatText()
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setUids(e.currentTarget.value)

    const array = e.currentTarget.value.split(',\n')
    const last = array[array.length - 1]

    const formatText = guidRegex.test(last)
    if (formatText) {
      reformatText(e.currentTarget.value)
    }
  }

  const handleSave = async (): Promise<void> => {
    try {
      setInProgress(true)

      await request.post(
        `superpowers/asr/speaker-model/${userUid}/retrain-from-uids`,
        {
          json: {
            organizationUid: organizationUid,
            conversationUids: uids.split(',\n').filter(Boolean),
          },
        },
      )

      onSubmit?.()
    } catch (error) {
      const msg = getErrorMessage(error) || 'Unknown error'
      setErrorMsg(msg)
      setInProgress(false)
    }
  }

  return (
    <Modal isOpen onClose={inProgress ? noop : onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Update Speaker model for ${userName}`}</ModalHeader>
        <ModalCloseButton isDisabled={inProgress} />
        <ModalBody>
          <FormControl mt="2" h="100%">
            <FormLabel mb="0" ml="1" htmlFor="name">
              Uids
            </FormLabel>
            <Textarea
              rows={15}
              onKeyDown={handleKeyDown}
              onChange={handleChange}
              value={uids}
            />
            <FormHelperText textAlign="end">
              Number of UIDs: {count} (min 10)
            </FormHelperText>
          </FormControl>

          {errorMsg && (
            <Text mt="2" textAlign="end" color="danger">
              {errorMsg}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} isDisabled={inProgress}>
            Close
          </Button>
          <Button
            primary
            onClick={handleSave}
            isLoading={inProgress}
            isDisabled={count < 10}
          >
            Update Speaker Model
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RetrainSpeakerModel
