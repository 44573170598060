import { FormControl, FormLabel, Switch } from '@chakra-ui/react'
import type { FC } from 'react'

type Props = {
  label: string
  fieldKey: string
  value: boolean
  onChange: (value: boolean) => void
  isDisabled?: boolean
}

export const BooleanField: FC<Props> = ({
  fieldKey,
  label,
  onChange,
  value,
  isDisabled,
}) => {
  return (
    <FormControl display="flex" mt="4">
      <Switch
        id={fieldKey}
        isChecked={value}
        onChange={(e) => onChange(e.currentTarget.checked)}
        isDisabled={isDisabled}
        mr="2"
      />
      <FormLabel htmlFor={fieldKey}>{label}</FormLabel>
    </FormControl>
  )
}
