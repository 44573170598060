import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../../Loader'
import EditTeamModal from './EditTeamModal'
import { type Team, useTeams } from './useSuperpowersTeams'

const Teams: React.FC = () => {
  const { uid: organizationUid } = useParams()
  const navigate = useNavigate()
  const [openEditTeamModal] = useModal(EditTeamModal)

  const { data } = useTeams(organizationUid || '')

  if (!organizationUid) return null

  const renderRow = ({
    email,
    externlaUids,
    name,
    uid,
  }: {
    name: string
    email: string
    externlaUids?: string[]
    uid: string
  }): ReactElement => {
    return (
      <Tr
        transition="background-color 200ms"
        _hover={{ bg: 'primary.50' }}
        cursor="pointer"
        key={uid}
        onClick={() => navigate(`/superpowers/user?uid=${uid}`)}
      >
        <Td>{name}</Td>
        <Td>{email}</Td>
        <Td>{(externlaUids || []).join(', ')}</Td>
      </Tr>
    )
  }

  const renderCard = ({
    name,
    createdByIntegration,
    uid,
    integrationType,
    users,
  }: Team) => {
    const sub = createdByIntegration
      ? `Created By integration: ${integrationType}`
      : integrationType || ''

    return (
      <Card variant="elevated" key={uid} m="2">
        <CardHeader display="flex" justifyContent="space-between">
          <div>
            <Heading size="xl">
              {name} ({users?.length})
            </Heading>
            <Text color="textMuted">{sub}</Text>
          </div>
          <Button
            onClick={() =>
              openEditTeamModal({
                name,
                createdByIntegration,
                uid,
                integrationType,
                organizationUid,
              })
            }
          >
            Edit
          </Button>
        </CardHeader>

        <CardBody>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>email</Th>
                <Th>externalUids</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map(renderRow)}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    )
  }

  const renderOverview = () => {
    return (
      <div>
        <Table mb="12">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>externalUid</Th>
              <Th>integrationType</Th>
              <Th>createdByIntegration</Th>
              <Th>#users</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(data || []).map(
              ({
                uid,
                name,
                integrationType,
                externalUid,
                createdByIntegration,
                users,
              }) => (
                <Tr
                  transition="background-color 200ms"
                  _hover={{ bg: 'primary.50' }}
                  cursor="pointer"
                  key={uid}
                  onClick={() =>
                    openEditTeamModal({
                      name,
                      createdByIntegration,
                      uid,
                      integrationType,
                      organizationUid,
                    })
                  }
                >
                  <Td>{name}</Td>
                  <Td>{externalUid}</Td>
                  <Td>{integrationType}</Td>
                  <Td>{String(createdByIntegration)}</Td>
                  <Td>{users?.length || 0}</Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
      </div>
    )
  }
  if (!data)
    return (
      <Box my="20">
        <Loader />
      </Box>
    )
  if (!data.length)
    return (
      <Flex my="20" justifyContent="center">
        <Text fontSize="lg">No teams on organization</Text>
      </Flex>
    )

  return (
    <>
      {renderOverview()}
      {data.map(renderCard)}
    </>
  )
}

export default Teams
