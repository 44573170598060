import request, { ResponseError } from '@capturi/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import Table from './Table'

type Props = {
  integrationKey: string
  integrationType: string
  limit: string
} & BaseModalComponentProps

type Response = {
  file: string
  content: Record<string, unknown>
  rawContent: string
  type: '.xml' | '.json' | '.txt'
}[]

const SftpModal: React.FC<Props> = ({
  integrationKey,
  integrationType,
  limit,
  onClose,
}) => {
  const filesToFetch = limit || 20
  const { data, error, isLoading } = useQuery<Response, ResponseError>({
    queryKey: [
      'integrations',
      'sftp',
      integrationType,
      integrationKey,
      filesToFetch,
    ],
    queryFn: () =>
      request.get('superpowers/integration/sftp', {
        searchParams: {
          integrationKey,
          integrationType,
          files: filesToFetch,
        },
      }),
    staleTime: Number.POSITIVE_INFINITY,
  })

  const renderContent = () => {
    if (isLoading || !data)
      return (
        <Flex mt="33%" justifyContent="center" alignItems="center">
          <Spinner />
          <Text ml="2">Reading files on the server, this takes some time.</Text>
        </Flex>
      )

    if (error) return <Text>{error.message}</Text>

    return (
      <Table
        data={data.map((d) => {
          return { __filename: d.file, ...d.content }
        })}
      />
    )
  }

  return (
    <Modal isOpen onClose={onClose} size="full">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Files on FTP</ModalHeader>

          <ModalCloseButton />
          <ModalBody>{renderContent()}</ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button mr="4" onClick={onClose}>
              <Trans>Cancel</Trans>
            </Button>
            <Button primary onClick={onClose}>
              <Trans>Ok</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

export default SftpModal
