import { RequestOptions } from '@capturi/request'

import { OrganizationSettingsUpsertModel } from './models'

const DEFAULT_API_VERSION = '1'

const controller = 'systemadmin'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const systemAdminAPI = {
  getOrganizationSettings: (organizationUid: string): RequestOptions => ({
    url: `${controller}/organization/${organizationUid}/settings`,
    query: baseQuery,
  }),
  upsertOrganizationSettings: (
    organizationUid: string,
    model: OrganizationSettingsUpsertModel,
  ): RequestOptions => ({
    url: `${controller}/organization/${organizationUid}/settings`,
    query: baseQuery,
    method: 'patch',
    json: model,
  }),
}
